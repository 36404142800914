import { Box, Text } from "@chakra-ui/react";
import React from "react";
import {
  chakra,
  HTMLChakraProps,
  Image,
  useColorMode,
  useToken,
} from "@chakra-ui/react";

export default function PoweredByWealthBee() {
  return (
    <Box
      pos="fixed"
      bottom={"10px"}
      right={"10px"}
      p="6px"
      m="4px"
      border="1px solid var(--dark-gray)"
      w="114px"
      h="60px"
      borderRadius={"6px"}
      bg="var(--bg)"
    >
      <a href="https://wealthbee.io" target="_blank">
        <Text fontSize={"12px"} mb="4px">
          Powered by{" "}
        </Text>
        <chakra.svg aria-hidden>
          <image
            height="20"
            id="img1"
            href="https://cdn.wealthbee.io/main/logos/wealthbee-logo-with-text.svg"
          />
        </chakra.svg>
      </a>
    </Box>
  );
}
