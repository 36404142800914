import { Box } from "@chakra-ui/react";
import { Meta } from "../../components";
import NavBar from "../homepage/menu/App";
import Footer from "../homepage/footer/App";
import { Cookies } from "../homepage/components/Cookies";
import Markdown from "react-markdown";
import styles from "../blog/markdown.module.css";

const Privacy = () => {
  return (
    <Box>
      <Meta
        title={"Terms & Conditions"}
        description={
          "Our Terms of Service govern your use of WealthBee. By using WealthBee, you agree to these terms."
        }
        canonical={"https://wealthbee.io/terms"}
      />
      <NavBar />
      <Box w="6xl" m="auto">
        <Markdown className={styles.reactMarkDown}>
          {`
[![WealthBee](https://cdn.wealthbee.io/main/logos/wealthbee-logo-with-text.svg)](https://wealthbee.io/)

## WealthBee Terms of Service

### Agreement between User and wealthbee.io

The wealthbee.io website (the "Site") is comprised of various web pages operated by WealthBee Limited ("WealthBee"). The Site is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the "Terms"). Your use of the Site constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference.

### wealthbee.io Services

wealthbee.io is an information aggregation and analysis Site. WealthBee helps traders track and analyze their positions and portfolios. WealthBee sells a monthly subscription service and a pre-paid annual recurring service. WealthBee does not and will never provide advice. Always make your own decisions.

### Privacy

Your use of wealthbee.io is subject to WealthBee's Privacy Policy. Please review our Privacy Policy, which also governs the Site and informs users of our data collection practices.

### Electronic Communications

Visiting wealthbee.io or sending emails to WealthBee constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures, and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.

### Your Account

If you use this site, you are responsible for maintaining the confidentiality of your account and password for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. You may not assign or otherwise transfer your account to any other person or entity. You acknowledge that WealthBee is not responsible for third party access to your account that results from theft or misappropriation of your account. WealthBee and its associates reserve the right to refuse or cancel service, terminate accounts, or remove or edit content in our sole discretion.

### Children Under Thirteen

WealthBee does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. If you are under 18, you may use wealthbee.io only with permission of a parent or guardian.

### Cancellation/Refund Policy

You may cancel your subscription at any time. To qualify for a refund of the active month's subscription, you must request a refund within 14 days of becoming a customer. You may only have one account. Contact us at [matt@wealthbee.io](mailto:matt@wealthbee.io) with any questions or concerns about cancellation and refunds.

### Links to Third Party Site/Third Party Services

wealthbee.io may contain links to other websites ("Linked Sites"). The Linked Sites are not under control of WealthBee and WealthBee is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. WealthBee is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by WealthBee of the site or any association with its operators.

Certain services made available via wealthbee.io are delivered by third party sites and organizations. By using any product, service or functionality originating from the wealthbee.io domain, you hereby acknowledge and consent that WealthBee may share such information and data with any third party with whom WealthBee has a contractual relationship to provide the requested product, service or functionality on behalf of wealthbee.io users and customers.

### No Unlawful or Prohibited Use/Intellectual Property

You are granted a non-exclusive, non-transferable, revocable license to access and use wealthbee.io strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to Option Tracker that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site.

All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of WealthBee or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto.

You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. Option Tracker content is not for resale. Your use of the Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your personal use, and will make no other use of the content without the express written permission of WealthBee and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of WealthBee or our licensors except as expressly authorized by these Terms.

You must not redistribute any information or data without our express consent. That consent can be sought from [matt@wealthbee.io](mailto:matt@wealthbee.io).

### Third Party Accounts

You will be able to connect your WealthBee account to third party accounts. By connecting your WealthBee account to your third party account, you acknowledge and agree that you are consenting to the continuous release of information about you to others (in accordance with your privacy settings on those third party sites). If you do not want information about you to be shared in this manner, do not use this feature.

### International Users

The Service is controlled, operated and administered by WealthBee from our offices within the UK. If you access the Service from a location outside the UK, you are responsible for compliance with all local laws. You agree that you will not use WealthBee Content accessed through wealthbee.io in any country or in any manner prohibited by applicable laws, restrictions or regulations.

### Indemnification

You agree to indemnify, defend and hold harmless WealthBee, its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable solicitor fees) relating to or arising out of your use of or inability to use the Site or services, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of any third party, or your violation of any applicable laws, rules or regulations. WealthBee reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with WealthBee in asserting any available defenses.

### Arbitration

In the event the parties are not able to resolve any dispute between them arising out of or concerning these Terms and Conditions, or any provisions hereof, whether in contract, tort, or otherwise at law or in equity for damages or any other relief, then such dispute shall be resolved only by final and binding arbitration pursuant to the Arbitration Act, conducted by a single neutral arbitrator and administered by the recommended approach in the UK Arbitration Act 1996, or a similar arbitration service selected by the parties, in a location mutually agreed upon by the parties. The arbitrator's award shall be final, and judgment may be entered upon it in any court having jurisdiction. In the event that any legal or equitable action, proceeding or arbitration arises out of or concerns these Terms and Conditions, the prevailing party shall be entitled to recover its costs and reasonable attorney's fees. The parties agree to arbitrate all disputes and claims in regards to these Terms and Conditions or any disputes arising as a result of these Terms and Conditions whether directly or indirectly, including Tort claims that are a result of these Terms and Conditions. The parties agree that the Arbitration Act governs the interpretation and enforcement of this provision. The entire dispute, including the scope and enforceability of this arbitration provision shall be determined by the Arbitrator. The arbitration provision shall survive the termination of these Terms and Conditions.

### Class Action Waiver

Any arbitration under these Terms and Conditions will take place on an individual basis; class arbitrations and class/representative/collective actions are not permitted.

THE PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/OR REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER.

Further, unless both you and WealthBee agree otherwise, the arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any form of a representative or class proceeding.

### Liability Disclaimer

THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. WealthBee Limited. AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.

WealthBee Limited. AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES, AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. WealthBee. AND/OR ITS SUPPLI
`}
        </Markdown>
      </Box>
      <Footer />

      <Cookies />
    </Box>
  );
};

export default Privacy;
