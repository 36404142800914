import {
  Avatar,
  AvatarGroup,
  Box,
  Heading,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Meta } from "../../components";
import NavBar from "../homepage/menu/App";
import Footer from "../homepage/footer/App";
import { Cookies } from "../homepage/components/Cookies";
import React from "react";
import { PrimaryButton } from "../../components/design_library";
import { ChatIcon, EmailIcon } from "@chakra-ui/icons";
import { RiDiscordFill } from "react-icons/ri";

const Contact = () => {
  return (
    <Box bg="var(--bg)">
      <Meta
        title={"Contact Us"}
        description={
          "We are always happy to hear from you. If you have any questions, feedback, or suggestions, please feel free to contact us."
        }
        canonical={"https://wealthbee.io/contact"}
      />
      <NavBar />
      <Box w="8xl" m="auto" mb="100px" mt="60px">
        <Box p={8} rounded="md" shadow="md">
          <VStack spacing={6} textAlign={"left"} alignItems={"flex-start"}>
            <Heading>Contact Us</Heading>

            <Text>
              We are always happy to hear from you. If you have any questions,
              feedback, or suggestions, please feel free to contact us:
            </Text>

            <HStack wrap="wrap" spacing="4" maxW="90vw">
              <BoxX
                title={"Live Chat"}
                text={
                  "You can find the live chat to talk to our team in the bottom right. We're always happy to talk whether its bugs, feedback or just to say hi."
                }
                buttonText={"Start Chat"}
                onClick={() => {
                  window.$crisp.push(["do", "chat:open"]);
                }}
                icon={<ChatIcon fontSize={"48px"} mb="10px" mr="10px" />}
              />
              <BoxX
                title={"Email"}
                text={"Email us directly using the button below"}
                buttonText={"Email"}
                onClick={() => {
                  window.location.href = "mailto:matt@wealthbee.io";
                }}
                icon={<EmailIcon fontSize={"48px"} mb="10px" mr="10px" />}
              />

              <BoxX
                title={"Community"}
                text={
                  "Join our Discord community to chat with other users and get support from our team."
                }
                buttonText={"Discord"}
                onClick={() => {
                  window.location.href = "https://discord.gg/5N9rJHhAbS";
                }}
                icon={<RiDiscordFill fontSize={"48px"} />}
              />
            </HStack>
          </VStack>
        </Box>
      </Box>
      <Footer />

      <Cookies />
    </Box>
  );
};

const BoxX = ({
  title,
  text,
  buttonText,
  onClick,
  icon = <ChatIcon />,
}: {
  title: string;
  text: string;
  buttonText: string;
  onClick: () => void;
  icon?: JSX.Element;
}) => {
  return (
    <Box
      w="400px"
      border="solid 2px var(--accent)"
      h="400px"
      p="20px"
      borderRadius="6px"
      display={"flex"}
      justifyContent={"space-between"}
      flexDir={"column"}
    >
      <VStack alignItems={"flex-start"}>
        <HStack>
          {icon}
          <Heading size="2xl" mb="20px">
            {title}
          </Heading>
        </HStack>
        <Text>{text}</Text>
      </VStack>
      <PrimaryButton mt="20px" onClick={onClick}>
        {buttonText}
      </PrimaryButton>
    </Box>
  );
};

export default Contact;
