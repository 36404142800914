import React from "react";
import {
  InsightComparisonProps,
  InsightBarchartProps,
  InsightNumberProps,
  InsightTableProps,
  InsightTemplate,
  InsightType,
} from "../../../../types/Insight";
import InsightNumber from "./InsightRenderers/Number/InsightNumber";
import StackedBarchart from "./InsightRenderers/Barchart/StackedBarchart";
import ComparisonNumber from "./InsightRenderers/ComparisonNumber/ComparisonNumber";
import Chart from "./InsightRenderers/Chart/Chart";
import Dendrogram from "./InsightRenderers/Dendrogram/Dendrogram";
import ProfitAndLossLineChart from "./InsightRenderers/ProfitAndLossLineChart/ProfitAndLossLineChart";
import InsightTable from "./InsightRenderers/Table/Table";
import { SizeByUnderlyingChart } from "../../../charts/SizeByUnderlying/SizeByUnderlyingChart";
import { SizeByPositionGroupChart } from "../../../charts/SizeByPositionGroup/SizeByPositionGroupChart";
import { SizeByBookChart } from "../../../charts/SizeByBook/SizeByBookChart";
import { SizeByStrategyChart } from "../../../charts/SizeByStrategy/SizeByStrategyChart";
import CalendarComponent from "../../../calendar/Calendar";

type RendererProps = {
  insight: InsightTemplate;
  demo?: boolean;
};

export const InsightRenderer: React.FC<RendererProps> = ({
  insight,
  demo,
}: RendererProps) => {
  switch (insight.type as InsightType) {
    case InsightType.Number:
      return (
        <InsightNumber
          title={insight.title}
          data={insight.data as InsightNumberProps}
          demo={demo}
        />
      );
    case InsightType.Table:
      return (
        <InsightTable
          query={insight.query}
          data={insight.data as InsightTableProps}
          demo={demo}
          title={insight.title}
        />
      );

    case InsightType.Comparison:
      return (
        <ComparisonNumber
          title={insight.title}
          data={insight.data as InsightComparisonProps}
          demo={demo}
        />
      );

    case InsightType.Barchart:
    case InsightType.Linechart:
      return (
        <Chart
          data={(insight.data as InsightBarchartProps)?.data}
          chartProperties={
            (insight.data as InsightBarchartProps)?.chartProperties
          }
        />
      );
    case InsightType.Treemap:
      switch (insight.query) {
        case "SIZE_BY_UNDERLYING":
          return (
            <SizeByUnderlyingChart
              data={(insight.data as InsightBarchartProps)?.data}
              chartProperties={
                (insight.data as InsightBarchartProps)?.chartProperties
              }
            />
          );
        case "SIZE_BY_POSITIONGROUP":
          return (
            <SizeByPositionGroupChart
              data={(insight.data as InsightBarchartProps)?.data}
              chartProperties={
                (insight.data as InsightBarchartProps)?.chartProperties
              }
            />
          );
        case "SIZE_BY_BOOK":
          return (
            <SizeByBookChart
              data={(insight.data as InsightBarchartProps)?.data}
              chartProperties={
                (insight.data as InsightBarchartProps)?.chartProperties
              }
            />
          );
        case "SIZE_BY_STRATEGY":
          return (
            <SizeByStrategyChart
              data={(insight.data as InsightBarchartProps)?.data}
              chartProperties={
                (insight.data as InsightBarchartProps)?.chartProperties
              }
            />
          );
      }
    // case InsightType.Dendrogram:
    //   return <Dendrogram tree={insight.data?.tree} />;
    // case InsightType.ProfitAndLossLineChart:
    //   return (
    //     <ProfitAndLossLineChart
    //       data={insight.data?.data}
    //       keys={insight.data?.keys}
    //       colors={insight.data?.colors}
    //       legendLabel={insight.data?.legend}
    //     />
    //   );
  }
  return <p>Undefined Insight</p>;
};
