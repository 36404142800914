import {
  Avatar,
  AvatarGroup,
  Box,
  Center,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { BiRightArrow, BiRightArrowAlt } from "react-icons/bi";
import { StarIcon } from "@chakra-ui/icons";
import { PrimaryButton } from "../../../components/design_library";
import WallOfLove from "../../../components/homepage/walloflove/WallOfLove";
import {
  IoCog,
  IoCube,
  IoDocument,
  IoLogoWebComponent,
  IoPencil,
} from "react-icons/io5";
import Xarrow from "react-xarrows";

export default function EnterpriseCTAText() {
  const iconFileUploadRef = useRef(null);
  const iconWebAppRef = useRef(null);
  const iconApiUploadRef = useRef(null);
  return (
    <Box
      maxW="full"
      pt={{ base: "80px", lg: "80px" }}
      // pl={{ md: "0px", base: "100px", lg: "20px" }}
      // pr={{ md: "0px", base: "100px", lg: "20px" }}
      flex="1"
      display={"flex"}
      flexDirection="column"
      alignItems={{
        base: "center",
        md: "center",
        lg: "center",
      }}
      textAlign={{ base: "center", md: "center", lg: "left" }}
      justifyContent={{ base: "center", md: "center", lg: "flex-start" }}
    >
      <Image
        src="https://cdn.wealthbee.io/main/app/authentication/candles.svg"
        pos="absolute"
        w="full"
        left="50%"
        transform={"translateX(-50%)"}
        justifyContent={"center"}
        zIndex={0}
      />

      <Heading as="h2" fontSize="20px" color="var(--white)" mb="10px">
        <Text color="var(--white)">For Enterprise</Text>
      </Heading>

      <Heading
        as="h1"
        pos={"relative"}
        fontSize={{ base: "32px", md: "48px", lg: "64px" }}
        fontWeight="700"
        lineHeight="1.2"
        letterSpacing="tight"
        textTransform="uppercase"
        maxW="830px"
        textAlign={"center"}
      >
        <Text color="var(--accent) !important">
          WealthBee P&L Analytics Platform
        </Text>
      </Heading>

      <Heading
        as="h2"
        fontSize="20px"
        pt="20px"
        textAlign={"center"}
        maxW="800px"
        fontWeight="400"
        lineHeight="1.4"
        color={"var(--white)"}
      >
        Improve efficiency, reduce costs and increase revenue with WealthBee.
      </Heading>

      <HStack justify={"flex-start"} alignItems="center" mt="22px">
        <PrimaryButton
          id="cta_1"
          as="a"
          href="#"
          onClick={() => {
            $crisp.push(["do", "chat:open"]);
            $crisp.push([
              "do",
              "message:send",
              [
                "text",
                `Hi! I'd like to discuss WealthBee's enterprise offering! `,
              ],
            ]);
          }}
          color={"var(--white)"}
          border="none !important"
          bg="var(--accent-dark) !important"
          mt="20px"
          fontSize="18px !important"
          minW="300px"
          h="60px"
        >
          Speak to an expert now
          <Box pl="6px">
            <BiRightArrowAlt />
          </Box>
        </PrimaryButton>
      </HStack>

      <Box
        w="full"
        display={"flex"}
        justifyContent={"center"}
        mt="50px"
        opacity={1}
        zIndex={9999}
        borderRadius={"6px"}
        border={"2px solid var(--bg)"}
      >
        {/* <Image
          src="https://cdn.wealthbee.io/main/app/enterprise/enterprise-dashboard.png"
          // pos="absolute"
          margin={"auto"}
          w="full"
          justifyContent={"center"}
          zIndex={9999}
          borderRadius={"10px"}
        /> */}

        <VStack w="100%">
          <HStack w="8xl" maxW="90vw" justifyContent={"space-around"}>
            <Box
              bg="var(--bg)"
              zIndex={999}
              opacity={1}
              w="100px"
              textAlign={"center"}
              ref={iconFileUploadRef}
            >
              <Text fontWeight={600}>File Upload</Text>
              <IoDocument size={"lg"} />
            </Box>
            <Box
              bg="var(--bg)"
              zIndex={999}
              opacity={1}
              w="100px"
              textAlign={"center"}
              ref={iconWebAppRef}
            >
              <Text fontWeight={600}>Web App</Text>
              <IoCube size="lg" />
            </Box>
            <Box
              bg="var(--bg)"
              zIndex={999}
              opacity={1}
              w="100px"
              textAlign={"center"}
              ref={iconApiUploadRef}
            >
              <Text fontWeight={600}>API</Text>
              <IoCog size="lg" />
            </Box>
          </HStack>

          <Center w="8xl" flexDir={"column"} marginTop="200px">
            <Box
              id="contentFrameContainer"
              bg="var(--dark-gray)"
              border={"1px solid var(--dark-gray)"}
              w="90%"
              borderTopLeftRadius={"8px"}
              borderTopRightRadius={"8px"}
              px="12px"
              py="8px"
              fontSize={"md"}
              textAlign={"center"}
            >
              Your embedded financial analytics
            </Box>
            <iframe
              id="contentFrame"
              src="https://wealthbee.io/embed?pid=47&did=188&apikey=d3759abd4f1e4b3cb1bc4fc62feca336"
              width="90%"
              style={{
                maxWidth: "100vw",
                height: "660px",
                border: "solid 4px var(--dark-gray)",
              }}
              scrolling="no"
            ></iframe>
          </Center>
        </VStack>
      </Box>

      <Xarrow
        start={iconFileUploadRef}
        end="contentFrameContainer"
        startAnchor={"bottom"}
        endAnchor={"top"}
        color="var(--accent)"
        strokeWidth={4}
        animateDrawing={true}
        // dashness={{ strokeLen: 10, nonStrokeLen: 15, animation: 2 }}
      />
      <Xarrow
        start={iconWebAppRef}
        end="contentFrameContainer"
        startAnchor={"bottom"}
        endAnchor={"top"}
        color="var(--accent)"
        strokeWidth={4}
        animateDrawing={true}
        // dashness={{ strokeLen: 10, nonStrokeLen: 15, animation: 22 }}
      />
      <Xarrow
        start={iconApiUploadRef}
        end="contentFrameContainer"
        startAnchor={"bottom"}
        endAnchor={"top"}
        color="var(--accent)"
        strokeWidth={4}
        animateDrawing={true}
        // dashness={{ strokeLen: 10, nonStrokeLen: 15, animation: 2 }}
      />
    </Box>
  );
}
