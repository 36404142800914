import React from "react";
import { Button, HStack, Icon, Text } from "@chakra-ui/react";
import { MoveIcon } from "../design_library";
import { CloseIcon } from "@chakra-ui/icons";
import { InsightTemplate } from "../../types/Insight";
import { useLayout } from "../../features/dashboard/helpers/Layout";

type InsightHeaderProps = {
  insight: InsightTemplate;
  preview?: boolean;
  embedded?: boolean;
};

export const InsightHeader: React.FC<InsightHeaderProps> = ({
  insight,
  preview,
  embedded,
}) => {
  const layout = useLayout();

  if (embedded) {
    return (
      <HStack
        w="full"
        justifyContent="space-between"
        px="5px"
        h="24px"
      ></HStack>
    );
  }

  return (
    <HStack w="full" justifyContent="space-between" px="5px" h="24px">
      <Icon
        className="drag-handle"
        stroke="var(--light-gray)"
        backgroundColor="var(--bg)"
        strokeWidth={4}
        as={MoveIcon}
        cursor="grab"
      />

      <Button
        bg="transparent"
        zIndex={2}
        fontSize="12px"
        transform={"translateX(12px) translateY(-2px)"}
        color="var(--light-gray)"
        onClick={(e) => {
          if (!preview) {
            return layout.removeInsightFromLayouts(insight);
          }
        }}
      >
        <CloseIcon />
      </Button>
    </HStack>
  );
};
