import { AgGridReact } from "ag-grid-react";
import mixpanel from "mixpanel-browser";
import React, { useState } from "react";
import {
  GridContainer,
  columnTypes,
  distinctValue,
} from "../../../../components";
import { tradeColumnDefinitions } from "../tradeColumnDefinitions";
import { useGridContextMenuController } from "../../../../components/grid/utils/useGridContextMenuController";
import { useGridState } from "../../../../components/grid/useGridState";
import { Box } from "@chakra-ui/react";
import { NotesModal } from "../../../notes/Notes";
import "./TradesAgGrid.css";
import { columnDefinitions } from "../../columnDefinitions";
import { PositionsInnerRender } from "../../grid/PositionsInnerRender";

export default function PositionsAgGrid(props: {
  trades: any;
  topLeftRef?: any;
}) {
  const contextController = useGridContextMenuController();

  const gridState = useGridState({ gridName: "Positions" });

  return (
    <>
      <GridContainer customClass={"gridContainerTradesViewHook"}>
        <AgGridReact
          autoGroupColumnDef={{
            cellRendererParams: {
              suppressCount: true,
              innerRenderer: PositionsInnerRender,
            },
            width: 600,
            sortable: true,
            resizable: true,
            headerName: "Book",
          }}
          rowHeight={31}
          rowGroupPanelShow="always"
          groupDefaultExpanded={-1}
          reactiveCustomComponents={true}
          rowData={props.trades} // Row Data for Rows
          columnDefs={columnDefinitions} // Column Defs for Columns
          columnTypes={columnTypes} // Optional - custom column types
          suppressAggFilteredOnly={false}
          rowDragManaged={false}
          suppressAggFuncInHeader={true}
          getContextMenuItems={contextController.getContextMenuItems}
          suppressChangeDetection={false}
          aggFuncs={{
            distinct: distinctValue,
          }}
          sideBar={{
            toolPanels: [
              {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel",
                toolPanelParams: {
                  suppressPivotMode: true,
                  suppressValues: true,
                  suppressRowGroups: true,
                },
              },
            ],
            position: "left",
            defaultToolPanel: "filters",
          }}
          onGridReady={gridState.onGridReady}
          onFirstDataRendered={gridState.onFirstDataRendered}
          onColumnEverythingChanged={gridState.onColumnChanged}
          onColumnResized={gridState.onColumnResized}
          onSortChanged={gridState.onColumnChanged}
        />
      </GridContainer>
    </>
  );
}
