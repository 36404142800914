import { Center, VStack, Icon, Text } from "@chakra-ui/react";
import React from "react";

export default function InvalidAPIKey() {
  return (
    <Center h="100vh">
      <VStack>
        <Icon name="warning" color="red.500" fontSize="4xl" />
        <Text>Invalid API Key</Text>
      </VStack>
    </Center>
  );
}
