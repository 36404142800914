import {
  Box,
  Button,
  Container,
  Heading,
  Icon,
  SimpleGrid,
  Square,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FiArrowRight } from "react-icons/fi";
import { features } from "./FeatureListData";
import React from "react";

export const FeatureList = () => (
  <Box bg="var(--dark-gray)" py="120px">
    <Container maxW="6xl">
      <Stack spacing={{ base: "12", md: "16" }}>
        <Stack spacing={{ base: "4", md: "5" }} w="full">
          <Stack spacing="3" textAlign={"center"} justifyContent={"center"}>
            <Text
              fontSize={{ base: "sm", md: "md" }}
              fontWeight="semibold"
              color="accent"
            >
              Program Details
            </Text>
            <Heading size={{ base: "sm", md: "md" }}>
              What can you expect?
            </Heading>
          </Stack>
          <Text fontSize={{ base: "lg", md: "xl" }} textAlign={"center"}>
            A lifetime, 30% cut of all revenue generated by users you refer to
            us
          </Text>
        </Stack>
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3 }}
          columnGap={8}
          rowGap={{ base: 10, md: 16 }}
          justifyContent="center" // Centers horizontally
          alignItems="center" // Centers vertically
        >
          {features.map((feature) => (
            <Stack key={feature.name} spacing={{ base: "4", md: "5" }}>
              <Square
                size={{ base: "10", md: "12" }}
                bg="var(--bg)"
                borderRadius="lg"
              >
                <Icon
                  as={feature.icon}
                  boxSize={{ base: "5", md: "6" }}
                  color="var(--accent)"
                />
              </Square>
              <Stack spacing={{ base: "1", md: "2" }} flex="1">
                <Text fontSize={{ base: "lg", md: "xl" }} fontWeight="medium">
                  {feature.name}
                </Text>
                <Text>{feature.description}</Text>
              </Stack>
            </Stack>
          ))}
        </SimpleGrid>
      </Stack>
    </Container>
  </Box>
);
