import React from "react";
import {
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import {
  GridContainer,
  Spinner,
  columnTypes,
  distinctValue,
  isMobile,
} from "../../components";
import { useEffect, useState } from "react";
import { LicenseManager } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import "./PositionGrid.css";
import { usePositionController } from "./usePositionController";
import { TradesView } from "./tradesview/TradesView";
import { RowDoubleClickedEvent, RowSelectedEvent } from "ag-grid-community";
import { useGridContextMenuController } from "../../components/grid/utils/useGridContextMenuController";
import { PositionsInnerRender } from "./grid/PositionsInnerRender";
import mixpanel from "mixpanel-browser";
import { useTour } from "@reactour/tour";
import { progressTour } from "../../components/tutorial/util/progressTour";
import { useGridState } from "../../components/grid/useGridState";
import { Toolbar } from "../../components/toolbar/Toolbar";
import {
  KnowledgeBaseHelperTopics,
  Position,
  ToolbarFeatures,
} from "../../types";
import { FaRegLightbulb } from "react-icons/fa";
import Uploads from "../uploads/Uploads";
import ExpirePositionModal from "../../components/grid/utils/contextMenu/ExpirePositionModal";
import ClosePositionModal from "../../components/grid/utils/contextMenu/ClosePositionModal";

LicenseManager.setLicenseKey(
  "Using_this_AG_Grid_Enterprise_key_( AG-042988 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Option Tracker Ltd )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Option Tracker )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Option Tracker )_need_to_be_licensed___( Option Tracker )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 26 June 2024 )____[v2]_MTcxOTM1NjQwMDAwMA==da26b5a51856f363e61b37e5775314c6"
);

const Positions = () => {
  const { isOpen, currentStep, steps, setIsOpen, setCurrentStep, setSteps } =
    useTour();

  const [showTrades, setShowTrades] = useState(false);
  const [tradeIds, setTradeIds] = useState<string[]>([]);
  const [selectedParams, setSelectedParams] = useState<any>({});
  const controller = usePositionController();
  const [selectedPositions, setSelectedPositions] = useState<Position[]>([]);

  const determineTradeIds = (rowIds: number[]) => {
    if (rowIds.length > 0) {
      setTradeIds(controller.getTradeIds(rowIds));
    }
  };

  const onRowSelected = (e: RowSelectedEvent) => {
    if (e.node.data && e.node.data.id) {
      determineTradeIds([e.node.data.id]);
    }
  };

  const openPane = (params: RowDoubleClickedEvent, dontOpen: boolean) => {
    if (!params.node.isSelected()) {
      return;
    }

    if (params.node.group) {
      const ids = params.node.allLeafChildren.map((x) => x.data.id);
      setSelectedPositions(
        controller.positions.filter((x) => ids.includes(x.id))
      );
      determineTradeIds(ids);

      if (!dontOpen) {
        setShowTrades(true);
      }
    } else if (params.node.data && params.node.data.id) {
      determineTradeIds([params.node.data.id]);

      if (!dontOpen) {
        setShowTrades(true);
      }
    }

    setSelectedParams(params);
  };

  useEffect(() => {
    if (localStorage.getItem("token") === null) {
      window.location.href = "/app/login";
    }
  }, []);

  return (
    <>
      <Flex h="full" direction="column">
        <Toolbar
          enabledOptions={[
            ToolbarFeatures.FILTER_SELECTOR,
            ToolbarFeatures.STATUS,
            ToolbarFeatures.GROUPING,
            ToolbarFeatures.HELP,
            ToolbarFeatures.PRICING_QUEUE,
          ]}
          helpKey={KnowledgeBaseHelperTopics.POSITIONS}
          positionsController={controller}
        />

        {controller.positions.length === 0 ? (
          <Center h="70%">
            <VStack>
              <FaRegLightbulb size="50" mb="10px" />
              <Heading size="md">No Positions Found</Heading>
              <Text maxW="300px" textAlign={"center"} mb="20px">
                Please add your trades to see positions.
              </Text>
              <Uploads />
            </VStack>
          </Center>
        ) : (
          <Flex flex={1}>
            <Allotment vertical separator={true}>
              <GridContainer>
                <>
                  {controller.switchingColDef === true && (
                    <Center h="70%">
                      <VStack>
                        <Spinner />
                        <Text fontSize="14px">Switching layout...</Text>
                      </VStack>
                    </Center>
                  )}
                  {controller.switchingColDef === false && (
                    <>
                      <AGGridWrapper
                        controller={controller}
                        openPane={openPane}
                        onRowSelected={onRowSelected}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        setIsOpen={setIsOpen}
                      />
                    </>
                  )}
                </>
              </GridContainer>

              <Allotment.Pane
                visible={showTrades}
                preferredSize={400}
                minSize={200}
              >
                <TradesView
                  onClose={() => setShowTrades(false)}
                  params={selectedParams}
                  tradeIds={tradeIds}
                  positions={selectedPositions}
                />
              </Allotment.Pane>
            </Allotment>
          </Flex>
        )}
      </Flex>
    </>
  );
};

const AGGridWrapper = (props: any) => {
  const gridState = useGridState({
    gridName: `Positions-${props.controller.grouping}`,
  });

  const {
    isOpen: isExpireModalOpen,
    onOpen: onOpenExpireModal,
    onClose: onCloseExpireModal,
  } = useDisclosure();

  const {
    isOpen: isCloseModalOpen,
    onOpen: onOpenCloseModal,
    onClose: onCloseCloseModal,
  } = useDisclosure();

  const [closingPrice, setClosingPrice] = useState("");
  const [closingDate, setClosingDate] = useState("");
  const [closingFees, setClosingFees] = useState("");
  const [closingCommission, setClosingCommission] = useState("");
  const [closingNotes, setClosingNotes] = useState("");

  const { getContextMenuItems, handleExpire, handleClose } =
    useGridContextMenuController(
      onOpenExpireModal,
      onCloseExpireModal,
      onOpenCloseModal,
      onCloseCloseModal,
      () => {},
      () => {}
    );

  const handleConfirmExpire = () => {
    handleExpire(closingFees, closingCommission, closingNotes);
    onCloseExpireModal();
  };

  const handleConfirmClose = () => {
    handleClose(
      closingPrice,
      closingDate,
      closingFees,
      closingCommission,
      closingNotes
    );
    onCloseCloseModal();
  };

  return (
    <>
      <AgGridReact
        autoGroupColumnDef={{
          cellRendererParams: {
            suppressCount: true,
            innerRenderer: PositionsInnerRender,
          },
          width: 600,
          sortable: true,
          resizable: true,
          headerName: "Book",
        }}
        rowData={props.controller.positions} // Row Data for Rows
        columnDefs={props.controller.columnDefs} // Column Defs for Columns
        columnTypes={columnTypes} // Optional - custom column types
        // rowSelection="single" // Options - allows click selection of rows
        rowSelection="multiple" // Options - allows click selection of rows
        rowHeight={31}
        showOpenedGroup={true}
        suppressAggFilteredOnly={false}
        suppressAggFuncInHeader={true}
        ref={props.controller.gridApiRef}
        sideBar={{
          toolPanels: [
            {
              id: "columns",
              labelDefault: "Columns",
              labelKey: "columns",
              iconKey: "columns",
              toolPanel: "agColumnsToolPanel",
              toolPanelParams: {
                suppressPivotMode: true,
                suppressValues: true,
                suppressRowGroups: true,
              },
            },
          ],
          position: "left",
          defaultToolPanel: "filters",
        }}
        rowDragEntireRow={isMobile}
        suppressChangeDetection={false}
        getContextMenuItems={getContextMenuItems}
        reactiveCustomComponents={true}
        aggFuncs={{
          distinct: distinctValue,
          none: () => {
            return "";
          },
        }}
        onRowGroupOpened={(params) => {
          progressTour(
            params,
            props.setCurrentStep,
            props.currentStep,
            props.setIsOpen
          );

          if (
            window.location.href.includes("tutorial=true") ||
            window.location.href.includes("result=paid")
          ) {
            if (params.node.level === 2) {
              props.openPane(params);
            }
          }

          gridState.performGroupChanged(params);
        }}
        rowDragManaged={false}
        rowDragMultiRow={true}
        getRowId={props.controller.getRowId}
        onRowSelected={(e: RowSelectedEvent) => {
          props.onRowSelected(e);
          // openPane(e, true);
        }}
        onRowDoubleClicked={(params: RowDoubleClickedEvent) => {
          mixpanel.track("Position Double Clicked");
          props.openPane(params, false);
        }}
        loadingOverlayComponent={props.controller.loadingOverlayComponent}
        loadingOverlayComponentParams={
          props.controller.loadingOverlayComponentParams
        }
        noRowsOverlayComponent={props.controller.noRowsOverlayComponent}
        noRowsOverlayComponentParams={
          props.controller.noRowsOverlayComponentParams
        }
        onRowDragMove={props.controller.onRowDragMove}
        onRowDragEnd={props.controller.onRowDragEnd}
        animateRows={false}
        onGridReady={gridState.onGridReady}
        onFirstDataRendered={gridState.onFirstDataRendered} // Disabled as breaks grouping...
        onColumnEverythingChanged={gridState.onColumnChanged}
        onColumnResized={gridState.onColumnResized}
        onColumnMoved={gridState.onColumnMoved}
        onSortChanged={gridState.onColumnChanged}
      />

      <ExpirePositionModal
        isOpen={isExpireModalOpen}
        onClose={onCloseExpireModal}
        onConfirm={handleConfirmExpire}
        closingFees={closingFees}
        setClosingFees={setClosingFees}
        closingCommission={closingCommission}
        setClosingCommission={setClosingCommission}
        closingNotes={closingNotes}
        setClosingNotes={setClosingNotes}
      />
      <ClosePositionModal
        isOpen={isCloseModalOpen}
        onClose={onCloseCloseModal}
        onConfirm={handleConfirmClose}
        closingPrice={closingPrice}
        setClosingPrice={setClosingPrice}
        closingDate={closingDate}
        setClosingDate={setClosingDate}
        closingFees={closingFees}
        setClosingFees={setClosingFees}
        closingCommission={closingCommission}
        setClosingCommission={setClosingCommission}
        closingNotes={closingNotes}
        setClosingNotes={setClosingNotes}
      />
    </>
  );
};
export default Positions;
