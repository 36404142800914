import { Box, HStack, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useAtomValue } from "jotai";
import { AccountAtom } from "../../store";
import StarterKit from "@tiptap/starter-kit";
import { EditorProvider } from "@tiptap/react";
import { MenuBar } from "../../components/texteditor/MenuBar";
import "../texteditor/Notes.css";
import { useDebounce } from "../../features/hooks";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import { UpdateCalendarNote } from "../../api/CalendarNotes";
import Placeholder from "@tiptap/extension-placeholder";

export default function CalendarNoteEditor({
  note,
  menuBarEnabled,
  tagsEnabled,
}: {
  note: any;
  menuBarEnabled?: boolean;
  tagsEnabled?: boolean;
}) {
  const accountState = useAtomValue(AccountAtom);
  const [noteSaving, setNoteSaving] = React.useState(false);

  const [notes, setNotes] = React.useState<string>(note.content);

  const saveText = async (value: string) => {
    setNoteSaving(true);
    const updatedNote = await UpdateCalendarNote(
      {
        date: note.date,
        content: value,
        id: note.id,
      },
      accountState.selectedAccount.id
    );

    setTimeout(() => {
      setNoteSaving(false);
    }, 400);
  };

  const onNoteFieldChange = useDebounce(saveText, 100);

  useEffect(() => {
    // setNotes(trade.notes ? trade.notes : "");
  }, [note.content]);

  const extensions = [
    StarterKit.configure({
      heading: {
        levels: [1, 2, 3, 4, 5, 6],
      },
    }),
    Image.configure({
      inline: true,
      allowBase64: true,
    }),
    Link.configure({
      openOnClick: false,
      autolink: true,
      defaultProtocol: "https",
    }),
    Placeholder.configure({
      placeholder: "Add you notes here...",
    }),
  ];

  return (
    <EditorProvider
      slotBefore={<Box mb="20px">{menuBarEnabled ? <MenuBar /> : null}</Box>}
      slotAfter={
        <>
          {noteSaving && (
            <Box py="10px" pos="absolute">
              <Box>
                <HStack>
                  <Spinner minW="20px" />
                  <Text>Saving...</Text>
                </HStack>
              </Box>
            </Box>
          )}
        </>
      }
      extensions={extensions}
      content={note.content}
      onUpdate={(e) => {
        setNotes(e.editor.getHTML());
        onNoteFieldChange(e.editor.getHTML());
      }}
      editorProps={{
        attributes: {
          class: "prose",
        },
      }}
    ></EditorProvider>
  );
}
