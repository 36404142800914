import React from "react";
import NavWrapper from "./NavWrapper";
import { Box, HStack, Heading, Text } from "@chakra-ui/react";
import { BiBook, BiBookOpen } from "react-icons/bi";
import { FaBookOpen, FaObjectGroup } from "react-icons/fa";
import { StockLogo } from "../../design_library";

export default function PositionsGridExplainer() {
  return (
    <NavWrapper
      previousEnabled={false}
      isFinalStep={false}
      nextDisabled={false}
    >
      <Box textAlign={"left"}>
        <Heading
          as="h2"
          fontSize="18px"
          mb={4}
          fontWeight={600}
          textAlign={"left"}
        >
          How to navigate WealthBee
        </Heading>
        <Text mt="10px">There are some pages for you to know about: </Text>
        <Text mt="10px">
          <span style={{ color: "var(--accent)" }}>Dashboard: </span>
          High level analytics, charts, graphs, tables, insights and more.
        </Text>
        <Text mt="10px">
          <span style={{ color: "var(--accent)" }}>Positions: </span>
          We're on the positions page currently. The positions page is where you
          view stats about your trades, you can filter these along the top. By
          default we show everything.
        </Text>
        <Text mt="10px">
          <span style={{ color: "var(--accent)" }}>Transactions: </span>
          Here you can group your trades by book, underlying, position group and
          add strategies.
        </Text>
        <Text mt="10px">
          <span style={{ color: "var(--accent)" }}>Cashflows: </span>
          Displays dividends, taxes, interest and more.
        </Text>
        <Text mt="10px">
          <span style={{ color: "var(--accent)" }}>Calendar: </span>
          View upcoming events, expiries, daily P&L and take journal notes by
          day..
        </Text>

        <Text mt="40px">
          There are 4 levels of grouping in the positions grid.{" "}
        </Text>
        <HStack mt="10px">
          <HStack>
            <FaBookOpen />
            <Text>Book,</Text>
          </HStack>
          <HStack>
            <StockLogo size="20" stock="NVDA" />
            <Text>Underlying,</Text>
          </HStack>
          <HStack>
            <FaObjectGroup />
            <Text>Position Group & </Text>
          </HStack>
          <HStack>
            <Text>Position</Text>
          </HStack>
        </HStack>
        <Text mt="40px">A book is a collection of positions. </Text>
      </Box>
    </NavWrapper>
  );
}
