import { APIEndpoint } from ".";

const token = localStorage.getItem("token");

export const getPositions = async (
  accountId: number,
  toDate?: Date,
  fromDate?: Date
  // book?: string,
  // positionGroup?: string,
  // status?: string,
  // underlying?: string,
  // tag?: string
) => {
  let url = `${APIEndpoint}/positions?toDate=${toDate?.toISOString()}&fromDate=${fromDate?.toISOString()}`;
  if (!toDate || !fromDate) url = `${APIEndpoint}/positions`;
  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: token || "",
      AccountId: accountId as any,
    },
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error getting positions:", error));
};

export const compilePositionsForAllusers = async () => {
  return fetch(`${APIEndpoint}/positions/compile/all`, {
    method: "POST",
    headers: {
      Authorization: token || "",
    },
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error compiling positions:", error));
};

export const compilePositionsForMe = async (accountId: number | undefined) => {
  return fetch(`${APIEndpoint}/positions/compile`, {
    method: "POST",
    headers: {
      Authorization: token || "",
      AccountId: accountId as any,
    },
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error compiling positions:", error));
};
