import { Box, HStack, Heading, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ChevronRight, StockLogo } from "../../design_library";
import NavWrapper from "./NavWrapper";
import { useTour } from "@reactour/tour";

export default function UnderlyingExplainerTutorial() {
  return (
    <NavWrapper previousEnabled={false} isFinalStep={false} nextDisabled={true}>
      <HStack>
        <StockLogo stock={"MSFT"} size="16px" />
        <StockLogo stock={"NVDA"} size="16px" />
        <StockLogo stock={"RIOT"} size="16px" />

        <Heading
          as="h2"
          fontSize="18px"
          mb={4}
          fontWeight={600}
          textAlign={"left"}
        >
          Underlyings
        </Heading>
      </HStack>
      <Text mt="10px">
        Within a book you can see the underlyings that make up the book. Each
        row group represents a unique underlying.
      </Text>
      <Text mt="10px">
        For every row you can see the total P&L and other aggregated metrics for
        the underlying to the right.
      </Text>
      <Text mt="10px">
        Like the book, you can right click this row to assign it to another book
        or assign all positions to a different position group.
      </Text>
      <Text mt="10px">
        To expand the underlying and see the positions groups:
      </Text>

      <VStack alignItems={"flex-start"}>
        <HStack>
          <Text>Mobile:</Text>
          <span className="rainbow">
            Tap the 'right arrow' (Chevron) next to the group{" "}
          </span>
        </HStack>
        <HStack>
          <Text>Desktop:</Text>
          <span className="rainbow">
            Double-click the first underlying underneath the book named
            'Unbooked'{" "}
          </span>
        </HStack>
      </VStack>
    </NavWrapper>
  );
}
