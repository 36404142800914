import { useEffect, useRef, useState } from "react";
import { createAccount, deleteAccount, updateAccount } from "../../api";
import { usePositions } from "../../hooks";
import { useAccount } from "../../hooks/useAccount";
import { useTransactions } from "../../hooks/useTransactions";
import { deleteAllTrades } from "../../api/Trades";
import { deleteAllCashFlows } from "../../api/Cashflow";
import { Account } from "../../types/Account";
import { useDebounce } from "../../features/hooks";

interface AccountController {
  accountForm: any;
  editingId: string | null;
  handleFormChange: (e: any) => void;
  handleSubmit: (e: any) => void;
  handleEdit: (account: any) => void;
  handleDelete: (accountId: number) => void;
  setSelectedAccount: (account: any) => void;
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  deleteAllTheTrades: (accountId: number) => void;
  updateAccountName: (
    accountId: number,
    name: string,
    account: Account
  ) => void;
}

const useAccountController = () => {
  const [accountForm, setAccountForm] = useState({ name: "", userId: "" });
  const [editingId, setEditingId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const initialised = useRef<boolean>(false);

  const positions = usePositions();
  const accounts = useAccount();
  const transactions = useTransactions();

  useEffect(() => {
    if (initialised.current === false) {
      initialised.current = true;
      fetchAccounts();
    }
  }, []);

  const deleteAllTheTrades = async (accountId: number) => {
    await deleteAllTrades(accountId);
    await deleteAllCashFlows(accountId);
    fetchAccounts();

    transactions.getTradesAndCashflows(accountId);
    positions.fetchPositions(accountId);
  };

  const setSelectedAccount = async (account: any) => {
    accounts.setSelectedAccount(account);
    transactions.getTradesAndCashflows(account.id);
    positions.fetchPositions(account.id);
  };

  const fetchAccounts = async () => {
    accounts.retrieveAccounts();
  };

  const handleFormChange = (e) => {
    setAccountForm({ ...accountForm, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editingId) {
      await updateAccount(editingId, accountForm);
    } else {
      await createAccount(accountForm);
    }
    setAccountForm({ name: "", userId: "" });
    setEditingId(null);
    fetchAccounts();
  };

  const handleEdit = (account) => {
    setAccountForm({ name: account.name, userId: account.userId });
    setEditingId(account.id);
  };

  const handleDelete = async (accountId: number) => {
    const result = await deleteAccount(accountId);
    window.location.reload();
  };

  const updateAccountName = useDebounce(
    async (accountId: number, name: string, account: Account) => {
      await updateAccount(accountId, {
        ...account,
        name: name,
      });
      fetchAccounts();
    },
    200
  );

  return {
    accountForm,
    editingId,
    handleFormChange,
    handleSubmit,
    handleEdit,
    handleDelete,
    setSelectedAccount,
    modalOpen,
    setModalOpen,
    deleteAllTheTrades,
    updateAccountName,
  } as AccountController;
};

export default useAccountController;
