import { useSetAtom } from "jotai";
import { getPositions } from "../api";
import { PositionStateAtom } from "../store";
import { Position } from "../types/Position";
import { useNotifications } from "./useNotifications";
import { useFilters } from "./useFilters";

export interface UsePositions {
  fetchPositions: (accountId: string) => void;
}

// this hook is used to fetch positions for the selected account and is called from various places in the app
// e.g. on account selection and on initialisation after authentication
export const usePositions = () => {
  const setPositionState = useSetAtom(PositionStateAtom);
  const filtersHook = useFilters();
  const notifications = useNotifications();

  const determineOpenClosedPostions = (
    positions: Position[]
  ): { open: number[]; closed: number[] } => {
    const openPositions: number[] = [];
    const closedPositions: number[] = [];

    const grouped: Record<string, { quantity: number; positionId: number[] }> =
      {};

    // group all positions that belong together, together
    for (let position of positions) {
      const positionKey = `${position.book ?? ""}-${
        position.underlyingSymbol
      }:${position.underlyingExchange}-${position.positionName ?? ""}`;

      if (!grouped[positionKey]) {
        grouped[positionKey] = {
          quantity: position.quantity,
          positionId: [position.positionId],
        };
      } else {
        const groupedPosition = grouped[positionKey];
        groupedPosition.quantity += position.quantity;
        groupedPosition.positionId.push(position.positionId);
      }
    }

    // determine whats open and whats closed
    for (let key in grouped) {
      const group = grouped[key];
      if (group.quantity !== 0) {
        openPositions.push(...group.positionId);
      } else {
        closedPositions.push(...group.positionId);
      }
    }

    return { open: openPositions, closed: closedPositions };
  };

  const fetchPositions = async (
    accountId: number
    // toDate?: Date,
    // fromDate?: Date
  ) => {
    notifications.createPositionNotification("Retrieving positions...", "");
    const positions = await getPositions(
      accountId
      //  toDate, fromDate
    );

    if (positions) {
      const underlyings = Array.from(
        new Set(
          positions.data.map((x: any) => {
            if (x.underlyingType === "Future") {
              return x.underlyingName;
            } else {
              return x.underlyingSymbol;
            }
          })
        )
      ).sort();
      if (underlyings) {
        filtersHook.setUnderlyings(underlyings);
      }

      setPositionState((prevState) => {
        const { open, closed } = determineOpenClosedPostions(positions.data);
        notifications.clearNotifications("position");

        const rows = positions.data.map((x: any) => {
          return {
            ...x,
            id: x.positionId,
            accountId: accountId,
            positionName: x.positionGroup,
            instrument: {
              symbol: x.symbol,
              exchange: x.exchange,
              name: x.name,
              instrumentType: x.instrumentType,
              putCall: x.putCall,
              strike: x.strike,
              expiry: x.expiry,
              multiplier: x.multiplier,
            },
            audit: x.audit ? x.audit : undefined,
          };
        });

        // manipulate the data to fit the old position ui schema
        return {
          ...prevState,
          positions: rows,
          openPositions: open,
          closedPositions: closed,
        };
      });
    }
  };

  return {
    fetchPositions,
  };
};
