import React from "react";
import { Box, Stack } from "@chakra-ui/react";
import { MaintenanceModeToggleButton } from "../../../components/maintenance/MaintenanceMode";
import { PricingEnabledButton } from "./components/PricingEnabledButton";
import { InstrumentLogosButton } from "./components/InstrumentLogosButton";
import RegeneratePositions from "../../../components/positions/RegeneratePositions";

export default function WebsiteSettings() {
  return (
    <Box>
      <Stack bg="var(--dark-gray)" p={4} borderRadius={8} spacing={"4"}>
        <MaintenanceModeToggleButton />
        <PricingEnabledButton />
        <InstrumentLogosButton />
      </Stack>
    </Box>
  );
}
