import { useEffect, useState } from "react";
import { useAtomValue } from "jotai";
import { AccountAtom } from "../../store";
import { SyncSetting } from "../../types";
import { getSyncSettings, runSyncSetting } from "../../api";
import { useNotifications } from "../../hooks/useNotifications";

interface useAutoSyncControllerResponse {
  settings: SyncSetting[];
  selectedSettings?: SyncSetting;
  setSelectedSettings: (setting?: SyncSetting) => void;
  showConfigure: boolean;
  setShowConfigure: (show: boolean) => void;
  run: (setting: SyncSetting) => void;
}

export const useAutoSyncController = (): useAutoSyncControllerResponse => {
  const accountState = useAtomValue(AccountAtom);
  const notifications = useNotifications();

  const [settings, setSettings] = useState<SyncSetting[]>([]);
  const [selectedSettings, setSelectedSettings] = useState<
    SyncSetting | undefined
  >();
  const [showConfigure, setShowConfigure] = useState<boolean>(false);

  const loadSettings = async () => {
    if (accountState.selectedAccount?.id) {
      const result = await getSyncSettings(accountState.selectedAccount?.id);
      setSettings(result ?? []);
    }
  };

  const showConfig = async (show: boolean) => {
    await setShowConfigure(show);
    if (!show) {
      await loadSettings();
    }
  };

  const run = async (setting: SyncSetting) => {
    if (accountState.selectedAccount?.id) {
      notifications.createNotification(
        "Auto Sync Settings",
        `Running ${setting.broker} Sync...`
      );
      const result = await runSyncSetting(
        setting,
        accountState.selectedAccount?.id
      );

      if (result === false) {
        alert("Failed to run sync settings!");
      }
    }
  };

  useEffect(() => {
    loadSettings();
  }, [accountState]);

  return {
    settings,
    selectedSettings,
    setSelectedSettings,
    showConfigure,
    setShowConfigure: showConfig,
    run,
  };
};
