import { AgGridReact } from "ag-grid-react";
import mixpanel from "mixpanel-browser";
import React, { useEffect, useState } from "react";
import {
  GridContainer,
  columnTypes,
  distinctValue,
} from "../../../../components";
import { tradeColumnDefinitions } from "../tradeColumnDefinitions";
import { useGridContextMenuController } from "../../../../components/grid/utils/useGridContextMenuController";
import { useGridState } from "../../../../components/grid/useGridState";
import { Box, useDisclosure } from "@chakra-ui/react";
import { NotesModal } from "../../../notes/Notes";
import "./TradesAgGrid.css";
import EditTradeModal from "../../../../components/grid/utils/contextMenu/EditTradeModal";
import { Trade } from "../../../../types";

// Renders the TradeView AGgrid with note editing.
export default function TradesAgGrid(props: { trades: any; topLeftRef?: any }) {
  const gridState = useGridState({ gridName: "TradeView" });

  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [noteModalOpen, setNoteModalOpen] = useState<boolean>(false);

  const {
    isOpen: isEditModalOpen,
    onOpen: onOpenEditModal,
    onClose: onCloseEditModal,
  } = useDisclosure();

  const { getContextMenuItems, selectedParams } = useGridContextMenuController(
    () => {},
    () => {},
    () => {},
    () => {},
    onOpenEditModal,
    onCloseEditModal
  );

  return (
    <>
      {noteModalOpen && (
        <NotesModal
          isOpen={noteModalOpen}
          onClose={() => {
            setNoteModalOpen(false);
            setSelectedRow(null);
          }}
          trade={selectedRow}
        />
      )}

      <EditTradeModal
        isOpen={isEditModalOpen}
        onClose={onCloseEditModal}
        onConfirm={onCloseEditModal}
        selectedParams={selectedParams}
      />

      <GridContainer customClass={"gridContainerTradesViewHook"}>
        <AgGridReact
          rowHeight={31}
          rowGroupPanelShow="always"
          groupDefaultExpanded={-1}
          reactiveCustomComponents={true}
          rowData={props.trades} // Row Data for Rows
          columnDefs={tradeColumnDefinitions} // Column Defs for Columns
          columnTypes={columnTypes} // Optional - custom column types
          suppressAggFilteredOnly={false}
          rowDragManaged={false}
          suppressAggFuncInHeader={true}
          getContextMenuItems={getContextMenuItems}
          suppressChangeDetection={false}
          aggFuncs={{
            distinct: distinctValue,
          }}
          sideBar={{
            toolPanels: [
              {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel",
                toolPanelParams: {
                  suppressPivotMode: true,
                  suppressValues: true,
                  suppressRowGroups: true,
                },
              },
            ],
            position: "left",
            defaultToolPanel: "filters",
          }}
          onRowDoubleClicked={(params) => {
            setSelectedRow(params.data);
            setNoteModalOpen(true);

            if (props.topLeftRef.current) {
              props.topLeftRef.current.focus();
            }
          }}
          onGridReady={gridState.onGridReady}
          onFirstDataRendered={gridState.onFirstDataRendered}
          onColumnEverythingChanged={gridState.onColumnChanged}
          onColumnResized={gridState.onColumnResized}
          onColumnMoved={gridState.onColumnMoved}
          onSortChanged={gridState.onColumnChanged}
        />
      </GridContainer>
    </>
  );
}
