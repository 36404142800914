import {
  Bs0Circle,
  BsCash,
  BsCashCoin,
  BsCookie,
  BsFillMoonFill,
  BsPaypal,
  BsStars,
} from "react-icons/bs";
import { FaAccessibleIcon, FaExpandAlt, FaPaintBrush } from "react-icons/fa";
import { IoRocketSharp } from "react-icons/io5";

export const features = [
  {
    name: "30% Lifetime Revenue",
    description:
      "A lifetime, 30% cut of all revenue generated by users you refer to us.",
    icon: BsCash,
  },
  {
    name: "60 Day Cookie",
    description:
      "If a user clicks on your affiliate link, you will receive a commission for any purchases made within 60 days.",
    icon: BsCookie,
  },
  {
    name: "Commission on Monthly",
    description:
      "On a $35 monthly subscription, you will receive $10.50~ every month.",
    icon: BsCashCoin,
  },
  {
    name: "Commission on Annual",
    description:
      "On a $350 annual subscription, you will receive $105~ every year.",
    icon: BsCashCoin,
  },
  {
    name: "Payout Via Wise or PayPal",
    description: "We pay out your commissions via Wise or PayPal. You choose.",
    icon: BsPaypal,
  },
];
