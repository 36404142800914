import React, { useEffect } from "react";
import {
  createBlogsArticle,
  deleteBlogsArticle,
  editBlogsArticle,
  getBlogsArticles,
  getSpecificBlogsArticle,
} from "../../../api";
import { useState } from "react";
import { BlogArticle, BlogArticleStatus } from "../../../types/BlogArticle";
import "../../../components/design_library/WYSIWIGEditorStyling.css";

interface UseBlogAdminController {
  selectedArticle: any;
  setArticle: (article: BlogArticle) => void;
  articles: any[];
  categories: any[];
  sidebarIsScrolled: boolean;
  setSidebarIsScrolled: (value: boolean) => void;
  mainIsScrolled: boolean;
  setmMainIsScrolled: (value: boolean) => void;
  setSelectedArticle: (article: BlogArticle) => void;
  deleteArticle: (id: string) => void;
  saveArticle: (article: BlogArticle) => void;
  createArticle: () => void;
  loading: boolean;
  saveLoading: boolean;
  handleFileRead: (event: any) => void;
}

export const useBlogAdminController = (): UseBlogAdminController => {
  const [categories, setCategories] = React.useState([] as any[]);
  const [articles, setArticles] = React.useState([]);
  const [sidebarIsScrolled, setSidebarIsScrolled] = useState(false);
  const [mainIsScrolled, setmMainIsScrolled] = useState(false);
  const [selectedArticle, setArticle] = useState(null);
  const [loading, setLoading] = useState(false);

  const [saveLoading, setSaveLoading] = useState(false);

  const getAllArticles = async () => {
    const data = await getBlogsArticles(false, undefined, 1, 1000);
    setArticles(data.articles);

    let categories = data.metadata.categories;
    //sort Other to top
    categories = categories.filter((category) => category !== "Other");
    categories.unshift("Other");

    setCategories(categories);
  };

  const createArticle = async () => {
    const article = await createBlogsArticle({
      title: "New article",
      content: "This is a new article",
      category: "Other",
      status: BlogArticleStatus.Draft,
      image: "",
    });

    if (article) {
      getAllArticles();
      setSelectedArticle(articles[articles.length - 1]);
    }
  };

  const setSelectedArticle = async (article: BlogArticle) => {
    const downloadedArticle = await getSpecificBlogsArticle(article.articleId);
    setLoading(true);

    setArticle(downloadedArticle);
    setTimeout(() => {
      setLoading(false);
    }, 1);
  };

  const deleteArticle = async (id: string) => {
    if (
      window.confirm("Are you sure you want to delete this article?") === false
    )
      return;
    const da = await deleteBlogsArticle(id);
    await getAllArticles();
    setSelectedArticle(articles[0]);
  };

  const saveArticle = async (article: BlogArticle) => {
    setSaveLoading(true);
    if (!selectedArticle) return;
    const editedArticle = await editBlogsArticle(
      selectedArticle.articleId,
      article
    );

    setTimeout(() => {
      setSaveLoading(false);
    }, 400);

    if (editedArticle) {
      await getAllArticles();
    }
  };

  const handleFileRead = async (file: any) => {
    const base64 = await convertBase64(file);
    return base64;
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    getAllArticles();
  }, []);

  return {
    handleFileRead,
    selectedArticle,
    setArticle,
    articles,
    categories,
    sidebarIsScrolled,
    setSidebarIsScrolled,
    mainIsScrolled,
    setmMainIsScrolled,
    setSelectedArticle,
    deleteArticle,
    saveArticle,
    createArticle,
    loading,
    saveLoading,
  };
};
