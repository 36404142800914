import { Box, Flex } from "@chakra-ui/react";
import { KnowledgeBaseHelperTopics, ToolbarFeatures } from "../../types";
import { Toolbar } from "../../components/toolbar/Toolbar";
import GridLayout from "./canvas/GridLayout";
import React from "react";
import AffiliatePopup from "../affilate_marketing/AffiliatePopup";

export const Dashboard = () => {
  return (
    <Flex h="full" direction="column" pt="1px">
      <Toolbar
        enabledOptions={[
          ToolbarFeatures.FILTER_SELECTOR,
          ToolbarFeatures.UNDERLYING,
          ToolbarFeatures.HELP,
          ToolbarFeatures.PRICING_QUEUE,
          ToolbarFeatures.TAGS,
          ToolbarFeatures.INSIGHTS,
          ToolbarFeatures.POSITION_GROUPING,
          ToolbarFeatures.BOOKS,
          ToolbarFeatures.DATE_RANGE,
          ToolbarFeatures.STRATEGY,
          ToolbarFeatures.CLEAR,
          ToolbarFeatures.DASHBOARD_SELECTOR,
          ToolbarFeatures.HELP,
        ]}
        helpKey={KnowledgeBaseHelperTopics.DASHBOARD}
      />
      <GridLayout />
      <Box h="400px" w="100%"></Box>

      {/* <AffiliatePopup /> */}
    </Flex>
  );
};
