import { Box, Text } from "@chakra-ui/react";
import { FeatureDark } from "./FeatureDark";
import React from "react";

export const Features = () => {
  return (
    <Box
      as="section"
      id="features"
      mt={{ base: "0px", md: "60px", sm: "10px" }}
    >
      <Box
        mt="80px"
        pb="80px"
        justifyContent="center"
        alignItems="center"
        margin="auto"
        display={"flex"}
        flexDir={"column"}
      >
        <Text
          textAlign="center"
          letterSpacing="tight"
          fontWeight="bold"
          mb={4}
          fontSize="34px"
          color={"var(--white)"}
          mt={{ base: "0px", md: "0px", sm: "0px" }}
        >
          Platform Features
        </Text>
        <Text textAlign={"center"} opacity={0.8} maxW="700px">
          WealthBee is an trading journal, trade analytics & ledger, sometimes
          called a stock tracker or option tracker. It helps you track your net
          worth, analyze your trades, and manage your investments. We support
          stocks, options & futures.
        </Text>
        <Text opacity={0.8} maxW="700px" textAlign={"center"} pt="20px">
          WealthBee is developed by industry professionals to democratise access
          to institutional features.{" "}
        </Text>
        <Box
          pt="40px"
          display="flex"
          justifyContent="space-around"
          margin="auto"
          maxW="6xl"
          flexWrap={"wrap"}
          gap="28px"
        >
          <FeatureDark classNameCustom="blue" title="P&L Tracking">
            <Text>
              View P&L & breakeven through rolls, by underlying, strategy and
              more.
            </Text>
          </FeatureDark>

          <FeatureDark classNameCustom="yellow" title="Trade Journal">
            <Text>
              Record your trades, analyze your performance, and learn from your
              mistakes to improve your investment strategy.
            </Text>
          </FeatureDark>

          <FeatureDark classNameCustom="green" title="Near Live Pricing">
            <Text>
              Track your investments across a wide range of asset classes,
              including stocks, indexes, options and futures.
            </Text>
          </FeatureDark>

          <FeatureDark classNameCustom="plates" title="Customizable Dashboard">
            <Text>
              Create your own dashboard with the metrics that matter most to you
            </Text>
          </FeatureDark>
          <FeatureDark
            classNameCustom="filter"
            title="Automated Reporting (AI)"
          >
            <Text>
              Automatically calculate strategy assignment to imported trades
            </Text>
          </FeatureDark>
          <FeatureDark classNameCustom="pink" title="Secure & Reliable">
            <Text>
              Your financial data is protected with industry-leading security
              measures, ensuring the privacy and integrity of your information.
            </Text>
          </FeatureDark>
        </Box>
      </Box>
    </Box>
  );
};
