import { Outlet, useNavigate } from "react-router-dom";
import { useAtomValue } from "jotai";
import { Box, Center, Flex, Heading } from "@chakra-ui/react";
import "./MainApp.css";
import { AppStateAtom } from "../../store";
import { Spinner } from "../../components";
import { Navbar } from "../navbar/Navbar";
import { useInitialise } from "../../hooks";
import ErrorBoundary from "../../components/errorboundary/ErrorBoundary";
import { MaintenanceMode } from "../../components/maintenance/MaintenanceMode";
import { useEffect } from "react";
import React from "react";

export const MainApp = () => {
  const appState = useAtomValue(AppStateAtom);

  // this hook is used to initialise the app when the user is authenticated
  useInitialise();

  const navigate = useNavigate();
  if (appState.authed === false && appState.initialised === true) {
    navigate("/app/login");
  } else if (appState.authed === true && appState.user === null) {
    window.location.reload();
  }

  useEffect(() => {
    try {
      window.$crisp.push(["set", "user:email", appState.user.email]);
    } catch (e) {
      console.log(e);
    }
  }, []);

  if (appState.loading) {
    return (
      <Box>
        <Center height="100vh" flexDir="column">
          <Spinner />
          <Heading mt="2" size="md">
            Loading...
          </Heading>
        </Center>
        <Box className={"this.state.hiddenClass"}></Box>
      </Box>
    );
  } else {
    return (
      <Flex h="full" direction="column">
        <Navbar />
        <ErrorBoundary>
          <Box flex={1}>
            <Outlet />
            <MaintenanceMode />
          </Box>
        </ErrorBoundary>
      </Flex>
    );
  }
};
