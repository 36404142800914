import { Component } from "react";
import { Box, Center, Heading, Stack } from "@chakra-ui/layout";
import { Meta } from "./components";
//import NavBar from "./legacy/homepage/menu/App";

export default class FourOhFour extends Component {
  componentDidMount() {
    window.location.href = "/";
  }

  render() {
    return (
      <>
        <Meta
          title={"WealthBee - 404"}
          description={
            "404 - Page not found. WealthBee is a trading journal for everyone. We help you track your trades, analyze your performance, and manage your portfolios."
          }
          canonical={"https://wealthbee.io/404"}
        />

        {/* <NavBar></NavBar> */}
        <Box backgroundColor="#1a202c" mt="-72px" height="72px"></Box>
        <Box backgroundColor="#1a202c" height="100vh">
          <Center>
            <Stack>
              <Box>
                <Heading color="white" pt="40" textAlign="center" size="4xl">
                  404
                </Heading>
              </Box>
              <Box>
                <Heading color="white" pt="5" textAlign="center" size="lg">
                  Page cannot be found
                </Heading>
              </Box>
            </Stack>
          </Center>
        </Box>
      </>
    );
  }
}
