import { Center, VStack, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { FaRegLightbulb } from "react-icons/fa";
import InsightsManager from "../../features/dashboard/insights/InsightsManager";

export default function RenderNoInsights() {
  return (
    <Center h="70%">
      <VStack>
        <FaRegLightbulb size="50" mb="10px" />
        <Heading size="md">No Insights Enabled</Heading>
        <Text maxW="300px" textAlign="center">
          Please enable insights using the button below.
        </Text>
        <InsightsManager />
      </VStack>
    </Center>
  );
}
