import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { RadioButton, RadioButtonGroup } from "../../components/design_library";
import { useInstrumentSearchController } from "./useInstrumentSearchController";
import { Instrument, InstrumentType } from "../../types";

interface InstrumentSearchProps {
  onInstrumentSelected: (
    instrument: Instrument,
    children?: Instrument[]
  ) => void;
}

export const InstrumentSearch = (props: InstrumentSearchProps) => {
  const controller = useInstrumentSearchController(props.onInstrumentSelected);
  return (
    <Flex direction="row" w="full" py={1}>
      <Flex>
        <RadioButtonGroup
          size="sm"
          h="40px"
          value={controller.instrumentType}
          onChange={(e) => {
            controller.setInstrumentType(e as InstrumentType);
          }}
        >
          <RadioButton size="sm" key="future" value="Future" minWidth="80px">
            Future
          </RadioButton>
          <RadioButton size="sm" key="option" value="Option" minWidth="80px">
            Option
          </RadioButton>
          <RadioButton size="sm" key="equity" value="Equity" minWidth="80px">
            Equity
          </RadioButton>
          <RadioButton size="sm" key="fund" value="Fund" minWidth="80px">
            Fund
          </RadioButton>
          <RadioButton size="sm" key="forex" value="Forex" minWidth="80px">
            Forex
          </RadioButton>
        </RadioButtonGroup>
      </Flex>

      <Box ml={2} w="700px">
        <Select
          tagVariant="solid"
          options={controller.instruments.map((item) => {
            if (item.instrumentType === InstrumentType.Future) {
              return { value: item, label: item.symbol };
            }
            return { value: item, label: item.name };
          })}
          onChange={(e) => {
            controller.setSelectedInstrument(e.value);
          }}
        />
      </Box>
    </Flex>
  );
};
