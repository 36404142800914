import React, { useCallback, useEffect } from "react";
import { Box, Center, HStack, Spinner, Text, VStack } from "@chakra-ui/react";
import "./EmbedDashboard.css";
import { useSearchParams } from "react-router-dom";
import InvalidAPIKey from "./InvalidAPIKey";
import { Responsive, WidthProvider } from "react-grid-layout";
import { getEmbeddedDashboardDatasets } from "../../api/Embed";
import { InsightContainer } from "../../components/insights/InsightContainer";
import { InsightItem } from "../../types/Insight";
import PoweredByWealthBee from "./PoweredByWealthBee";
import { useAtom } from "jotai";
import { AppStateAtom } from "../../store";

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function EmbedDashboard() {
  const [searchParams] = useSearchParams();
  const dashboardId = searchParams.get("did");
  const portfolioId = searchParams.get("pid");
  const theme = searchParams.get("theme");
  const showToolbar = searchParams.get("toolbar");
  const apiKey = searchParams.get("apikey");
  const bgColor = searchParams.get("bgcolor");

  const [loading, setLoading] = React.useState(true);
  const [layouts, setLayouts] = React.useState<any>({});
  const [breakpoints] = React.useState(["lg", "md", "sm", "xs", "xxs"]);
  const [currentBreakpoint, setCurrentBreakpoint] = React.useState("lg");
  const [apiKeyValidity, setApiKeyValidity] = React.useState(false);
  const [error, setError] = React.useState("");
  const [appState, setAppState] = useAtom(AppStateAtom);

  const fetchInsightsDatasets = async (
    portfolioId: string,
    configurationId: string
  ) => {
    if (!configurationId) return;

    try {
      const response = await getEmbeddedDashboardDatasets(
        apiKey,
        configurationId,
        portfolioId
      );

      if (response.success === false) {
        setApiKeyValidity(false);
        setError(response.message);
        setLoading(false);
        return;
      }

      return response;
    } catch (error) {
      console.error("Error fetching insights datasets:", error);
    }
  };

  const generateLayoutData = (layout, datasets) => {
    const layoutData = { lg: [], md: [], sm: [], xs: [], xxs: [] };

    breakpoints.forEach((breakpoint) => {
      layoutData[breakpoint] = layout[breakpoint].map((item) => {
        const insight = datasets.find(
          (i) => parseInt(i.insightId) === parseInt(item.i)
        );
        return { ...item, data: insight };
      });
    });

    return layoutData;
  };

  const fetchLayouts = useCallback(async () => {
    setLoading(true);
    const response = await fetchInsightsDatasets(portfolioId, dashboardId);

    setLayouts(response.layout);

    const newLayoutData = generateLayoutData(response.layout, response.data);

    setLayouts(newLayoutData);
    setApiKeyValidity(true);

    setLoading(false);

    setUpIframeHeight();

    return;
  }, []);

  useEffect(() => {
    $crisp.push(["do", "chat:hide"]);

    setAppState((prevState) => {
      return {
        ...prevState,
        gridTheme: "ag-theme-tasty",
      };
    });

    fetchLayouts();
  }, []);

  const setUpIframeHeight = () => {
    window.addEventListener("message", (event) => {
      if (event.data === "getHeight") {
        const height = document.documentElement.scrollHeight;
        event.source.postMessage({ height: height }, event.origin);
      }
    });

    // Observe content size and update parent window on resize
    const observer = new ResizeObserver(() => {
      const height = document.documentElement.scrollHeight;
      window.parent.postMessage({ height: height }, "*");
    });

    observer.observe(document.body);
  };

  const setBreakpoint = useCallback((breakpoint: string) => {
    setCurrentBreakpoint(breakpoint);
  }, []);

  if (loading) {
    return (
      <Box h="100%" w="100%">
        <Center h="100vh">
          <VStack>
            <Spinner size="xl" />
            <Text mt="10px">Analyzing positions...</Text>
          </VStack>
        </Center>
      </Box>
    );
  }

  if (apiKeyValidity === false) {
    return <InvalidAPIKey />;
  }

  return (
    <Box
      bg={bgColor !== null ? bgColor : "var(--dark-grey)"}
      pl="10px"
      pt="10px"
      pb="10px"
    >
      <ResponsiveGridLayout
        useCSSTransforms={false}
        className="layout"
        style={{ width: "100%", padding: "5px", margin: "5px" }}
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        autoSize
        margin={[10, 10]}
        containerPadding={[0, 0]}
        allowOverlap={false}
        onBreakpointChange={setBreakpoint}
        compactType="vertical"
        isDraggable={false}
        isResizable={false}
      >
        {layouts[currentBreakpoint].map((item: InsightItem) =>
          item.data ? (
            <div key={item.i}>
              <InsightContainer insight={item.data} embedded={true} />
            </div>
          ) : null
        )}
      </ResponsiveGridLayout>

      <PoweredByWealthBee />
    </Box>
  );
}
