import { Box, Heading, Text } from "@chakra-ui/react";
import NavWrapper from "./NavWrapper";

export default function UploadDnDTutorial() {
  return (
    <NavWrapper previousEnabled={false} isFinalStep={false} nextDisabled={true}>
      <Box fontSize="14px" maxW="200px">
        <Heading
          as="h2"
          fontSize="18px"
          mb={4}
          fontWeight={600}
          textAlign={"left"}
        >
          Uploads
        </Heading>
        <Text mt="10px">
          Each broker comes with instructions for how to get your account set
          up. You can upload by clicking on the dashed box or draging your file
          into that box. After you've uploaded your trades you can continue to
          upload whenever you make additional trades.
        </Text>
        <Text className="rainbow">Upload a file to proceed</Text>
      </Box>
    </NavWrapper>
  );
}
