import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { FeatureDark } from "../../homepage/Features/FeatureDark";

export const EnterpriseFeatures = () => {
  return (
    <Box
      as="section"
      id="features"
      mt={{ base: "0px", md: "60px", sm: "10px" }}
    >
      <Box
        mt="80px"
        pb="80px"
        justifyContent="center"
        alignItems="center"
        margin="auto"
        display={"flex"}
        flexDir={"column"}
      >
        <Text
          textAlign="center"
          letterSpacing="tight"
          fontWeight="bold"
          mb={4}
          fontSize="34px"
          color={"var(--white)"}
          mt={{ base: "0px", md: "0px", sm: "0px" }}
        >
          Platform Features
        </Text>
        <Text textAlign={"center"} opacity={0.8} maxW="700px">
          For enterprise users WealthBee is an embeddable P&L engine with APIs
          to help you track your portfolios or your clients accounts.
        </Text>

        <Box
          pt="40px"
          display="flex"
          justifyContent="space-around"
          margin="auto"
          maxW="6xl"
          flexWrap={"wrap"}
          gap="28px"
        >
          <FeatureDark classNameCustom="blue" title="P&L Tracking">
            <Text>
              P&L is generated at an trade and position level, with the ability
              to disect data by position group, book or strategy.
            </Text>
          </FeatureDark>

          <FeatureDark classNameCustom="yellow" title="Trade Journal">
            <Text>
              Any instrument can be imported into the platform, with the ability
              to add notes and tags.
            </Text>
          </FeatureDark>

          <FeatureDark classNameCustom="green" title="Near Live Pricing">
            <Text>
              Pricing is near-real-time for stocks, options & futures. Including
              support for user pricing.
            </Text>
          </FeatureDark>

          <FeatureDark classNameCustom="plates" title="Embeddable Dashboard">
            <Text>
              A rich, dynamic dashboard that can be customised to your needs and
              embedded into your own platform.
            </Text>
          </FeatureDark>
          <FeatureDark
            classNameCustom="filter"
            title="Automated Reporting (AI)"
          >
            <Text>
              Automated reporting with AI that can be scheduled to run at any
              time.
            </Text>
          </FeatureDark>
          <FeatureDark classNameCustom="pink" title="Secure & Reliable">
            <Text>
              Financial data is protected with industry-leading security
              measures, ensuring the privacy and integrity of your information.
            </Text>
          </FeatureDark>
        </Box>
      </Box>
    </Box>
  );
};
