import React from "react";
import {
  Box,
  BoxProps,
  Center,
  HStack,
  HTMLChakraProps,
  Heading,
  Text,
} from "@chakra-ui/react";
import { BiRightArrowAlt } from "react-icons/bi";
import { PrimaryButton } from "../../../components/design_library";

export const EnterpriseCTA = () => {
  return (
    <Box
      maxW="6xl"
      w="100%"
      mb="200px"
      display={"flex"}
      justifyContent={"center"}
      flexDir={"column"}
      m="auto"
      textAlign={"center"}
      pb="60px"
      pt="0px"
    >
      <Heading>Speak to us today</Heading>
      <Text m="auto" mb="20px" mt="16px" opacity="0.8" maxW="400px">
        Get in touch with us to learn more about how WealthBee can help you
        track your portfolios or your clients accounts.
      </Text>

      <HStack justify={"center"} alignItems="center">
        <PrimaryButton
          id="cta_1"
          as="a"
          href="#"
          onClick={() => {
            $crisp.push(["do", "chat:open"]);
            $crisp.push([
              "do",
              "message:send",
              [
                "text",
                `Hi! I'd like to discuss WealthBee's enterprise offering! `,
              ],
            ]);
          }}
          color={"var(--white)"}
          border="none !important"
          bg="var(--accent-dark) !important"
          mt="20px"
          fontSize="18px !important"
          minW="300px"
          h="60px"
        >
          Speak to an expert
          <Box pl="6px">
            <BiRightArrowAlt />
          </Box>
        </PrimaryButton>
      </HStack>
    </Box>
  );
};
