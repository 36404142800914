import {
  Box,
  Heading,
  Text,
  VStack,
  SimpleGrid,
  Image,
  HStack,
} from "@chakra-ui/react";
import React from "react";
import { PrimaryButton } from "../../../components/design_library";
import { BiRightArrowAlt } from "react-icons/bi";

export default function IndustryProfessionals() {
  return (
    <VStack
      justifyContent="center"
      textAlign="center"
      spacing={8}
      mt={{
        base: "0px",
        md: "0px",
      }}
      mb="300px"
    >
      <Heading as="h2" size="xl">
        Supports all major brokers
      </Heading>
      <Text maxW={"600px"}>
        Our trading journal supports all major brokers, including Schwab,
        TastyTrade, Interactive Brokers, ETrade, Robinhood, Firstrade, Ally, and
        TD Ameritrade.
      </Text>
      <HStack
        wrap={"wrap"}
        maxW="80vw"
        justifyContent={"center"}
        alignContent={"center"}
        w="6xl"
        mt="20px"
        spacing={{
          base: "100px",
          md: "66px",
        }}
        alignItems={"center"}
      >
        <VStack minW="140px">
          <AutoSyncLabel />

          <Image
            borderRadius={4}
            src="https://cdn.wealthbee.io/main/brokers/broker_icons/etrade.png"
            alt="ETrade Trading Journal"
            boxSize="60px"
            objectFit="contain"
            m="auto"
          />
          <Text>ETrade</Text>
        </VStack>
        <VStack minW="140px">
          <AutoSyncLabel />

          <Image
            borderRadius={4}
            src="https://cdn.wealthbee.io/main/brokers/broker_icons/interactive-brokers.png"
            alt="Interactive Brokers Trading Journal"
            boxSize="60px"
            objectFit="contain"
            m="auto"
          />
          <Text>Interactive Brokers</Text>
        </VStack>
        <VStack minW="140px">
          <TradeConfirmationLabel />

          <Image
            borderRadius={4}
            src="https://cdn.wealthbee.io/main/brokers/broker_icons/schwab.png"
            alt="Charles Schwab Trading Journal"
            boxSize="60px"
            objectFit="contain"
            m="auto"
          />
          <Text>Charles Schwab</Text>
        </VStack>

        <VStack minW="140px">
          <Image
            borderRadius={4}
            src="https://cdn.wealthbee.io/main/brokers/broker_icons/tastytrade.png"
            alt="TastyTrade Trading Journal"
            boxSize="60px"
            objectFit="contain"
            m="auto"
          />
          <Text>TastyTrade</Text>
        </VStack>

        <VStack minW="140px">
          <Image
            borderRadius={4}
            src="https://cdn.wealthbee.io/main/brokers/broker_icons/fidelity.png"
            alt="Fidelity Trading Journal"
            boxSize="60px"
            objectFit="contain"
            m="auto"
          />
          <Text>Fidelity</Text>
        </VStack>

        <VStack minW="140px">
          <TradeConfirmationLabel />
          <Image
            borderRadius={4}
            src="https://cdn.wealthbee.io/main/brokers/broker_icons/td.png"
            alt="TD Ameritrade Trading Journal"
            boxSize="60px"
            objectFit="contain"
            m="auto"
          />
          <Text>TD Ameritrade</Text>
        </VStack>

        <VStack minW="140px">
          <Image
            borderRadius={4}
            src="https://cdn.wealthbee.io/main/brokers/broker_icons/robinhood.png"
            alt="Robinhood Trading Journal"
            boxSize="60px"
            objectFit="contain"
            m="auto"
          />
          <Text>Robinhood</Text>
        </VStack>
        <VStack minW="140px">
          <Image
            borderRadius={4}
            src="https://cdn.wealthbee.io/main/brokers/broker_icons/firstrade.png"
            alt="Firstrade Trading Journal"
            boxSize="60px"
            objectFit="contain"
            m="auto"
          />
          <Text>Firstrade</Text>
        </VStack>

        <VStack minW="140px">
          <Image
            borderRadius={4}
            src="https://cdn.wealthbee.io/main/brokers/broker_icons/ally.png"
            alt="Ally Trading Journal"
            boxSize="60px"
            objectFit="contain"
            m="auto"
          />
          <Text>Ally</Text>
        </VStack>
      </HStack>
    </VStack>
  );
}

const AutoSyncLabel = () => {
  return (
    <Box
      px="2"
      pos="absolute"
      transform={"translateY(500%)"}
      borderRadius={"20px"}
      bg="var(--red)"
    >
      <Text fontSize="14px" fontWeight={600}>
        AutoSync
      </Text>
    </Box>
  );
};

const TradeConfirmationLabel = () => {
  return (
    <Box
      px="2"
      pos="absolute"
      transform={"translateY(500%)"}
      borderRadius={"20px"}
      bg="var(--chart-orange)"
    >
      <Text fontSize="14px" fontWeight={500} color="var(--black)">
        Email Sync
      </Text>
    </Box>
  );
};
