import { APIEndpoint } from ".";

export const getChartData = async (
  symbol: string,
  resolution: string,
  periodParams: any,
  symbolInfo: any
) => {
  return fetch(
    APIEndpoint +
      "/datafeeds/" +
      symbol +
      `/charts?resolution=${resolution}&from=${periodParams.from}&to=${periodParams.to}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("token")}`,
      },
    }
  )
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      return { success: false, title: "Server error", message: e.message };
    });
};
