import { ColDef } from "ag-grid-community";
import {
  createGroupColumn,
  ColumnTypeEnum,
} from "../../../../../../../components";

export const upcomingExpiriesColumnDefs: ColDef[] = [
  createGroupColumn("Position Group", "positionName", ColumnTypeEnum.Text, {
    rowGroup: true,
    hide: true,
    sort: "asc",
    rowDrag: true,
    suppressColumnsToolPanel: true,
  }),

  createGroupColumn("Expiry", "expiry", ColumnTypeEnum.Date, {
    suppressColumnsToolPanel: true,
    aggFunc: "distinct",
    rowGroup: false,
    sort: "desc",
    hide: false,
  }),

  createGroupColumn("Underlying", "underlyingName", ColumnTypeEnum.Text, {
    rowGroup: true,
    hide: true,
    sort: "asc",
    rowDrag: true,
    suppressColumnsToolPanel: true,
    valueGetter: (params) => {
      if (params.data.underlyingType === "Future") {
        return params.data.underlyingName;
      } else {
        return params.data.underlyingSymbol;
      }
    },
  }),
];
