import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Trade } from "../../types";
import { useTransactions } from "../../hooks/useTransactions";
import { useAtomValue } from "jotai";
import { AccountAtom } from "../../store";
import mixpanel from "mixpanel-browser";
import StarterKit from "@tiptap/starter-kit";
import { EditorProvider } from "@tiptap/react";
import { MenuBar } from "../../components/texteditor/MenuBar";
import "./Notes.css";
import { TagEntry } from "../../features/notes/TagEntry";
import { useDebounce } from "../../features/hooks";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";

export default function NoteEditor({
  trade,
  menuBarEnabled,
  tagsEnabled,
}: {
  trade: Trade;
  menuBarEnabled?: boolean;
  tagsEnabled?: boolean;
}) {
  const useTransactionsHook = useTransactions();
  const accountState = useAtomValue(AccountAtom);

  const [notes, setNotes] = React.useState<string>("");

  const saveText = (value: string) => {
    mixpanel.track("Trade Note Added", {
      tradeId: trade.id,
      notes: value,
    });

    useTransactionsHook.modifyNote(
      value,
      [trade],
      accountState?.selectedAccount?.id
    );
  };

  const onNoteFieldChange = useDebounce(saveText, 450);

  useEffect(() => {
    setNotes(trade.notes ? trade.notes : "");
  }, [trade.notes]);

  const extensions = [
    StarterKit.configure({
      heading: {
        levels: [1, 2, 3, 4, 5, 6],
      },
    }),
    Placeholder.configure({
      placeholder: "Add you notes here...",
    }),
    Image.configure({
      inline: true,
      allowBase64: true,
    }),
    Link.configure({
      openOnClick: false,
      autolink: true,
      defaultProtocol: "https",
    }),
  ];

  return (
    <EditorProvider
      slotBefore={
        <Box mb="20px">
          {menuBarEnabled ? <MenuBar /> : null}
          {tagsEnabled ? <TagEntry trade={trade} /> : null}
        </Box>
      }
      extensions={extensions}
      content={trade.notes}
      onUpdate={(e) => {
        setNotes(e.editor.getHTML());
        onNoteFieldChange(e.editor.getHTML());
      }}
      editorProps={{
        attributes: {
          class: "prose",
        },
      }}
    ></EditorProvider>
  );
}
