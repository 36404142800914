import {
  Box,
  Heading,
  Image,
  List,
  ListItem,
  OrderedList,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Component } from "react";
import { CustomLink, Panel } from "../../../../../components/design_library";
import React from "react";

export default class ETradeAutoSyncInstructions extends Component {
  render() {
    return (
      <Panel>
        <Stack spacing="5">
          <Heading as="h3" size="lg">
            Instructions
          </Heading>

          <OrderedList>
            <ListItem>
              <Stack>
                <Text>Navigate to ETrade Developer Portal</Text>
                <CustomLink
                  color="var(--accent)"
                  href="https://developer.etrade.com/getting-started"
                >
                  ETrade Developer Portal
                </CustomLink>
              </Stack>
            </ListItem>

            <ListItem>
              <Box my={4} boxSize="full">
                <Image
                  src="https://cdn.wealthbee.io/images/users/1/eba80e39-aa7c-4992-b04d-53073b4f136f.png"
                  style={{ width: "100%" }}
                />
              </Box>
            </ListItem>

            <ListItem>
              <Text>
                Agree to the "User Intent Survey" and "API Terms of Use"
              </Text>
            </ListItem>

            <ListItem>
              <Text>
                Navigate to the ETrade portal and request a sandbox key
              </Text>
              <CustomLink
                color="var(--accent)"
                href="https://us.etrade.com/etx/ris/apikey"
              >
                ETrade API Key Generation Webpage
              </CustomLink>
            </ListItem>

            <ListItem>
              <Box my={4} boxSize="full">
                <Image
                  src="https://cdn.wealthbee.io/images/users/1/1d504137-352d-4104-80c8-464baf897d40.png
"
                  style={{ width: "100%" }}
                />
              </Box>
            </ListItem>

            <ListItem>
              <Text>
                Fill out the form, select create key for your account and then
                click generate sandbox key.{" "}
              </Text>
            </ListItem>

            <ListItem>
              <Box my={4} boxSize="full">
                <Image
                  src="https://cdn.wealthbee.io/images/users/1/76ddae8f-14a2-41ba-b841-b9a2de4eac22.png

"
                  style={{ width: "100%" }}
                />
              </Box>
            </ListItem>

            <ListItem>
              <Text>Copy the PRODUCTION Consumer Key and Consumer Secret.</Text>
            </ListItem>

            <ListItem>
              <Text>
                Above, click on Add Configuraiton and enter your PRODUCTION
                Consumer Key and Consumer Secret. Then click on Get Link Code,
                copy this code into the link code box and click test connection.
              </Text>
            </ListItem>

            <ListItem>
              <Text>
                Contact support and they will configure access to your account.
              </Text>
            </ListItem>
          </OrderedList>
        </Stack>
      </Panel>
    );
  }
}
