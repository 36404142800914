import { FilterSettings, UserFilter } from "../types";
import { APIEndpoint } from ".";

const token = localStorage.getItem("token");

export const getUserFilters = async (): Promise<UserFilter[]> => {
  const result = await fetch(`${APIEndpoint}/userfilters`, {
    method: "GET",
    headers: {
      Authorization: token || "",
    },
  });

  if (result.ok) {
    const data = await result.json();
    if (data.success && data.data) {
      return data.data.map((x: UserFilter) => {
        return {
          ...x,
          filterConfig: {
            underlyings: x.filterConfig?.underlyings ?? [],
            tags: x.filterConfig?.tags ?? [],
            positionGroups: x.filterConfig?.positionGroups ?? [],
            strategies: x.filterConfig?.strategies ?? [],
            books: x.filterConfig?.books ?? [],
          },
        } as UserFilter;
      });
    }
  }

  return [];
};

export const updateUserFilterName = async (id: number, name: string) => {
  const token = localStorage.getItem("token");
  const result = await fetch(`${APIEndpoint}/userfilters/${id}/name`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token || ""}`,
    },
    body: JSON.stringify({ name }),
  });

  if (result.ok) {
    const data = await result.json();
    if (data.success) {
      return true;
    }
  }
};

export const updateUserFilter = async (filter: UserFilter) => {
  const token = localStorage.getItem("token");
  const result = await fetch(`${APIEndpoint}/userfilters/${filter.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token || ""}`,
    },
    body: JSON.stringify({
      name: filter.name,
      filterConfig: filter.filterConfig,
    }),
  });

  if (result.ok) {
    const data = await result.json();
    if (data.success) {
      return true;
    }
  }
};

export const createUserFilter = async (
  name: string
): Promise<number | undefined> => {
  const token = localStorage.getItem("token");

  const result = await fetch(`${APIEndpoint}/userfilters`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token || ""}`,
    },
    body: JSON.stringify({
      name,
    }),
  });

  if (result.ok) {
    const data = await result.json();
    if (data.success && data.filterId) {
      return data.filterId;
    }
  }

  return undefined;
};

export const deleteUserFilter = async (id: number): Promise<boolean> => {
  const token = localStorage.getItem("token");
  const result = await fetch(`${APIEndpoint}/userfilters/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `${token || ""}`,
    },
  });

  if (result.ok) {
    const data = await result.json();
    if (data.success) {
      return true;
    }
  }

  return false;
};
