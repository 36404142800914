import {
  Avatar,
  AvatarGroup,
  Box,
  Heading,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Meta } from "../../components";
import NavBar from "../homepage/menu/App";
import Footer from "../homepage/footer/App";
import { Cookies } from "../homepage/components/Cookies";
import React from "react";

const AboutUs = () => {
  return (
    <Box bg="var(--bg)">
      <Meta
        title={"About Us"}
        description={
          "WealthBee was founded in 2021 by a team of traders and developers who were frustrated with the lack of user-friendly tools available for managing investments. We wanted to create a platform that would simplify the process of tracking trades, analyzing performance, and managing portfolios."
        }
        canonical={"https://wealthbee.io/AboutUs"}
      />
      <NavBar />
      <Box maxW="8xl" m="auto" mb="100px" pt="20px">
        <Box p={8} rounded="md" shadow="md">
          <VStack spacing={6} textAlign={"left"} alignItems={"flex-start"}>
            <HStack>
              <Heading as="h1" size="xl" textAlign="left">
                A Trading Journal for Everyone
              </Heading>

              <AvatarGroup>
                <Avatar
                  src="https://cdn.wealthbee.io/main/app/about/matt.jpeg"
                  style={{
                    filter: "grayscale(100%)",
                  }}
                />
                <Avatar
                  src="https://cdn.wealthbee.io/main/app/about/phil.jpeg"
                  style={{
                    filter: "grayscale(100%)",
                  }}
                />
              </AvatarGroup>
            </HStack>
            <Text textAlign="left">
              WealthBee was founded in 2021 by a team of traders and developers
              who were frustrated with the lack of user-friendly tools available
              for managing investments. We wanted to create a platform that
              would simplify the process of tracking trades, analyzing
              performance, and managing portfolios.
            </Text>
            <Text textAlign="left">
              We started with spreadsheets and quickly realized that they were
              not sufficient for our needs. We needed a tool that could handle
              the complexity of our trades, provide real-time data, and help us
              make informed decisions. So we set out to build our own trading
              journal – WealthBee.
            </Text>
            <Text textAlign="left">
              WealthBee is an online platform designed to empower individuals in
              managing and tracking their investments across stocks, options,
              and futures. Acting as both an investment journal and a
              comprehensive ledger, our platform enables users to monitor their
              net worth, analyze trades, and efficiently manage their
              portfolios.
            </Text>
            <Text textAlign="left">
              Our mission is to democratize access to advanced financial tools
              traditionally reserved for institutional investors. At WealthBee,
              we aim to simplify and elevate financial management, making it
              easier for everyday users to take control of their financial
              future.
            </Text>
            <Text textAlign="left">
              Our platform is designed to be user-friendly, intuitive, and
              accessible to all levels of traders. Whether you are a seasoned
              investor or just starting out, WealthBee provides the tools and
              resources you need to make informed decisions and grow your
              wealth.
            </Text>
            <Text>
              Where WealthBee really shines is through chains of complex trades
              which are very common in options trading. Opening, closing,
              rolling and still being able to understand your position is
              difficult without the right tools. Many trading journals do not
              support this level of complexity and because of this we built
              WealthBee.
            </Text>
            <Text>
              WealthBee was designed and developed in London by a global team.
              We are a group of traders, developers and designers who are
              passionate about making trading easier for everyone.
            </Text>

            <Text>
              At WealthBee, we take the security and privacy of our users
              seriously. We use industry-standard encryption to protect your
              data and ensure that your information remains confidential and
              secure. We do not share your personal information with third
              parties without your consent. We do not store passwords or any
              sensitive information in plain text.
            </Text>
          </VStack>
        </Box>
      </Box>
      <Footer />

      <Cookies />
    </Box>
  );
};

export default AboutUs;
