import { useAtom } from "jotai";
import { AppStateAtom } from "../../store";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import moment from "moment";
import { CustomDrawer } from "../../components/design_library/CustomDrawer";
import { useEffect, useRef, useState } from "react";
import { NegativeButton } from "../../components/design_library/NegativeButton";
import { useSettingsController } from "./useSettingsController";
import Themes from "../../components/themes/Themes";
import mixpanel from "mixpanel-browser";
import { AutoSync } from "../AutoSync/AutoSync";
import React from "react";
import ManageAPIKeys from "../../components/apikeys/ManageAPIKeys";

const Settings = () => {
  const controller = useSettingsController();
  const [appState] = useAtom(AppStateAtom);
  const [showPassword, setShowPassword] = useState(false);
  const [logOutModalOpen, setLogOutModalOpen] = useState(false);
  const cancelRef = useRef<any>();

  return (
    <Box>
      <VStack spacing="5" mt="5">
        <Box
          width="xl"
          maxW="100vw"
          rounded={{ md: "lg" }}
          bg={"var(--dark-gray)"}
          shadow="base"
          overflow="hidden"
          padding="5"
        >
          <Heading as="h3" size="lg">
            Settings
          </Heading>
          <Text fontSize="sm">Your email is {appState.user.email}</Text>

          <HStack mt="5" alignItems={"flex-start"} wrap={"wrap"}>
            <Button onClick={() => controller.setDrawerOpen(true)}>
              Change password
            </Button>

            <Button
              onClick={() => {
                mixpanel.track("Logout Attempt");
                setLogOutModalOpen(true);
              }}
            >
              Logout
            </Button>

            <Button
              isLoading={controller.loading}
              onClick={() => controller.requestStripePortal()}
            >
              Manage subscription{" "}
            </Button>
          </HStack>
        </Box>

        <Themes />
        <CustomDrawer
          size="sm"
          isOpen={controller.drawerOpen}
          onClose={() => {
            controller.setDrawerOpen(false);
            controller.setPassword("");

            setShowPassword(false);
          }}
          body={
            <Stack>
              <Box>
                <InputGroup mt="20px" size="md">
                  <Input
                    pr="4.5rem"
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter password"
                    autoComplete="password"
                    size="md"
                    disabled={controller.loading}
                    onChange={(e) => {
                      controller.setPassword(e.target.value);
                    }}
                    isRequired={true}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>

                <HStack mt="20px" justifyContent={"flex-end"}>
                  <Button
                    colorScheme="blue"
                    mr="5"
                    isLoading={controller.loading}
                    onClick={() => {
                      controller.changePassword();
                    }}
                  >
                    Save
                  </Button>

                  <NegativeButton
                    onClick={() => {
                      controller.setPassword("");
                      setShowPassword(false);
                      controller.setDrawerOpen(false);
                    }}
                  >
                    Close
                  </NegativeButton>
                </HStack>
              </Box>
              );
            </Stack>
          }
        />

        <Box
          width="xl"
          maxW="100vw"
          rounded={{ md: "lg" }}
          bg={"var(--dark-gray)"}
          shadow="base"
          overflow="hidden"
          padding="5"
        >
          <Heading as="h3" size="lg">
            Tutorial
          </Heading>
          <Button
            mt="10px"
            w="100%"
            onClick={() => {
              window.location.href = "../app/positions?tutorial=true";
            }}
          >
            Replay tutorial again
          </Button>
        </Box>

        <Box
          width="xl"
          maxW="100vw"
          rounded={{ md: "lg" }}
          bg={"var(--dark-gray)"}
          shadow="base"
          overflow="hidden"
          padding="5"
        >
          <AutoSync />
        </Box>

        <ManageAPIKeys />
      </VStack>

      <AlertDialog
        isOpen={logOutModalOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setLogOutModalOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent bg="var(--bg)">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Logout
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure you want to logout?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setLogOutModalOpen(false)}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  window.localStorage.removeItem("token");
                  window.location.href = "../app/login";
                }}
                ml={3}
              >
                Logout
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default Settings;
