import { useAtomValue } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AccountAtom, TransactionsAtom } from "../../store";
import { Instrument, Trade, TradeEx } from "../../types";
import { GetRowIdFunc, GetRowIdParams } from "ag-grid-community";
import { useTransactions } from "../../hooks/useTransactions";
import {
  getAllChildrenOfGroup,
  getAllChildrenOfOverNode,
} from "../../components";
import {
  overlayLoadingTemplate,
  overlayNoRowsTemplate,
} from "../../components/grid/NoRowsToShow";
import mixpanel from "mixpanel-browser";
import { useInstruments } from "../../hooks";
import { FilterStateAtom } from "../../store/FilterStateAtom";

interface UseTransactionController {
  selectedUnderlying?: string;
  setSelectedUnderlying: (underlying: string | undefined) => void;
  trades: TradeEx[];

  onRowDragMove: (params: GetRowIdParams) => void;
  onRowDragEnd: (params: GetRowIdParams) => void;
  getRowId: GetRowIdFunc;

  loadingOverlayComponent: any;
  loadingOverlayComponentParams: any;
  noRowsOverlayComponent: any;
  noRowsOverlayComponentParams: any;

  tradesWithNoBook: number;
  showUnBookedTrades: boolean;
  setShowUnBookedTrades: (showUnBookedTrades: boolean) => void;

  tradesWithNoPositionGrouping: number;
  showNoPositionGrouping: boolean;

  setShowNoPositionGrouping: (showNoPositionGrouping: boolean) => void;
}

export const useTransactionController = (): UseTransactionController => {
  const filters = useAtomValue(FilterStateAtom);
  const [showUnBookedTrades, setShowUnBookedTrades] = useState(false);
  const [showNoPositionGrouping, setShowNoPositionGrouping] = useState(false);

  const useTransactionsHook = useTransactions();
  const instruments = useInstruments();
  const accountState = useAtomValue(AccountAtom);
  const transactions = useAtomValue(TransactionsAtom);

  const filteredTrades = useMemo(() => {
    let result = filters.selectedUnderlying
      ? transactions.trades.filter((trade) => {
          const underlyingInstrument = instruments.instruments.find(
            (x) =>
              x.id === trade.underlyingInstrumentId ||
              x.id === trade.instrumentId
          );

          if (underlyingInstrument?.name === filters.selectedUnderlying) {
            return true;
          } else {
            return false;
          }
        })
      : transactions.trades;

    if (filters.selectedTag && filters.selectedTag !== "All") {
      result = result.filter((trade) => {
        return trade?.tags?.includes(filters.selectedTag);
      });
    }

    if (showUnBookedTrades) {
      result = result.filter((trade) => !trade.book);
    }
    if (showNoPositionGrouping) {
      result = result.filter(
        (trade) =>
          !trade.positionGroup ||
          trade.positionGroup.toLowerCase() === "ungrouped"
      );
    }

    if (
      filters.selectedPositionGroup &&
      filters.selectedPositionGroup !== "All"
    ) {
      result = result.filter(
        (trade) =>
          trade.positionGroup?.toLowerCase() ===
          filters.selectedPositionGroup.toLowerCase()
      );
    }

    if (filters.selectedStrategy && filters.selectedStrategy !== "All") {
      result = result.filter(
        (trade) => trade.strategy === filters.selectedStrategy
      );
    }

    if (filters.selectedBook && filters.selectedBook !== "All") {
      result = result.filter(
        (trade) =>
          trade.book?.toLowerCase() === filters.selectedBook.toLowerCase()
      );
    }

    return result.map((trade) => {
      return {
        ...trade,
        instrument: trade.instrumentId
          ? instruments.instruments.find((x) => x.id === trade.instrumentId)
          : undefined,
        underlyingInstrument: trade.underlyingInstrumentId
          ? instruments.instruments.find(
              (x) => x.id === trade.underlyingInstrumentId
            )
          : instruments.instruments.find((x) => x.id === trade.instrumentId),
      } as TradeEx;
    });
  }, [
    filters.selectedUnderlying,
    transactions.trades,
    showNoPositionGrouping,
    showUnBookedTrades,
    filters.selectedTag,
    filters.selectedStrategy,
  ]);

  const tradesWithNoBook = useMemo(() => {
    return filteredTrades.filter((trade) => !trade.book).length;
  }, [filteredTrades]);

  const tradesWithNoPositionGrouping = useMemo(() => {
    return filteredTrades.filter(
      (trade) =>
        !trade.positionGroup ||
        trade.positionGroup.toLowerCase() === "ungrouped"
    ).length;
  }, [filteredTrades]);

  const onRowDragMove = (params: GetRowIdParams) => {
    // console.log("onRowDragEnd", params);
  };

  const getRowId = useCallback(function (params: GetRowIdParams) {
    return params.data.id;
  }, []);

  useEffect(() => {
    useTransactionsHook.getTradesAndCashflows(
      accountState?.selectedAccount?.id
    );
  }, []);

  const onRowDragEnd = (params: GetRowIdParams) => {
    mixpanel.track("Transaction Dragged");
    const nodesData = getAllChildrenOfGroup(params, transactions);
    const overNodesData = getAllChildrenOfOverNode(params, transactions);

    if (params.overNode.group === false) {
      useTransactionsHook.updateBookAndGroup(
        [...nodesData],
        params.overNode.data.book,
        params.overNode.data.positionGroup,
        accountState?.selectedAccount?.id
      );
    } else if (
      params.overNode.field === "positionGroup" ||
      params.overNode.field === "book"
    ) {
      useTransactionsHook.updateBookAndGroup(
        [...nodesData],
        overNodesData[0].book,
        overNodesData[0].positionName ||
          overNodesData[0].positionGroup ||
          params.overNode.aggData.positionName ||
          params.overNode.aggData.positionGroup,
        accountState?.selectedAccount?.id
      );
    }
    return;
  };

  const loadingOverlayComponent = useMemo(() => {
    return overlayLoadingTemplate;
  }, []);

  const loadingOverlayComponentParams = useMemo(() => {
    return {
      loadingMessage: "Retrieving transactions...",
    };
  }, []);
  const noRowsOverlayComponent = useMemo(() => {
    return overlayNoRowsTemplate;
  }, []);
  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      noRowsMessageFunc: () =>
        "No transactions found, please add them using the 'Add assets' button ",
    };
  }, []);

  return {
    trades: filteredTrades,
    onRowDragMove,
    onRowDragEnd,
    getRowId,
    loadingOverlayComponent,
    loadingOverlayComponentParams,
    noRowsOverlayComponent,
    noRowsOverlayComponentParams,
    tradesWithNoBook,
    showUnBookedTrades,
    setShowUnBookedTrades,
    tradesWithNoPositionGrouping,
    showNoPositionGrouping,
    setShowNoPositionGrouping,
  };
};
