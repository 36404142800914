import { useEffect, useState } from "react";
import { getUserInstrumentPrices, saveUserInstrumentPrice } from "../../api";
import { useAtomValue } from "jotai";
import { AccountAtom } from "../../store";
import { UserInstrumentPrice } from "../../types";

interface UsePricesControllerResponse {
  prices: UserInstrumentPrice[];
  underlyings: string[];
  selectedUnderlying?: string;
  selectUnderlying: (underlying: string | undefined) => void;
  saveUserPrice: (
    instrumentId: number,
    price?: number,
    expiry?: string
  ) => void;
}
export const usePricesController = (): UsePricesControllerResponse => {
  const [prices, setPrices] = useState<UserInstrumentPrice[]>([]);
  const [underlyings, setUnderlyings] = useState<string[]>([]);
  const [selectedUnderlying, setSelectedUnderlying] = useState<
    string | undefined
  >();

  const account = useAtomValue(AccountAtom);

  const selectUnderlying = (underlying: string | undefined) => {
    setSelectedUnderlying(underlying);
  };

  const loadPrices = async () => {
    const result = await getUserInstrumentPrices(account.selectedAccount.id);
    if (result !== undefined) {
      setPrices(result);
      const underlyingSymbols = result
        .map((x) => x.underlyingSymbol)
        .filter((value, index, self) => self.indexOf(value) === index);

      setUnderlyings([
        "All",
        ...underlyingSymbols.sort((a, b) => a.localeCompare(b)),
      ]);
    }
  };

  const saveUserPrice = async (
    instrumentId: number,
    price?: number,
    expiry?: string
  ) => {
    try {
      await saveUserInstrumentPrice(
        account.selectedAccount.id,
        instrumentId,
        price,
        expiry
      );
      // update user prices to reflect changes
      setPrices((prev) => {
        const newPrices = [...prev];
        const index = newPrices.findIndex(
          (x) => x.instrumentId === instrumentId
        );
        newPrices[index].userPrice = price;
        newPrices[index].userExpiry = expiry;
        return newPrices;
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (account.selectedAccount.id !== undefined) {
      loadPrices();
    }
  }, [account]);

  return {
    prices: selectedUnderlying
      ? prices.filter((x) => x.underlyingSymbol === selectedUnderlying)
      : prices,
    underlyings,
    selectedUnderlying,
    selectUnderlying,
    saveUserPrice,
  };
};
