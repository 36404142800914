import React from "react";
import { Spinner } from "../misc";
import { VStack } from "@chakra-ui/react";

export default function RenderLoading() {
  return (
    <VStack h="80%" justifyContent="center">
      <Spinner w="80px" h="80px" />
    </VStack>
  );
}
