import React from "react";
import FormBase from "../../../../components/forms/Form";
import { Box } from "@chakra-ui/react";
import { useAddOptionFormController } from "./useAddOptionFormController";

export default function AddOptionForm(props: any) {
  const controller = useAddOptionFormController(props);

  return (
    <Box>
      {props.goBackButton}
      <FormBase
        isLoading={controller.isLoading}
        title="Add Option"
        subtitle="Use this form to add an option trade to your portfolio."
        onSubmit={controller.submitPayload}
        submitText="Create trade"
        schema={controller.schema}
      />
    </Box>
  );
}
