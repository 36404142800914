import { SyncSetting } from "../types";
import { APIEndpoint } from ".";

const token = localStorage.getItem("token");

export const getSyncSettings = async (
  accountId: number
): Promise<SyncSetting[]> => {
  const result = await fetch(`${APIEndpoint}/sync`, {
    method: "GET",
    headers: {
      Authorization: token || "",
      AccountId: accountId as any,
    },
  });

  if (result.ok) {
    const data = await result.json();
    if (data.success && data.data) {
      return data.data as SyncSetting[];
    }
  }

  return [];
};

export const addOrUpdateSyncSetting = async (
  settings: SyncSetting,
  accountId: number
) => {
  return fetch(`${APIEndpoint}/sync`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
      AccountId: accountId as any,
    },
    body: JSON.stringify(settings),
  })
    .then((response) => response.json())
    .catch((error) =>
      console.error("Error updating autosync settings:", error)
    );
};

export const testSyncSetting = async (
  settings: SyncSetting,
  accountId: number,
  stage?: string
): Promise<boolean> => {
  const result = await fetch(`${APIEndpoint}/sync/test?stage=${stage}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
      AccountId: accountId as any,
    },
    body: JSON.stringify(settings),
  });

  if (result.ok) {
    const data = await result.json();
    if (data.success && data.data) {
      return data.data as boolean;
    }
  }

  return false;
};

export const runSyncSetting = async (
  settings: SyncSetting,
  accountId: number
): Promise<boolean> => {
  const result = await fetch(`${APIEndpoint}/sync/run`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
      AccountId: accountId as any,
    },
    body: JSON.stringify(settings),
  });

  if (result.ok) {
    const data = await result.json();
    if (data.success && data.data) {
      return data.data as boolean;
    }
  }

  return false;
};
