import { Box, HStack, Tag, Text, Tooltip, VStack } from "@chakra-ui/react";
import currencyFormatter from "currency-formatter";
import {
  BiWifi,
  BiWifi0,
  BiWifi1,
  BiWifi2,
  BiWifiOff,
  BiUser,
} from "react-icons/bi";
import { MdOnlinePrediction } from "react-icons/md";
import { CustomTag, CustomToolTip } from "../../design_library";
import { calculateDaysUntilExpiry } from "../utils";
import { DownloadIcon } from "@chakra-ui/icons";
import { Spinner as ChakraSpinner, Center } from "@chakra-ui/react";
import React from "react";

export const PriceDetailRender = (params: any) => {
  if (!params?.node?.data) return null;
  const { price, priceDate, priceConfidence } =
    params?.node?.data?.priceData || {};
  const { currency } =
    params?.node?.data?.instrumentId || params?.node?.data?.instrument || {};

  if (params.node.data.quantity === 0) {
    return (
      <VStack
        h="100%"
        alignItems={"flex-start"}
        justifyContent={"center"}
        w="100%"
      >
        <CustomTag h="20px" backgroundColor={"var(--trade-closed)"}>
          Closed
        </CustomTag>
      </VStack>
    );
  }

  //Expired trade.

  if (
    calculateDaysUntilExpiry(
      params?.node?.data?.instrumentId || params?.node?.data?.instrument
    ) === 0
  ) {
    return (
      <VStack
        h="100%"
        alignItems={"flex-start"}
        justifyContent={"center"}
        w="100%"
      >
        <CustomTag h="20px" backgroundColor={"var(--trade-expired)"}>
          Expired
        </CustomTag>
      </VStack>
    );
  }

  if (!price && price !== 0) {
    return (
      <VStack
        h="100%"
        alignItems={"flex-start"}
        justifyContent={"center"}
        w="100%"
      >
        <Text fontSize="12px">Waiting for update...</Text>
      </VStack>
    );
  }

  return (
    <HStack bg="transparent">
      <Indicator priceConfidence={priceConfidence} />
      {priceConfidence !== "Unknown" && (
        <Text>{currencyFormatter.format(price, { code: currency })}</Text>
      )}
    </HStack>
  );
};

const Indicator = ({ priceConfidence }) => {
  const indicators = {
    Theoretical: {
      label:
        "Theoretical Price - Price is calculated by us based on black scholes model.",
      icon: <MdOnlinePrediction color="var(--green)" />,
    },
    Delayed: {
      label: "Pricing is delayed, this can be from 15 mins and longer.",
      icon: <BiWifi2 color="var(--accent)" />,
    },
    EOD: {
      label: "End of Day data - Priced from last close.",
      icon: <BiWifi2 color="var(--accent)" />,
    },
    Live: {
      label: "Live data is less than 1 minute old",
      icon: <BiWifi color="var(--green)" />,
    },
    User: {
      label: "User Price - Price is set by user.",
      icon: <BiUser color="var(--green)" />,
    },
    Unknown: {
      label: "Data is not available",
      icon: <BiWifiOff color="var(--red)" />,
    },
    default: {
      label: "...",
      icon: <BiWifiOff color="var(--blue)" />,
    },
  };

  const indicator = indicators[priceConfidence] || indicators["default"];
  return <IndicatorText label={indicator.label} icon={indicator.icon} />;
};

const IndicatorText = ({ label, icon }) => {
  return (
    <Tooltip label={label}>
      <Text fontSize="30px">{icon}</Text>
    </Tooltip>
  );
};
