import APIEndpoint from "./APIEndpoint";
const token = localStorage.getItem("token");

export const GetCalendarNotes = async (month: string, accountId: number) => {
  return fetch(`${APIEndpoint}/calendar?month=${month}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
      AccountId: accountId as any,
    },
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error getting calendar notes:", error));
};

export const CreateCalendarNote = async (
  date: string,
  content: string,
  accountId: number
) => {
  return fetch(`${APIEndpoint}/calendar`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
      AccountId: accountId as any,
    },
    body: JSON.stringify({
      date,
      content,
    }),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error creating calendar note:", error));
};

export const UpdateCalendarNote = async (note: any, accountId: number) => {
  return fetch(`${APIEndpoint}/calendar/${note.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
      AccountId: accountId as any,
    },
    body: JSON.stringify(note),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error updating calendar note:", error));
};

export const DeleteCalendarNote = async (date: Date, accountId: number) => {
  return fetch(`${APIEndpoint}/calendar`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
      AccountId: accountId as any,
    },
    body: JSON.stringify({ date }),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error deleting calendar note:", error));
};
