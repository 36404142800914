import { UserInstrumentPrice } from "../types";
import { APIEndpoint } from ".";
import moment from "moment";

const token = localStorage.getItem("token");

export const getUserInstrumentPrices = async (
  accountId: number
): Promise<UserInstrumentPrice[]> => {
  const result = await fetch(`${APIEndpoint}/prices`, {
    method: "GET",
    headers: {
      Authorization: token || "",
      AccountId: accountId as any,
    },
  });

  if (result.ok) {
    const data = await result.json();
    if (data.success && data.data) {
      return data.data.map((x: UserInstrumentPrice) => {
        return {
          ...x,
          expiry: x.expiry ? moment(x.expiry).toDate() : undefined,
          userExpiry: x.userExpiry ? moment(x.userExpiry).toDate() : undefined,
        } as UserInstrumentPrice;
      });
    }
  }

  return [];
};

export const saveUserInstrumentPrice = async (
  accountId: number,
  instrumentId: number,
  price?: number,
  expiry?: string
) => {
  const result = await fetch(`${APIEndpoint}/prices`, {
    method: "POST",
    headers: {
      Authorization: token || "",
      AccountId: accountId as any,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      instrumentId: instrumentId,
      price: price,
      expiry: expiry,
    }),
  });
};
