import React from "react";
import {
  Grid,
  Axis,
  BarStack,
  BarSeries,
  LineSeries,
  AreaSeries,
  XYChart,
  Tooltip,
} from "@visx/xychart";
import { LegendOrdinal } from "@visx/legend";
import { scaleOrdinal } from "@vx/scale";
import { curveCardinal } from "@visx/curve";
import { ChartProps } from "./Chart";
import { NewTooltip } from "../Shared/Tooltip";
import { DataItem } from "../../../../../../types/Insight";
import { Text, VStack } from "@chakra-ui/react";
import moment from "moment";

interface ChartStructurePropsEx extends ChartProps {
  width: number;
  height: number;
  onLegendClick: (key: string) => void;
}

const ChartStructure = (props: ChartStructurePropsEx) => {
  const { width, height, data, chartProperties, onLegendClick } = props;

  const accessors = {
    xAccessor: (d: DataItem, key: string) => {
      if (chartProperties.flipAxis === true) {
        return d[key] as number;
      }
      return d.domain;
    },
    yAccessor: (d: DataItem, key: string) => {
      if (chartProperties.flipAxis === true) {
        return d.domain;
      }
      return d[key] as number;
    },
  };

  const margin = { top: 20, left: 75, right: 20, bottom: 80 };

  const domainValues = data.map((d) => d.domain as string);

  const valueAccessor =
    chartProperties.flipAxis === true
      ? accessors.xAccessor
      : accessors.yAccessor;

  const legendScale = scaleOrdinal<string, string>({
    domain: chartProperties.legend,
    range: chartProperties.colors,
  });

  const background = "transparent";

  const numTicks = 6;
  // TODO: colour zero line
  //   const zeroY = yScale(0) + margin.top; // Calculate the y position for zero

  return (
    <>
      <div
        style={{
          position: "absolute",
          width: "100%",
          bottom: "20px",
          display: "flex",
          justifyContent: "center",
          fontSize: "12px",
        }}
      >
        {/* <LegendOrdinal
          scale={legendScale}
          direction="row"
          labelMargin="0 5px 0 0"
        /> */}
      </div>

      <div
        style={{
          position: "absolute",
          top: "5px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          fontSize: "12px",
        }}
      >
        <VStack align="stretch" spacing={1}>
          <Text size="md" textAlign="center" pb="8px" fontSize={"12px"}>
            {chartProperties.name}
          </Text>
        </VStack>
      </div>

      <XYChart
        xScale={
          chartProperties.flipAxis === true
            ? {
                type: "linear",
              }
            : { type: "band", padding: 0.2 }
        }
        yScale={
          chartProperties.flipAxis === true
            ? { type: "band", padding: 0.2 }
            : {
                type: "linear",
              }
        }
        width={width - 40}
        height={height}
        margin={margin}
      >
        <rect width={width} height={height} fill={background} rx={14} />

        <Grid
          rows={true}
          columns={false}
          numTicks={numTicks}
          // animationTrajectory="center"
          lineStyle={{ stroke: "#717186", strokeWidth: 1, opacity: 0.3 }}
        />

        {/* Domain Axis */}
        <Axis
          orientation={chartProperties.flipAxis === true ? "left" : "bottom"}
          tickFormat={(value) => {
            if (
              chartProperties.flipAxis !== true &&
              chartProperties.domainType === "date"
            ) {
              return moment(value, "YYYY-MM-DD").format("Do MMM YY");
            }

            return value;
          }}
          tickValues={
            chartProperties.flipAxis === true &&
            chartProperties.showEveryDomainLabel
              ? domainValues
              : undefined
          }
          // animationTrajectory="center"
          stroke="#717186"
          tickStroke="#717186"
          tickLabelProps={() => ({
            stroke: "#717186",
          })}
        />

        <Axis
          orientation={chartProperties.flipAxis === true ? "bottom" : "left"}
          tickFormat={(value) => {
            if (
              chartProperties.flipAxis === true &&
              chartProperties.domainType === "date"
            ) {
              return moment(value, "YYYY-MM-DD").format("Do MMM YY");
            }

            return value;
          }}
          tickValues={
            chartProperties.flipAxis !== true &&
            chartProperties.showEveryDomainLabel
              ? domainValues
              : undefined
          }
          // animationTrajectory="center"
          stroke="#717186"
          numTicks={chartProperties.flipAxis !== true ? numTicks : undefined}
          tickStroke="#717186"
          tickLabelProps={() => ({
            stroke: "#717186",
          })}
        />

        {chartProperties.keys?.map((key, i) => {
          const name = chartProperties.name?.replaceAll(" ", "-");

          switch (chartProperties.chartType) {
            case "line":
              return (
                <LineSeries
                  key={`1-${key}-${
                    chartProperties.flipAxis === true
                      ? "horizontal"
                      : "vertical"
                  }-${name}`}
                  dataKey={key}
                  data={data}
                  xAccessor={(d) => accessors.xAccessor(d, key)}
                  yAccessor={(d) => accessors.yAccessor(d, key)}
                  curve={curveCardinal}
                />
              );
            case "area":
              return (
                <>
                  <AreaSeries
                    key={`2-${key}-${
                      chartProperties.flipAxis === true
                        ? "horizontal"
                        : "vertical"
                    }`}
                    dataKey={key}
                    data={data}
                    xAccessor={(d) => accessors.xAccessor(d, key)}
                    yAccessor={(d) => accessors.yAccessor(d, key)}
                    curve={curveCardinal}
                    fillOpacity={1.0}
                    fill="var(--chart-blue)"
                  />

                  <LineSeries
                    dataKey="zero"
                    data={[
                      { domain: domainValues[0], value: 0 },
                      {
                        domain: domainValues[domainValues.length - 1],
                        value: 0,
                      },
                    ]}
                    xAccessor={(d) => d.domain}
                    yAccessor={() => 0}
                    stroke="var(--red)"
                    strokeWidth={1}
                  />
                </>
              );
            case "bar":
              return (
                <>
                  <BarStack>
                    {chartProperties.keys?.map((key, i) => {
                      const keyIdx = chartProperties.keys?.indexOf(key);
                      const fillColour =
                        chartProperties.colors[keyIdx ?? 0] ?? "black";
                      return (
                        <BarSeries
                          key={`3-${key}-${
                            chartProperties.flipAxis === true
                              ? "horizontal"
                              : "vertical"
                          }-${name}`}
                          dataKey={key}
                          data={data}
                          xAccessor={(d) => accessors.xAccessor(d, key)}
                          yAccessor={(d) => {
                            return accessors.yAccessor(d, key);
                          }}
                          barPadding={0.5}
                          // colorAccessor={() => fillColour}

                          // TODO - FIX THIS TO DERIVE IT BACK TO THE ORIGINAL COLOUR
                          colorAccessor={(d) => {
                            if (
                              typeof accessors.xAccessor(d, key) !== "number"
                            ) {
                              const value = accessors.yAccessor(d, key);

                              return value >= 0 ? "var(--green)" : "var(--red)";
                            } else {
                              const value = accessors.xAccessor(d, key);
                              if (key === "unrealisedPnl") {
                                return value >= 0
                                  ? "var(--unrealised-gain)"
                                  : "var(--unrealised-loss)";
                              } else if (key === "realisedPnl") {
                                return value >= 0
                                  ? "var(--green)"
                                  : "var(--red)";
                              } else {
                                return fillColour;
                              }
                            }
                          }}
                        />
                      );
                    })}
                  </BarStack>
                  <LineSeries
                    dataKey="zero"
                    data={[
                      { domain: domainValues[0], value: 0 },
                      {
                        domain: domainValues[domainValues.length - 1],
                        value: 0,
                      },
                    ]}
                    xAccessor={(d) => d.domain}
                    yAccessor={() => 0}
                    stroke="var(--red)"
                    strokeWidth={1}
                  />
                </>
              );
          }
          return <></>;
        })}

        <Tooltip
          snapTooltipToDatumX
          snapTooltipToDatumY
          showHorizontalCrosshair
          showVerticalCrosshair
          showDatumGlyph
          renderTooltip={({ tooltipData, colorScale }) => {
            const x = accessors.xAccessor(
              tooltipData.nearestDatum?.datum,
              tooltipData.nearestDatum?.key as string
            );
            const y = accessors.yAccessor(
              tooltipData.nearestDatum?.datum,
              tooltipData.nearestDatum?.key as string
            );

            const keyIdx = chartProperties.keys?.indexOf(
              tooltipData.nearestDatum?.key
            );
            if (keyIdx === undefined) return <></>;

            const value = chartProperties.flipAxis === true ? x : y;
            const label = chartProperties.flipAxis === true ? y : x;
            return (
              <NewTooltip
                domain={chartProperties.legend[keyIdx] ?? ""}
                value={value as number}
                label={
                  chartProperties.domainType === "date"
                    ? moment(label, "YYYY-MM-DD").format("Do MMM YY")
                    : label
                }
              />
            );
          }}
        />
      </XYChart>
    </>
  );
};

export default ChartStructure;
