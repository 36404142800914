export const features = [
  {
    type: "Epic",
    title: "Analytical Dashboards",
    description:
      "Allow users to create custom dashboards, with insights and charts.",
    status: "Completed",
  },
  {
    type: "Epic",
    title: "Insight marketplace",
    description:
      "Allow users to share their insights and charts with other users.",
    status: "In Progress",
  },
  {
    type: "Epic",
    title: "Insight Query Language",
    description:
      "Allow users to query their data using a custom query language.",
    status: "To Do",
  },
  {
    type: "Epic",
    title: "Data update frequency indicator",
    description: "Provide indicators showing the frequency of data updates.",
    status: "Completed",
  },
  {
    type: "Epic",
    title: "Additional data feeds (outside US)",
    description:
      "Incorporate additional data feeds from international sources.",

    status: "To Do",
  },
  {
    type: "Epic",
    title: "Crypto",
    description: "Integrate cryptocurrency data and insights.",
    status: "To Do",
  },
  {
    type: "Epic",
    title: "Real Estate",
    description: "Include real estate data and insights.",
    status: "To Do",
  },
  {
    type: "Epic",
    title: "Real Assets",
    description: "Add data and insights on real assets.",
    status: "To Do",
  },
  {
    type: "Epic",
    title: "Fixed Income",
    description: "Provide fixed income data and insights.",
    status: "To Do",
  },
  {
    type: "Epic",
    title: "Cash",
    description: "Incorporate cash data and insights.",
    status: "To Do",
  },
  {
    type: "Epic",
    title: "Interactive Brokers data import support",
    description:
      "Allow users to use WealthBee to track their Interactive Brokers trades and investments to see analytics.",
    status: "Completed",
  },
  {
    type: "Epic",
    title: "Schwab data import support",
    description:
      "Allow users to use WealthBee to track their Schwab trades and investments to see analytics.",
    status: "Completed",
  },
  {
    type: "Epic",
    title: "Fidelity data import support",
    description:
      "Allow users to use WealthBee to track their Fidelity trades and investments to see analytics.",
    status: "Completed",
  },
  {
    type: "Epic",
    title: "TastyTrade data import support",
    description:
      "Allow users to use WealthBee to track their TastyTrade trades and investments to see analytics.",
    status: "Completed",
  },
  {
    type: "Epic",
    title: "TD Ameritrade data import support",
    description:
      "Allow users to use WealthBee to track their TD Ameritrade trades and investments to see analytics.",
    status: "Completed",
  },
  {
    type: "Epic",
    title: "E*TRADE data import support",
    description:
      "Allow users to use WealthBee to track their E*TRADE trades and investments to see analytics.",
    status: "Completed",
  },
  {
    type: "Epic",
    title: "Robinhood data import support",
    description:
      "Allow users to use WealthBee to track their Robinhood trades and investments to see analytics.",
    status: "Completed",
  },
  {
    type: "Epic",
    title: "Firstrade data import support",
    description:
      "Allow users to use WealthBee to track their Firstrade trades and investments to see analytics.",
    status: "Completed",
  },
  {
    type: "Epic",
    title: "Merrill Edge data import support",
    description:
      "Allow users to use WealthBee to track their Merrill Edge trades and investments to see analytics.",
    status: "To Do",
  },
  {
    type: "Epic",
    title: "Vanguard data import support",
    description:
      "Allow users to use WealthBee to track their Vanguard trades and investments to see analytics.",
    status: "To Do",
  },
  {
    type: "Epic",
    title: "Ally data import support",
    description:
      "Allow users to use WealthBee to track their Ally trades and investments to see analytics.",
    status: "Comleted",
  },
];
