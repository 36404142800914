import React, { useCallback, useMemo, useRef } from "react";
import {
  Divider,
  Flex,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  Text,
  Box,
  Button,
  Input,
  Spacer,
  VStack,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogBody,
} from "@chakra-ui/react";
import { FaChevronDown, FaPlus } from "react-icons/fa";
import { useDashboardSelectorController } from "./useDashboardSelectorController";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { Show } from "../../components";
import { _deleteLayout } from "../../api";
import { DashboardConfiguration } from "../../types/DashboardConfiguration";

export const DashboardSelector = () => {
  const renameDashboardInput = useRef<HTMLInputElement>(null);
  const newDashboardInput = useRef<HTMLInputElement>(null);
  const [showAlert, setShowAlert] = React.useState(false);
  const cancelRef = React.useRef<any>();

  const [editRow, setEditRow] = React.useState<number | undefined>(undefined);
  const [deleteDashboard, setDeleteDashboardRow] = React.useState<
    DashboardConfiguration | undefined
  >(undefined);

  const controller = useDashboardSelectorController();

  const dashboards = useMemo(() => {
    return controller.configurations.map((item) => {
      return { value: item, label: item.name };
    });
  }, [controller.configurations]);

  const doDelete = async () => {
    if (deleteDashboard?.configurationId) {
      await controller.deleteDashboard(deleteDashboard.configurationId);
    }
    setDeleteDashboardRow(undefined);
    setShowAlert(false);
  };

  const confirmDelete = useCallback(() => {
    return (
      <AlertDialog
        isOpen={showAlert}
        leastDestructiveRef={cancelRef}
        onClose={() => setShowAlert(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Dashboard ({deleteDashboard?.name})
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setShowAlert(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={() => doDelete()} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  }, [deleteDashboard, showAlert]);

  const [expanded, setExpanded] = React.useState(false);
  return (
    <>
      {confirmDelete()}
      <Popover
        isOpen={expanded}
        onClose={() => setExpanded(false)}
        closeOnBlur={true}
        placement="bottom-start"
      >
        <PopoverTrigger>
          <Flex>
            <Flex>
              <Tooltip label="Select Dashboard">
                <Button
                  border="1px solid var(--gray)"
                  bg="var(--bg)"
                  aria-haspopup="listbox"
                  aria-expanded={expanded}
                  rightIcon={<FaChevronDown />}
                  onClick={() => setExpanded(!expanded)}
                  minWidth="250px"
                  justifyContent="space-between"
                  mt="2px"
                  height="45px"
                  px="8px"
                >
                  <HStack cursor="pointer">
                    <Text ml={2} color="var(--accent)">
                      Dashboard:
                    </Text>
                    {controller.selectedDashboard && (
                      <Text>{controller.selectedDashboard}</Text>
                    )}
                    {!controller.selectedDashboard && (
                      <Text color="yellow.200">Not Specified</Text>
                    )}
                  </HStack>
                </Button>
              </Tooltip>
            </Flex>
          </Flex>
        </PopoverTrigger>
        <PopoverContent p={0} mt="-4px" bg="var(--bg)" w="350px">
          <PopoverBody py={2} m={0} w="full">
            <VStack align="stretch" p={0} spacing={1}>
              <Box maxHeight="200px" overflowY="auto">
                {dashboards.map((dashboard, index) => (
                  <Button
                    key={index}
                    variant="ghost"
                    justifyContent="space-between"
                    onClick={(e) => {
                      e.preventDefault();
                      controller.setSelectedConfiguration(
                        dashboard.value.configurationId!
                      );
                      setExpanded(false);
                    }}
                    width="full"
                    py={0}
                    px={2}
                  >
                    <HStack w="full">
                      <Show if={editRow !== dashboard.value.configurationId}>
                        <Text
                          maxW="200px"
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                        >
                          {dashboard.label}
                        </Text>
                      </Show>
                      <Show if={editRow === dashboard.value.configurationId}>
                        <Input
                          onClick={(e) => e.stopPropagation()}
                          ref={renameDashboardInput}
                          defaultValue={dashboard.label}
                          onKeyUp={(e) => {
                            if (e.code === "Enter") {
                              controller.renameDashboard(
                                dashboard.value.configurationId!,
                                renameDashboardInput.current?.value ?? ""
                              );
                              setExpanded(false);
                              setEditRow(undefined);
                            }
                          }}
                        />
                      </Show>
                      <Spacer />
                      <Tooltip label="Rename Dashboard">
                        <EditIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditRow(dashboard.value.configurationId);
                          }}
                          color="var(--white)"
                        />
                      </Tooltip>
                      <Tooltip
                        label={
                          dashboards.length === 1
                            ? "You can't delete your only dashboard."
                            : "Delete dashboard"
                        }
                      >
                        <DeleteIcon
                          color={
                            dashboards.length === 1
                              ? "var(--dark-grey)"
                              : "var(--red)"
                          }
                          cursor={dashboards.length !== 1 ? "pointer" : "auto"}
                          onClick={(e) => {
                            if (dashboards.length === 1) {
                              return;
                            }
                            e.stopPropagation();
                            setDeleteDashboardRow(dashboard.value);
                            setShowAlert(true);
                          }}
                        />
                      </Tooltip>
                    </HStack>
                  </Button>
                ))}
              </Box>

              <Divider />
              <Flex direction="column" py={2}>
                <Text ml={1} mb={2}>
                  New Dashboard
                </Text>
                <Input
                  ref={newDashboardInput}
                  placeholder="Enter Name"
                  onKeyUp={(e) => {
                    if (e.code === "Enter") {
                      e.preventDefault();
                      controller.createDashboard(
                        newDashboardInput.current?.value ?? ""
                      );
                      setExpanded(false);
                    }
                  }}
                />
                <HStack mt={2} w="full">
                  <Spacer />
                  <Button
                    height="32px"
                    leftIcon={<FaPlus />}
                    onClick={(e) => {
                      e.preventDefault();
                      controller.createDashboard(
                        newDashboardInput.current?.value ?? ""
                      );
                      setExpanded(false);
                    }}
                    colorScheme="green"
                    size="sm"
                  >
                    Create Dashboard
                  </Button>
                </HStack>
              </Flex>
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};
