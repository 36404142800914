import { useAtom } from "jotai";
import { AppStateAtom } from "../store";
import { checkToken } from "../api";

export interface UseUser {
  refreshUserProfile: () => void;
  setLoginSettings: (email: string, theme: string, token: string) => void;
  setLoading: (loading: boolean) => void;
  logUserOut: () => void;
}

export const useUser = (): UseUser => {
  const [appState, setAppState] = useAtom(AppStateAtom);

  const refreshUserProfile = () => {
    checkToken().then((res) => {
      setAppState((prevState) => {
        return {
          ...prevState,
          user: res.user,
          subscriptionActive: res.user.subscriptionStatus,
          role: res.user.role,
        };
      });
    });
  };

  const setLoginSettings = (email: string, theme: string, token: string) => {
    localStorage.setItem("token", token);
    setAppState((prevState) => {
      return {
        ...prevState,
        loading: false,
        errorMessage: "",
        email: email,
        authed: true,
        gridTheme: theme || "ag-theme-alpine",
      };
    });
  };

  const logUserOut = () => {
    localStorage.removeItem("token");
    setAppState((prevState) => {
      return {
        ...prevState,
        authed: false,
        email: "",
        gridTheme: "ag-theme-alpine",
        loading: false,
      };
    });
  };

  const setLoading = (loading: boolean) => {
    setAppState((prevState) => {
      return {
        ...prevState,
        loading: loading,
      };
    });
  };

  return {
    refreshUserProfile,

    setLoginSettings,
    setLoading,
    logUserOut,
  };
};
