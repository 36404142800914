import {
  Box,
  Heading,
  Image,
  ListItem,
  OrderedList,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Component } from "react";
import { Panel } from "../../../../../components/design_library";
import React from "react";

export default class InteractiveBrokersInstructions extends Component {
  render() {
    return (
      <Panel>
        <Stack spacing="5">
          <Heading as="h3" size="lg">
            Instructions
          </Heading>

          <OrderedList>
            <ListItem>
              Log into the Interactive Brokers website, go to the 'Reports' page
              and 'Flex Queries' menu item
            </ListItem>
            <ListItem>
              <Box my={4} boxSize="full">
                <Image
                  src="https://cdn.wealthbee.io/main/brokers/instructions/ibkr/ibkr1.png"
                  style={{ width: "100%" }}
                />
              </Box>
            </ListItem>
            <ListItem>
              Click the + to the right of 'Activity Flex Query'
            </ListItem>
            <ListItem>
              <Box my={4} boxSize="full">
                <Image
                  src="https://cdn.wealthbee.io/main/brokers/instructions/ibkr/ibkr2.png"
                  style={{ width: "100%" }}
                />
              </Box>
            </ListItem>

            <ListItem>
              Give it a “Query Name” and click on “Cash Transactions” in the
              Sections list
            </ListItem>
            <ListItem>
              <Box my={4} boxSize="full">
                <Image
                  src="https://cdn.wealthbee.io/main/brokers/instructions/ibkr/ibkr3.png"
                  style={{ width: "100%" }}
                />
              </Box>
            </ListItem>
            <ListItem>
              Select all groups except Summary and then select all fields via
              the Select All checkbox, then click Save
            </ListItem>
            <ListItem>
              <Box my={4} boxSize="full">
                <Image
                  src="https://cdn.wealthbee.io/main/brokers/instructions/ibkr/ibkr4.png"
                  style={{ width: "100%" }}
                />
              </Box>
            </ListItem>
            <ListItem>Next click on “Trades” in the Sections list</ListItem>
            <ListItem>
              <Box my={4} boxSize="full">
                <Image
                  src="https://cdn.wealthbee.io/main/brokers/instructions/ibkr/ibkr5.png"
                  style={{ width: "100%" }}
                />
              </Box>
            </ListItem>
            <ListItem>
              Select "Execution" and the select all fields via the Select All
              checkbox, then click Save
            </ListItem>
            <ListItem>
              <Box my={4} boxSize="full">
                <Image
                  src="https://cdn.wealthbee.io/main/brokers/instructions/ibkr/ibkr6.png"
                  style={{ width: "100%" }}
                />
              </Box>
            </ListItem>

            <ListItem>
              In the list of headings select under 'Delivery Configuration':
              Change Format to CSV and ensure the 'Include column headers' and
              'Include section code and line descriptor' box is checked
            </ListItem>
            <ListItem>
              <Box my={4} boxSize="full">
                <Image
                  src="https://cdn.wealthbee.io/main/brokers/instructions/ibkr/ibkr7.png"
                  style={{ width: "100%" }}
                />
              </Box>
            </ListItem>
            <ListItem>
              Under 'General Configuration' make sure all toggles are set to
              'No' and the date and time formats match the following:
              'yyyy-MM-dd' and 'HH:mm:ss' and a single space between the date
            </ListItem>
            <ListItem>
              <Box my={4} boxSize="full">
                <Image
                  src="https://cdn.wealthbee.io/main/brokers/instructions/ibkr/ibkr8.png"
                  style={{ width: "100%" }}
                />
              </Box>
            </ListItem>
            <ListItem>
              Click 'Continue' and then 'Save Changes' on the next page
            </ListItem>
            <ListItem>
              Go back to the ‘Flex queries’ page and click on the right arrow
              and then click run selecting the Period you want.
            </ListItem>
            <ListItem>
              <Box my={4} boxSize="full">
                <Image
                  src="https://cdn.wealthbee.io/main/brokers/instructions/ibkr/ibkr9.png"
                  style={{ width: "100%" }}
                />
              </Box>
            </ListItem>
            <ListItem>Upload the file which is downloaded</ListItem>
          </OrderedList>
        </Stack>
      </Panel>
    );
  }
}
