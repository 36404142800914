import { useEffect, useState } from "react";
import { AccountAtom, TransactionsAtom } from "../../store";
import { useAtomValue } from "jotai";
import { useTransactions } from "../../hooks/useTransactions";

export interface ExplainTradeProps {
  trades: any[];
  onExplainTrade: () => void;
}

interface UseExplainTradesResponse {
  nbrTrades: number;

  books: string[];
  selectedBook: string | undefined;
  setSelectedBook: (value: string | undefined) => void;
  clearBook: () => void;

  positionGroups: string[];
  selectedPositionGroup: string | undefined;
  setSelectedPositionGroup: (value: string | undefined) => void;
  clearPositionName: () => void;

  strategies: string[];
  selectedStrategy: string | undefined;
  setSelectedStrategy: (value: string | undefined) => void;
  clearStrategy: () => void;

  tags: string[];
  selectedTags: string[];
  setSelectedTags: (value: string[]) => void;
  clearTags: () => void;

  notes: string;
  setNotes: (notes: string) => void;
  clearNotes: () => void;

  saveChanges: () => Promise<void>;
  loading: boolean;
}

export const useExplainTrades = (
  props: ExplainTradeProps
): UseExplainTradesResponse => {
  const transactions = useAtomValue(TransactionsAtom);
  const transactionsController = useTransactions();
  const accountState = useAtomValue(AccountAtom);

  // books
  const [books, setBooks] = useState<string[]>([]);
  const [selectedBook, setSelectedBook] = useState<string | undefined>(
    undefined
  );

  // positions
  const [positionGroups, setPositionGroups] = useState<string[]>([]);
  const [selectedPositionGroup, setSelectedPositionGroup] = useState<
    string | undefined
  >(undefined);

  // tags
  const [tags, setTags] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  // strategies
  const [strategies, setStrategies] = useState<string[]>([]);
  const [selectedStrategy, setSelectedStrategy] = useState<string | undefined>(
    undefined
  );

  const [loading, setLoading] = useState<boolean>(false);

  // notes
  const [notes, setNotes] = useState<string>("");

  const clearBook = () => {
    if (accountState.selectedAccount?.id) {
      transactionsController.updateBook(
        props.trades,
        "",
        accountState?.selectedAccount?.id
      );
      props.onExplainTrade();
    }
  };

  const clearPositionName = () => {
    if (accountState.selectedAccount?.id) {
      transactionsController.updateGroupNames(
        props.trades,
        "",
        accountState?.selectedAccount?.id
      );
      props.onExplainTrade();
    }
  };

  const clearStrategy = () => {
    if (accountState.selectedAccount?.id) {
      transactionsController.updateStrategy(
        props.trades,
        "",
        accountState?.selectedAccount?.id
      );
      props.onExplainTrade();
    }
  };

  const clearTags = () => {
    if (accountState.selectedAccount?.id) {
      transactionsController.setTags(
        [],
        props.trades,
        accountState?.selectedAccount?.id
      );
      props.onExplainTrade();
    }
  };

  const clearNotes = () => {
    if (accountState.selectedAccount?.id) {
      transactionsController.modifyNote(
        "",
        props.trades,
        accountState?.selectedAccount?.id
      );
      props.onExplainTrade();
    }
  };

  const determineBooks = () => {
    if (transactions.books) {
      setBooks(transactions.books);
    }
    let book = undefined;
    for (const trade of props.trades) {
      if (!book && trade.book) {
        book = trade.book;
      } else if (book && trade.book && book !== trade.book) {
        book = undefined;
        break;
      }
    }
    setSelectedBook(book);
  };

  const determinePositionGroups = () => {
    if (transactions.groupNames) {
      setPositionGroups(transactions.groupNames);
    }
    let positionGroup = undefined;
    for (const trade of props.trades) {
      if (!positionGroup && trade.positionGroup) {
        positionGroup = trade.positionGroup;
      } else if (
        positionGroup &&
        trade.positionGroup &&
        positionGroup !== trade.positionGroup
      ) {
        positionGroup = undefined;
        break;
      }
    }
    setSelectedPositionGroup(positionGroup);
  };

  const determineStrategies = () => {
    if (transactions.strategies) {
      setStrategies(transactions.strategies);
    }
    let strategy = undefined;
    for (const trade of props.trades) {
      if (!strategy && trade.strategy) {
        strategy = trade.strategy;
      } else if (strategy && trade.strategy && strategy !== trade.strategy) {
        strategy = undefined;
        break;
      }
    }
    setSelectedStrategy(strategy);
  };

  const determineTags = () => {
    setTags(transactions.tags ?? []);

    let tags = undefined;
    for (const trade of props.trades) {
      if (trade.tags) {
        const tradeTags = trade.tags as string[];
        const ordered = tradeTags.sort().join(",");
        if (!tags) {
          tags = ordered;
        } else if (tags !== ordered) {
          tags = undefined;
          break;
        }
      }
    }
    setSelectedTags(tags ? tags?.split(",") ?? [] : []);
  };

  const determineNotes = () => {
    let notes = undefined;
    for (const trade of props.trades) {
      if (trade.notes) {
        if (!notes) {
          notes = trade.notes;
        } else if (!trade.notes || notes !== trade.notes) {
          notes = undefined;
          break;
        }
      }
    }
    setNotes(notes ?? "");
  };

  const saveChanges = async () => {
    const updatedTrades = props.trades.map((trade) => {
      if (selectedStrategy) {
        trade.strategy = selectedStrategy;
      }

      if (selectedBook) {
        trade.book = selectedBook;
      }

      if (selectedPositionGroup) {
        trade.positionGroup = selectedPositionGroup;
      }

      if (selectedTags.length > 0) {
        trade.tags = selectedTags;
      }

      if (notes) {
        trade.notes = notes;
      }

      return trade;
    });

    await transactionsController.bulkUpdateTradeProperties(
      updatedTrades,
      accountState?.selectedAccount?.id
    );

    props.onExplainTrade();
    setSelectedBook(undefined);
    setSelectedPositionGroup(undefined);
    setSelectedStrategy(undefined);
    setSelectedTags([]);
  };

  useEffect(() => {
    setLoading(true);
    determineBooks();
    determinePositionGroups();
    determineStrategies();
    determineTags();
    determineNotes();

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [props.trades, transactions.books, transactions.strategies]);

  return {
    nbrTrades: props.trades.length ?? 0,
    books,
    loading,
    selectedBook,
    setSelectedBook,
    clearBook,
    positionGroups,
    selectedPositionGroup,
    setSelectedPositionGroup,
    clearPositionName,
    tags,
    selectedTags,
    setSelectedTags,
    clearTags,
    notes,
    setNotes,
    clearNotes,
    strategies,
    selectedStrategy,
    setSelectedStrategy,
    clearStrategy,
    saveChanges,
  };
};
