import {
  Box,
  Center,
  Stack,
  HStack,
  Badge,
  Avatar,
  Text,
  List,
  ListItem,
  Heading,
  VStack,
} from "@chakra-ui/react";
import NavBar from "../homepage/menu/App";
import Footer from "../homepage/footer/App";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import React from "react";
import { getPublicInsights } from "../../api";
import { Spinner } from "../../components";
import { InsightTypeIcon } from "../../components/insights/InsightTypeIcon";
import { InsightContainer } from "../../components/insights/InsightContainer";
import { InsightTemplate } from "../../types/Insight";

export const MarketingFeatureList = () => {
  const [features, setFeatures] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      const features = await getPublicInsights();
      setFeatures(features);
      setLoading(false);
    })();
  }, []);

  return (
    <Box background={"var(--bg)"}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Features List</title>
        <link rel="canonical" href="https://wealthbee.io/features" />
      </Helmet>
      <NavBar />
      <Center
        mx="auto"
        minH={"89vh"}
        alignItems={"flex-start"}
        mt="20px"
        p="20px"
      >
        <Stack spacing="5" flex="1" maxW="6xl" px="20px">
          <Stack spacing="1" px="40px">
            <Heading
              as="h1"
              size="2xl"
              color="fg.emphasized"
              maxW={"full"}
              textAlign={"left"}
            >
              Features
            </Heading>
            <Text
              color="fg.muted"
              textAlign={"left"}
              textStyle="sm"
              w="full"
              mb="40px"
            >
              WealthBee lets you automatically import your trades, analyze your
              trading performance and trading psychology. This is a list of some
              of the features available.
            </Text>
          </Stack>

          <VStack px="40px">
            <VStack maxW="8xl" alignItems={"flex-start"}>
              <Heading
                as="h2"
                size="lg"
                color="fg.emphasized"
                maxW={"full"}
                mb="10px"
                textAlign={"left"}
              >
                Dashboard
              </Heading>
              <Text mb="20px" textAlign={"left"}>
                The dashboard is the first feature you see when you log in. It
                provides a high-level overview of your trading performance and
                trading psychology.
              </Text>
              <Text mb="20px" textAlign={"left"}>
                You can have multiple portfolios, dashboards, filters and
                insights. All can be moved around and resized to your
                preferences. On this page you can add assets, options, equities,
                futures, unlisted assets.
              </Text>
              <Box px="30px">
                <picture>
                  <img
                    className="caroselImage"
                    alt="Demo image of options tracker 1"
                    src="https://cdn.wealthbee.io/main/app/homepage/dashboard.png"
                  />
                </picture>
              </Box>
            </VStack>

            <VStack justifyContent={"flex-start"} alignItems={"flex-start"}>
              <Heading
                as="h2"
                size="lg"
                color="fg.emphasized"
                maxW={"full"}
                textAlign={"left"}
              >
                Insights
              </Heading>

              <Text
                color="fg.muted"
                textAlign={"left"}
                textStyle="sm"
                w="full"
                mb="40px"
              >
                There are loads of insights available to help you understand
                your trading performance and trading psychology. We're always
                adding new insights to help you improve your trading. This is a
                summary of some of the insights available.
              </Text>
              <List listStyleType="none" w="full">
                {loading && <Spinner />}

                <VStack spacing="4" justifyContent={"space-evenly"}>
                  {features.map((feature) => (
                    <ListItemCustom key={feature.id} insight={feature} />
                  ))}
                </VStack>
              </List>
            </VStack>
          </VStack>

          <VStack px="40px">
            <VStack
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              w="full"
            >
              <Heading
                as="h2"
                size="lg"
                color="fg.emphasized"
                maxW={"full"}
                textAlign={"left"}
              >
                Positions
              </Heading>

              <Text
                color="fg.muted"
                textAlign={"left"}
                textStyle="sm"
                w="full"
                mb="40px"
              >
                The positions page is where you can see all your positions. You
                can filter by portfolio, asset, option, equity, future, unlisted
                asset, strategy, book, position group, underlying, and more. You
                can also see the performance of each position, the trading
                psychology of each position, and the risk of each position.
              </Text>

              <Box px="30px">
                <picture>
                  <img
                    className="caroselImage"
                    alt="Demo image of options tracker 1"
                    src="https://cdn.wealthbee.io/main/app/homepage/positions.png"
                  />
                </picture>
              </Box>
            </VStack>
          </VStack>

          <VStack px="40px">
            <VStack
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              w="full"
            >
              <Heading
                as="h2"
                size="lg"
                color="fg.emphasized"
                maxW={"full"}
                textAlign={"left"}
              >
                Transactions
              </Heading>

              <Text
                color="fg.muted"
                textAlign={"left"}
                textStyle="sm"
                w="full"
                mb="40px"
              >
                The transactions page is where you can see all your
                transactions. Here you can assign books, position groups and
                strategies. You can also see the performance of each
                transaction, the trading notes of each transaction.
              </Text>

              <Box px="30px">
                <picture>
                  <img
                    className="caroselImage"
                    alt="Demo image of options tracker 1"
                    src="https://cdn.wealthbee.io/main/app/homepage/transactions.png"
                  />
                </picture>
              </Box>
            </VStack>
          </VStack>

          <VStack px="40px">
            <VStack
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              w="full"
            >
              <Heading
                as="h2"
                size="lg"
                color="fg.emphasized"
                maxW={"full"}
                textAlign={"left"}
              >
                Cashflows
              </Heading>

              <Text
                color="fg.muted"
                textAlign={"left"}
                textStyle="sm"
                w="full"
                mb="40px"
              >
                The cashflows page is where you can see all your cashflows.
                These are dividends, interest, deposits, withdrawals, and more.
                You can filter by portfolio, asset, option, equity, future,
                unlisted asset, strategy, book, position group, underlying, and
                more.
              </Text>
            </VStack>
          </VStack>

          <VStack px="40px">
            <VStack
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              w="full"
            >
              <Heading
                as="h2"
                size="lg"
                color="fg.emphasized"
                maxW={"full"}
                textAlign={"left"}
              >
                User Pricing
              </Heading>

              <Text
                color="fg.muted"
                textAlign={"left"}
                textStyle="sm"
                w="full"
                mb="40px"
              >
                While WealthBee does provide pricing for US equities, futures
                and options. WealthBee also lets you set your own pricing,
                useful for unlisted assets and other assets that don't have a
                price.
              </Text>

              <Box px="30px">
                <picture>
                  <img
                    className="caroselImage"
                    alt="Demo image of options tracker 1"
                    src="https://cdn.wealthbee.io/main/app/homepage/userprices.png"
                  />
                </picture>
              </Box>
            </VStack>
          </VStack>
          <VStack px="40px">
            <VStack
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              w="full"
            >
              <Heading
                as="h2"
                size="lg"
                color="fg.emphasized"
                maxW={"full"}
                textAlign={"left"}
              >
                Embedding
              </Heading>

              <Text
                color="fg.muted"
                textAlign={"left"}
                textStyle="sm"
                w="full"
                mb="40px"
              >
                WealthBee lets you embed your dashboards, insights, positions,
                transactions, cashflows, and more on your website. This is
                useful for sharing your trading performance and trading
                psychology with others. This is an enterprise feature, please
                contact us to enable this feature.
              </Text>

              <Box px="30px">
                <picture>
                  <img
                    className="caroselImage"
                    alt="Demo image of options tracker 1"
                    src="https://cdn.wealthbee.io/main/app/homepage/embedding.png"
                  />
                </picture>
              </Box>
            </VStack>
          </VStack>

          <VStack px="40px">
            <VStack
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              w="full"
            >
              <Heading
                as="h2"
                size="lg"
                color="fg.emphasized"
                maxW={"full"}
                textAlign={"left"}
              >
                Historic Charts
              </Heading>

              <Text
                color="fg.muted"
                textAlign={"left"}
                textStyle="sm"
                w="full"
                mb="40px"
              >
                Monitor your entry and exit points with historic charts. This
                feature allows you to see the price of an asset at a specific
                time in the past. This is useful for backtesting your trading
                strategies.
              </Text>

              <Box px="30px">
                <picture>
                  <img
                    className="caroselImage"
                    alt="Demo image of options tracker 1"
                    src="https://cdn.wealthbee.io/main/app/homepage/charts.png"
                  />
                </picture>
              </Box>
            </VStack>
          </VStack>

          <VStack px="40px">
            <VStack
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              w="full"
            >
              <Heading
                as="h2"
                size="lg"
                color="fg.emphasized"
                maxW={"full"}
                textAlign={"left"}
              >
                Option Risk Analysis
              </Heading>

              <Text
                color="fg.muted"
                textAlign={"left"}
                textStyle="sm"
                w="full"
                mb="40px"
              >
                Easily view any position and the breakeven and risk of that
                position. This is useful for understanding the risk of your
                trading strategies.
              </Text>

              <Box px="30px">
                <picture>
                  <img
                    className="caroselImage"
                    alt="Demo image of options tracker 1"
                    src="https://cdn.wealthbee.io/main/app/homepage/optionpayoff.png"
                  />
                </picture>
              </Box>
            </VStack>
          </VStack>
          <VStack px="40px">
            <VStack
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              w="full"
            >
              <Heading
                as="h2"
                size="lg"
                color="fg.emphasized"
                maxW={"full"}
                textAlign={"left"}
              >
                Upload Trades
              </Heading>

              <Text
                color="fg.muted"
                textAlign={"left"}
                textStyle="sm"
                w="full"
                mb="40px"
              >
                Easily upload your trades from your broker. Including: Manual
                Entry, Interactive Brokers, TastyTrade, Thinkorswim, Robinhood,
                Firstrade, Ally, Etrade, Charles Schwab, Fidelity, TD Ameritrade
                and more.
              </Text>

              <Box px="30px">
                <picture>
                  <img
                    className="caroselImage"
                    alt="Demo image of options tracker 1"
                    src="https://cdn.wealthbee.io/main/app/homepage/brokers.png"
                  />
                </picture>
              </Box>
            </VStack>
          </VStack>
          <VStack px="40px">
            <VStack
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              w="full"
            >
              <Heading
                as="h2"
                size="lg"
                color="fg.emphasized"
                maxW={"full"}
                textAlign={"left"}
              >
                Calendar
              </Heading>

              <Text
                color="fg.muted"
                textAlign={"left"}
                textStyle="sm"
                w="full"
                mb="40px"
              >
                The calendar page is where you can see all your upcoming events
                such as expiries. This is useful for tracking your trading
                performance and trading psychology.
              </Text>

              <Box px="30px">
                <picture>
                  <img
                    className="caroselImage"
                    alt="Demo image of options tracker 1"
                    src="https://cdn.wealthbee.io/main/app/homepage/calendar.png"
                  />
                </picture>
              </Box>
            </VStack>
          </VStack>

          <VStack px="40px">
            <VStack
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              w="full"
            >
              <Heading
                as="h2"
                size="lg"
                color="fg.emphasized"
                maxW={"full"}
                textAlign={"left"}
              >
                Themes
              </Heading>

              <Text
                color="fg.muted"
                textAlign={"left"}
                textStyle="sm"
                w="full"
                mb="40px"
              >
                WealthBee lets you change the theme of the app. This is useful
                for changing the look and feel of the app to suit your
                preferences.
              </Text>

              <Box px="30px">
                <picture>
                  <img
                    className="caroselImage"
                    alt="Demo image of options tracker 1"
                    src="https://cdn.wealthbee.io/main/app/homepage/themes.png"
                  />
                </picture>
              </Box>
            </VStack>
          </VStack>
        </Stack>
      </Center>
      <Footer />
    </Box>
  );
};

const ListItemCustom = ({ insight }: { insight: InsightTemplate }) => {
  return (
    <ListItem
      width="full"
      key={insight.id}
      value={insight.id}
      bg="var(--dark-gray)"
      py="2"
      px="4"
      boxShadow="sm"
      position="relative"
      borderRadius="lg"
      border="1px solid var(--accent)"
    >
      <Stack shouldWrapChildren spacing="2">
        <HStack justifyContent={"space-between"}>
          <HStack justify="space-between" w="100%">
            <Text textStyle="lg" fontWeight="800" color="fg.emphasized">
              {insight.title}
            </Text>
            <HStack>
              {insight.tags.map((tag) => (
                <Badge size="sm" colorScheme="yellow" py="1">
                  {tag}
                </Badge>
              ))}
            </HStack>
          </HStack>

          <HStack borderRadius={"4px"} px="2" py="1" bg="var(--bg)">
            <InsightTypeIcon type={insight.type} />
            <Text> {insight.type}</Text>
          </HStack>
        </HStack>
        <Text textStyle="xs" color="fg.subtle" fontWeight={300}>
          {insight.description}
        </Text>
      </Stack>
    </ListItem>
  );
};
