import BookRender from "../../../components/grid/renderers/BookRender";
import { UnderlyingRender } from "../../../components/grid/renderers/UnderlyingRender";
import { PositionGroupRender, TradeDetailsRender } from "../../../components";
import moment from "moment";

export const PositionsInnerRender = (params: any) => {
  if (params.node.field === "book") {
    return BookRender(params);
  } else if (params.node.field === "underlyingName") {
    return UnderlyingRender(params);
  } else if (params.node.field === "positionName") {
    return PositionGroupRender(params);
  } else if (params.node.field === "positionDate") {
    return `${moment(params.valueFormatted).calendar(null, {
      lastDay: "[Yesterday]",
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      lastWeek: "[last] dddd",
      nextWeek: "dddd",
      sameElse: "L",
    })} (${moment(params.valueFormatted).fromNow()})`;
  } else {
    return TradeDetailsRender(params, "Positions");
  }
};
