import { useCallback, useRef, useState } from "react";
import {
  ColumnEverythingChangedEvent,
  ColumnResizedEvent,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import { useDebounce } from "../../features/hooks";

interface UseGridStateProps {
  gridName: string;
}

interface UseGridStateResponse {
  onGridReady: (e: GridReadyEvent) => void;
  onFirstDataRendered: () => void;
  onColumnChanged: (e: ColumnEverythingChangedEvent) => void;
  onColumnResized: (e: ColumnResizedEvent) => void;
  setSwitchingGrouping: (value: boolean) => void;
  performGroupChanged: (params: any) => void;
  onColumnMoved: (e: ColumnEverythingChangedEvent) => void;
}

export const useGridState = (
  props: UseGridStateProps
): UseGridStateResponse => {
  const { gridName } = props;

  const gridRef = useRef<GridApi | null>(null);
  const [switchingGrouping, setSwitchingGrouping] = useState(false);

  const onGridReady = useCallback((e: GridReadyEvent) => {
    gridRef.current = e.api;
  }, []);

  const onFirstDataRendered = useCallback(() => {
    const savedColumnState = localStorage.getItem(`${gridName}_ColumnState`);
    if (savedColumnState && !switchingGrouping) {
      gridRef.current?.applyColumnState({
        state: JSON.parse(savedColumnState),
        applyOrder: true,
      });
    }

    const savedGroupState = JSON.parse(
      localStorage.getItem(`${gridName}_GroupState`)
    );

    if (savedGroupState) {
      gridRef.current?.expandAll();
      gridRef.current?.collapseAll();
      savedGroupState.forEach((groupId: string) => {
        gridRef.current?.getRowNode(groupId)?.setExpanded(true);
      });
    }
  }, []);

  const ignoreSources = ["api", "gridInitializing", "flex"];

  const performColumnChanged = useCallback(
    (e: ColumnEverythingChangedEvent | ColumnResizedEvent) => {
      if (e && !ignoreSources.includes(e.source)) {
        const columnState = gridRef.current?.getColumnState();
        if (columnState) {
          localStorage.setItem(
            `${gridName}_ColumnState`,
            JSON.stringify(columnState)
          );
        }
      }
    },
    []
  );

  const performGroupChanged = useCallback((params: any) => {
    let savedGroupState = JSON.parse(
      localStorage.getItem(`${gridName}_GroupState` || "[]")
    );

    if (!savedGroupState) {
      savedGroupState = [];
    }

    if (!params.node.expanded) {
      savedGroupState = savedGroupState.filter(
        (groupId: string) => groupId !== params.node.id
      );
    } else {
      savedGroupState.push(params.node.id);
    }
    localStorage.setItem(
      `${gridName}_GroupState`,
      JSON.stringify(savedGroupState)
    );
  }, []);

  const onColumnChanged = useDebounce(performColumnChanged, 200);
  const onColumnResized = useDebounce(performColumnChanged, 200);
  const onColumnMoved = useDebounce(performColumnChanged, 200);

  return {
    performGroupChanged,
    onGridReady,
    onFirstDataRendered,
    onColumnChanged,
    setSwitchingGrouping,
    onColumnResized,
    onColumnMoved,
  };
};
