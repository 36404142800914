import React, { useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import {
  FormFrameworkComponentType,
  Instrument,
  InstrumentType,
} from "../../../../types";
import { Currency } from "../../../../types/Currencies";
import moment from "moment";
import { calculateTheoreticalPriceForInstrument } from "../../../../api";

interface UseInstrumentFormControllerProps {
  instrument?: Instrument;
  volatility?: number;
  updateInstrument: (instrument: Instrument) => void;
}

interface UseInstrumentFormController {
  isLoading: boolean;
  schema: any;
  submitPayload: (payload: any) => void;
  calculateTheoreticalPrice: () => void;
}

export const useInstrumentFormController = (
  props: UseInstrumentFormControllerProps
): UseInstrumentFormController => {
  const [schema, setSchema] = React.useState<any>({});

  const toast = useToast();

  const submitPayload = async (payload: any) => {
    const instrument: Instrument = {
      ...props.instrument!,
      name: payload.name.value,
      expiry: payload.expiry.value
        ? moment(payload.expiry.value)
            .add(moment().utcOffset(), "m")
            .format("YYYY-MM-DD")
        : undefined,
      instrumentType: payload.instrumentType.value,
      currency: payload.currency.value,
      multiplier: payload.multiplier.value,
      volatility: payload.historicalVolatility.value,
      strike:
        payload.instrumentType.value === InstrumentType.Option
          ? payload.strike.value
          : undefined,
      putCall:
        payload.instrumentType.value === InstrumentType.Option
          ? payload.putCall.value
          : undefined,
      pricingDisabled: payload.pricingDisabled.value ?? false,
    };
    props.updateInstrument(instrument);
  };

  const calculateTheoreticalPrice = async () => {
    const result = await calculateTheoreticalPriceForInstrument(
      props.instrument as Instrument
    );
    if (result) {
      toast({
        title: "Theoretical Price Calculated",
        description: `Calculated Price: ${result.data?.price} (saved against instrument)`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      // console.log("Theoretical Price:", result);
    }
  };

  useEffect(() => {
    if (props.instrument) {
      setSchema({
        pricingDisabled: {
          value: props.instrument!.pricingDisabled ?? false,
          label: "Pricing Disabled",
          error: "",
          type: FormFrameworkComponentType.CheckBox,
          required: true,
        },
        name: {
          value: props.instrument!.name || "",
          label: "Name",
          error: "",
          type: FormFrameworkComponentType.Text,
          required: true,
        },
        instrumentType: {
          value: props.instrument!.instrumentType || "",
          label: "Instrument Type",
          error: "",
          type: FormFrameworkComponentType.Select,
          required: true,
          enum: Object.values(InstrumentType),
        },
        symbol: {
          value: props.instrument!.symbol || "",
          label: "Symbol",
          error: "",
          type: FormFrameworkComponentType.Text,
          required: true,
        },
        exchange: {
          value: props.instrument!.exchange || "",
          label: "Exchange",
          error: "",
          type: FormFrameworkComponentType.Text,
          required: true,
        },
        currency: {
          value: props.instrument!.currency || "",
          label: "Currency",
          error: "",
          type: FormFrameworkComponentType.Select,
          enum: Object.values(Currency),
          required: true,
        },
        multiplier: {
          value: props.instrument!.multiplier || 1,
          label: "Multiplier",
          error: "",
          type: FormFrameworkComponentType.Number,
          required: true,
        },
        historicalVolatility: {
          value: props.volatility || 0,
          label: "Historical Volatility",
          error: "",
          type: FormFrameworkComponentType.Number,
        },
        expiry: {
          value: props.instrument!.expiry || "",
          label: "Expiry",
          error: "",
          type: FormFrameworkComponentType.Date,
        },
        strike: {
          value: props.instrument!.strike || 0,
          label: "Strike",
          error: "",
          type: FormFrameworkComponentType.Number,
        },
        putCall: {
          value: props.instrument!.putCall || "",
          label: "Put/Call",
          error: "",
          type: FormFrameworkComponentType.Select,
          enum: ["Put", "Call"],
        },
      });
    }
  }, [props.instrument]);

  return {
    isLoading: false,
    schema,
    submitPayload,
    calculateTheoreticalPrice,
  };
};
