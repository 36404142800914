import { APIEndpoint } from ".";

// export const getEmbedDashboard = async (
//   apiKey: string,
//   dashboardId: string,
//   portfolioId: string,
//   theme?: string,
//   showToolbar?: boolean
// ) => {
//   return fetch(`${APIEndpoint}/embed/dashboard`, {
//     method: "GET",
//     headers: new Headers({
//       "Content-Type": "application/json",
//       apikey: `${apiKey}`,
//       dashboardId: dashboardId.toString(),
//       portfolioId: portfolioId.toString(),
//       theme: theme || "",
//       showToolbar: showToolbar?.toString() || "false",
//     }),
//   })
//     .then((response) => response.json())
//     .catch((error) => console.error("Error registering email:", error));
// };

export const getEmbeddedDashboardDatasets = async (
  apiKey: string,
  dashboardId: string,
  portfolioId: string
) => {
  return fetch(`${APIEndpoint}/embed/dashboard/datasets`, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      apikey: `${apiKey}`,
      dashboardId: dashboardId.toString(),
      portfolioId: portfolioId.toString(),
    }),
  })
    .then((response) => response.json())
    .catch((error) =>
      console.error("Error fetching insights datasets:", error)
    );
};
