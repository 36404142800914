import React from "react";
import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";
import { useInstrumentsController } from "./useInstrumentsController";
import { InstrumentSearch } from "../../instrument-search/InstrumentSearch";
import { Instrument } from "../../../types";
import { InstrumentIssues } from "./issues/InstrumentIssues";
import { PricingTool } from "./pricing/PricingTool";
import { InstrumentPrices } from "./pricing/InstrumentPrices";
import { InstrumentList } from "./list/InstrumentList";
import { InstrumentForm } from "./form/InstrumentForm";

export const Instruments = () => {
  const controller = useInstrumentsController();

  return (
    <Flex w="full" h="full" direction="column">
      <Box w="full">
        <InstrumentSearch onInstrumentSelected={controller.loadInstrument} />
      </Box>
      <Flex flex={1} w="full" pt={1}>
        <Grid
          gap={0}
          templateRows="repeat(8, 1fr)"
          templateColumns="repeat(5, 2fr)"
          h="100%"
          w="100%"
          border="1px solid"
          borderColor="gray.600"
          overflow="clip"
        >
          <GridItem rowSpan={6} colSpan={1} px="4px">
            <InstrumentList
              parent={controller.instrument}
              children={controller.children}
              selectInstrument={(instrument: Instrument) => {
                controller.selectInstrument(instrument);
              }}
            />
          </GridItem>

          <GridItem
            rowSpan={6}
            colSpan={2}
            borderLeft="1px solid"
            borderColor="gray.600"
            px="4px"
          >
            <InstrumentForm
              instrument={controller.selectedInstrument}
              volatility={controller.volatility}
              updateInstrument={(value: Instrument) => {
                controller.applyChanges(value);
              }}
            />
          </GridItem>

          <GridItem
            rowSpan={8}
            colSpan={2}
            borderLeft="1px solid"
            borderColor="gray.600"
            px="4px"
          >
            <InstrumentIssues />
          </GridItem>
          <GridItem
            rowSpan={8}
            colSpan={2}
            borderLeft="1px solid"
            borderColor="gray.600"
            px="4px"
          >
            <PricingTool instrument={controller.selectedInstrument} />
          </GridItem>
        </Grid>
      </Flex>

      <Box border="1px solid" borderColor="gray.600" px="4px">
        <InstrumentPrices instrument={controller.selectedInstrument} />
      </Box>
    </Flex>
  );
};
