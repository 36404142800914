import { useEffect, useState, useCallback } from "react";
import {
  deleteInsight,
  getInsights,
  getInsightsDatasets,
  updateInsight,
} from "../../../api";
import { useAtomValue } from "jotai";
import { AccountAtom } from "../../../store";
import { useNotifications } from "../../../hooks/useNotifications";
import { InsightSearchPages } from "../../../types";
import { useFilters } from "../../../hooks/useFilters";
import { InsightTemplate } from "../../../types/Insight";
import {
  DashboardLayoutStateStore,
  SelectedDashboardIdAtom,
} from "../../../store/DashboardLayout";
import moment from "moment";

interface Insight {
  insightId?: number;
  [key: string]: any;
}

interface UseInsightsController {
  handleToggle: () => void;
  handleDelete: (insightId: number) => void;
  fetchInsights: (page: InsightSearchPages, search: string) => void;
  fetchInsightsDatasets: () => void;
  isOpen: boolean;
  insights: Insight[];
  insightsDataset: Insight[];
  insightData: Insight | null;
  insightId: number | null;
  setInsightId: (id: number | null) => void;
  loading: boolean;
  displaysPage: InsightSearchPages;
  setDisplayedPage: (page: InsightSearchPages) => void;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  selectedInsight: Insight | null;
  setSelectedInsight: (insight: Insight | null) => void;
  page: string;
  setPage: (page: "insights" | "creator") => void;
  setInsightData: (insight: InsightTemplate) => void;
}

export const useInsightsController = (): UseInsightsController => {
  const [page, setPage] = useState("insights");
  const [insightData, setInsightData] = useState<Insight | null>(null);
  const [insights, setInsights] = useState<Insight[]>([]);
  const [insightsDataset, setInsightsDataset] = useState<Insight[]>([]);
  const [insightId, setInsightId] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedInsight, setSelectedInsight] = useState<Insight | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [displaysPage, setDisplayedPage] = useState(InsightSearchPages.ALL);

  const selectedDashboardId = useAtomValue(SelectedDashboardIdAtom);
  const notifications = useNotifications();
  const accountState = useAtomValue(AccountAtom);
  const filters = useFilters();
  const dashboardLayoutStore = useAtomValue(DashboardLayoutStateStore);

  const handleToggle = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, []);

  useEffect(() => {
    fetchInsightsDatasets();
  }, [
    filters.selectedUnderlying,
    filters.selectedTag,
    filters.selectedStrategy,
    filters.selectedPositionGroup,
    dashboardLayoutStore.gridLoading,
  ]);

  const handleDelete = useCallback(
    async (insightId: number) => {
      if (!window.confirm("Are you sure you want to delete this insight?"))
        return;

      setLoading(true);
      try {
        await deleteInsight(insightId);
        notifications.createNotification(
          "Insight deleted",
          "Your insight has been deleted successfully"
        );
        fetchInsights(displaysPage, searchQuery);
      } catch (error) {
        notifications.createNotification(
          "Error deleting insight",
          "There was an error deleting your insight"
        );
        console.error("Error deleting insight:", error);
      } finally {
        setLoading(false);
      }
    },
    [displaysPage, searchQuery, notifications]
  );

  const fetchInsights = useCallback(
    async (page?: InsightSearchPages, search?: string) => {
      setLoading(true);
      try {
        const response = await getInsights(page, search);
        setInsights(response.data);
      } catch (error) {
        notifications.createNotification(
          "Error fetching insights",
          "There was an error fetching insights"
        );
        console.error("Error fetching insights:", error);
      } finally {
        setLoading(false);
      }
    },
    [notifications]
  );

  const fetchInsightsDatasets = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getInsightsDatasets(
        accountState?.selectedAccount?.id,
        selectedDashboardId ??
          accountState.selectedAccount.defaultDashboardConfigurationId,
        filters.selectedUnderlying,
        filters.selectedTag,
        filters.selectedStrategy,
        filters.selectedBook,
        filters.selectedPositionGroup,
        moment(filters.fromDate).format("YYYY-MM-DD"),
        moment(filters.toDate).format("YYYY-MM-DD")
      );
      setInsightsDataset(response);
      // notifications.clearNotificationsAfterDelay(3000);
    } catch (error) {
      // notifications.createNotification(
      //   "Error fetching insights datasets",
      //   "There was an error fetching insights datasets"
      // );
      console.error("Error fetching insights datasets:", error);
    } finally {
      setLoading(false);
    }
  }, [accountState, selectedDashboardId, filters, notifications]);

  return {
    handleToggle,
    handleDelete,
    fetchInsights,
    fetchInsightsDatasets,
    isOpen,
    insights,
    insightsDataset,
    insightData,
    setInsightData,
    insightId,
    setInsightId,
    loading,
    displaysPage,
    setDisplayedPage,
    searchQuery,
    setSearchQuery,
    selectedInsight,
    setSelectedInsight,
    page,
    setPage,
  };
};
