import { ColDef } from "ag-grid-community";
import {
  calculateDaysUntilExpiry,
  ColumnTypeEnum,
  createGroupColumn,
} from "../../components";
import convertSymbolToReadable from "../../components/grid/utils/convertSymbolToReadable";

export const columnDefinitions: ColDef[] = [
  createGroupColumn(
    "Underlying Symbol",
    "underlyingSymbol",
    ColumnTypeEnum.Logo,
    {
      suppressColumnsToolPanel: true,
      hide: true,
      rowDrag: true,
    }
  ),

  createGroupColumn("Instrument", "instrument.symbol", ColumnTypeEnum.Text, {
    valueGetter: (params) => {
      return convertSymbolToReadable(params.data.instrument.symbol);
    },
    aggFunc: "distinct",
    minWidth: 200,
    hide: true,
  }),

  createGroupColumn("Book", "book", ColumnTypeEnum.Text, {
    rowGroup: true,
    hide: true,
    aggFunc: "distinct",
    rowDrag: true,
    sort: "asc",
    suppressColumnsToolPanel: true,
  }),

  createGroupColumn("Underlying", "underlyingName", ColumnTypeEnum.Text, {
    rowGroup: true,
    hide: true,
    sort: "asc",
    rowDrag: true,
    suppressColumnsToolPanel: true,
    valueGetter: (params) => {
      if (params.data.underlyingType === "Future") {
        return params.data.underlyingName;
      } else {
        return params.data.underlyingSymbol;
      }
    },
  }),

  createGroupColumn("Position Group", "positionName", ColumnTypeEnum.Text, {
    rowGroup: true,
    hide: true,
    sort: "asc",
    rowDrag: true,
    suppressColumnsToolPanel: true,
  }),

  createGroupColumn("Strategy", "strategy", ColumnTypeEnum.Text, {
    aggFunc: "distinct",
  }),

  // tags
  createGroupColumn("Tags", "tags", ColumnTypeEnum.Tags, {
    hide: true,
    aggFunc: "none",
  }),

  createGroupColumn("Last Trade Date", "positionDate", ColumnTypeEnum.Date, {
    sort: "asc",
    aggFunc: "distinct",
    minWidth: 140,
    hide: true,
  }),

  createGroupColumn("Quantity", "quantity", ColumnTypeEnum.Numeric, {
    aggFunc: "none",
    hide: true,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  }),

  createGroupColumn("Market Price", "price", ColumnTypeEnum.Currency, {
    cellRendererParams: { noFormatting: true },
    hide: true,
    aggFunc: "none",
  }),

  createGroupColumn(
    "Underlying Price",
    "underlyingPrice",
    ColumnTypeEnum.Currency,
    { aggFunc: "none" }
  ),

  createGroupColumn("Realized P&L", "realisedPnl", ColumnTypeEnum.Currency, {
    cellRendererParams: { showDirection: true },
    aggFunc: "sum",
  }),

  createGroupColumn(
    "Unrealized P&L$",
    "unrealisedPnl",
    ColumnTypeEnum.CurrencyWithPercentage,
    {
      hide: false,
      cellRendererParams: {
        showDirection: true,
        percentageField: "unRealisedPnLPercentage",
      },
      aggFunc: "sum",
      valueGetter: (params) => {
        if (params.data.priceDate === null) {
          return null;
        } else {
          return params.data.unrealisedPnl;
        }
      },
    }
  ),

  createGroupColumn("Total P&L$", "totalPnl", ColumnTypeEnum.Currency, {
    hide: false,
    aggFunc: "sum",
    valueGetter: (params) => {
      return params.data.unrealisedPnl + params.data.realisedPnl;
    },
  }),

  createGroupColumn(
    "Gross proceeds",
    "grossProceeds",
    ColumnTypeEnum.Currency,
    { cellRendererParams: { showDirection: true }, aggFunc: "sum" }
  ),
  createGroupColumn("Net proceeds", "netProceeds", ColumnTypeEnum.Currency, {
    cellRendererParams: { showDirection: true },
    aggFunc: "sum",
  }),

  createGroupColumn("Total Credits", "totalCredits", ColumnTypeEnum.Currency, {
    cellRendererParams: { showDirection: true },
    aggFunc: "sum",
  }),

  createGroupColumn("Total Debits", "totalDebits", ColumnTypeEnum.Currency, {
    cellRendererParams: { showDirection: true },
    aggFunc: "sum",
  }),

  createGroupColumn("Costs", "costs", ColumnTypeEnum.Currency, {
    hide: true,
    aggFunc: "sum",
    valueGetter: (params) => {
      return params.data.costs * -1;
    },
  }),

  createGroupColumn("Type", "instrument.instrumentType", ColumnTypeEnum.Text, {
    aggFunc: "distinct",
  }),

  createGroupColumn("Put/Call", "instrument.putCall", ColumnTypeEnum.Pill, {
    cellRendererParams: {
      exactMatch: true,

      colourMap: {
        Put: "var(--trade-call)",
        Call: "var(--trade-put)",
      },
      defaultColour: "var(--accent)",
    },
    aggFunc: "distinct",
  }),

  createGroupColumn("Strike", "instrument.strike", ColumnTypeEnum.Numeric, {
    aggFunc: "distinct",
  }),
  createGroupColumn("Expiry Date", "instrument.expiry", ColumnTypeEnum.Date, {
    aggFunc: "distinct",
  }),

  createGroupColumn(
    "Multiplier",
    "instrument.multiplier",
    ColumnTypeEnum.Numeric,
    {
      aggFunc: "distinct",
    }
  ),

  // daysInPosition
  createGroupColumn(
    "Days in Position",
    "daysInPosition",
    ColumnTypeEnum.Numeric,
    {
      aggFunc: "avg",
      hide: true,
      valueFormatter: (params) => {
        if (typeof params.value === "object") {
          return params?.value?.value?.toFixed(1);
        } else {
          return params?.value?.toFixed(1);
        }
      },
    }
  ),

  createGroupColumn(
    "Days until expiry",
    "daysUntilExpiry",
    ColumnTypeEnum.Numeric,
    {
      aggFunc: "avg",
      hide: false,
      valueGetter: (params) => {
        let daysUntilExpiry = calculateDaysUntilExpiry(
          params?.node?.data?.instrument
        );
        if (isNaN(daysUntilExpiry) || daysUntilExpiry < 0) {
          return "";
        }
        return daysUntilExpiry.toFixed(2) || "";
      },
    }
  ),
];
