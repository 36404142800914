import { Box, HStack, Heading, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { ChevronRight, StockLogo } from "../../design_library";
import { FaObjectGroup } from "react-icons/fa";
import NavWrapper from "./NavWrapper";

export default function PositionsGroupTutorial() {
  return (
    <NavWrapper previousEnabled={false} isFinalStep={false} nextDisabled={true}>
      <HStack>
        <FaObjectGroup />
        <Heading
          as="h2"
          fontSize="18px"
          mb={4}
          fontWeight={600}
          textAlign={"left"}
        >
          Position Groups
        </Heading>
      </HStack>
      <Text mt="10px">
        Position groups are the second lowest level of the account hierarchy.
        Their purpose is to group positions together for easier management. If
        you're trading options then this could be 'Covered call', 'Iron Condor',
        etc. and would allow you to track performance through rolls, expiries
        and assignments.
      </Text>
      <Text mt="10px">
        These groups can be renamed and deleted by right-clicking on the group.
      </Text>
      <Text mt="10px">
        You can drag and drop positions between groups to re-allocate them. You
        can also drag positions into any other group.
      </Text>
      <Text mt="10px"></Text>

      <VStack alignItems={"flex-start"}>
        <HStack>
          <Text>Mobile:</Text>
          <span className="rainbow">
            Tap the 'right arrow' (Chevron) next to the group{" "}
          </span>
        </HStack>
        <HStack>
          <Text>Desktop:</Text>
          <span className="rainbow">
            Double-click the position group to see the positions
          </span>
        </HStack>
      </VStack>
    </NavWrapper>
  );
}
