import { Checkbox, Stack, Switch } from "@chakra-ui/react";
import { TextInput } from "./TextInput";
import { TextareaInput } from "./TextAreaInput";
import { DatePicker } from "./DatePicker";
import { MultiSelect } from "./MultiInput";
import { RadioInput } from "./RadioInput";
import { NumberInput } from "./NumberInput";
import { FormFrameworkComponentType } from "../../../types/Enums";
import { ReadOnly } from "./ReadOnly";
import { InstrumentSearch } from "./InstrumentSearch";
import React from "react";

export const SchemaMapper = (props: {
  schema: any;
  onChange: (key: string, value: string) => void;
}) => {
  return (
    <Stack spacing="0">
      {Object.keys(props.schema).map((key) => {
        const field = props.schema[key];

        if (field.type === FormFrameworkComponentType.Text) {
          return (
            <TextInput
              key={key}
              label={field.label}
              required={field.required}
              value={field.value}
              defaultValue={field.defaultValue}
              placeholder={field.placeholder}
              error={field.error}
              field={key}
              onChange={props.onChange}
            />
          );
        } else if (field.type === FormFrameworkComponentType.TextArea) {
          return (
            <TextareaInput
              key={key}
              label={field.label}
              required={field.required}
              value={field.value}
              placeholder={field.placeholder}
              error={field.error}
              field={key}
              onChange={props.onChange}
            />
          );
        } else if (field.type === FormFrameworkComponentType.Date) {
          return (
            <DatePicker
              key={key}
              label={field.label}
              required={field.required}
              placeholder={field.placeholder}
              value={field.value}
              error={field.error}
              field={key}
              onChange={props.onChange}
            />
          );
        } else if (field.type === FormFrameworkComponentType.Select) {
          return (
            <MultiSelect
              key={key}
              label={field.label}
              required={field.required}
              value={field.value}
              error={field.error}
              field={key}
              onChange={props.onChange}
              enums={field.enum}
              enableCreateNew={field.enableCreateNew}
              api={field.api}
            />
          );
        } else if (field.type === FormFrameworkComponentType.Radio) {
          return (
            <RadioInput
              key={key}
              label={field.label}
              required={field.required}
              value={field.value}
              error={field.error}
              field={key}
              onChange={props.onChange}
              enums={field.enum}
            />
          );
        } else if (field.type === FormFrameworkComponentType.CheckBox) {
          return (
            <Switch
              key={key}
              required={field.required}
              placeholder={field.placeholder}
              isChecked={field.value}
              onChange={(e: any) => {
                props.onChange(key, !field.value as any);
              }}
              my={2}
              pb={2}
            >
              {field.label}
            </Switch>
          );
        } else if (field.type === FormFrameworkComponentType.Number) {
          return (
            <NumberInput
              key={key}
              label={field.label}
              required={field.required}
              value={field.value}
              error={field.error}
              placeholder={field.placeholder}
              defaultValue={field.defaultValue}
              field={key}
              onChange={props.onChange}
            />
          );
        } else if (field.type === FormFrameworkComponentType.ReadOnly) {
          return <ReadOnly key={key} label={field.label} value={field.value} />;
        } else if (field.type === FormFrameworkComponentType.InstrumentSearch) {
          return (
            <InstrumentSearch
              key={key}
              label={field.label}
              instrumentType={field.instrumentType}
              value={field.value}
              error={field.error}
              field={key}
              onChange={props.onChange}
              enableCreateNew={field.enableCreateNew}
            />
          );
        }
      })}
    </Stack>
  );
};
