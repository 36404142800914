import { useEffect, useMemo, useState } from "react";
import { useAtomValue } from "jotai";
import { Brokers, SyncSetting } from "../../../../types";
import { AccountAtom } from "../../../../store";
import {
  addOrUpdateSyncSetting,
  getSyncSettings,
  testSyncSetting,
} from "../../../../api";
import { useNotifications } from "../../../../hooks/useNotifications";
import { ETRADEAutoSyncConfig } from "./ETRADEAutoSyncConfig";

// Props interface for the hook
export interface ETradeAutoSyncControllerProps {
  settings?: SyncSetting;
  close?: () => void;
  readOnly?: boolean;
}

// Response interface for the hook
interface ETradeAutoSyncControllerResponse {
  readOnly?: boolean;
  config: ETRADEAutoSyncConfig;
  setConfig: (config: ETRADEAutoSyncConfig) => void;
  connectionStatus: string;
  validAPIKey: boolean;
  validAPISecret: boolean;
  saveConfig: (overrideConfig: ETRADEAutoSyncConfig) => void;
  setLinkCode: (code: string) => void;
  testConfig: (
    stage:
      | "AUTHORIZE_URL"
      | "LINK_CODE"
      | "TEST_CONNECTION"
      | "RENEW_ACCESS_TOKEN"
      | "TEST_DATA_ACCESS"
      | "RUN_FULL_CONNECTION_TEST"
  ) => void;
}

// Main hook to handle ETRADE Auto Sync Controller logic
export const useETradeAutoSyncController = (
  props: ETradeAutoSyncControllerProps
): ETradeAutoSyncControllerResponse => {
  const notifications = useNotifications();
  const accountState = useAtomValue(AccountAtom);

  // State management for configuration and connection status
  const [config, setConfig] = useState<ETRADEAutoSyncConfig>({});
  const [connectionStatus, setConnectionStatus] =
    useState<string>("NOT_CONNECTED");
  const [syncSettingIdTemp, setSyncSettingIdTemp] = useState<
    number | undefined
  >(props.settings?.syncSettingId);

  // Validating API Key and Secret using useMemo
  const validAPIKey = useMemo(() => !!config.apiKey, [config.apiKey]);
  const validAPISecret = useMemo(() => !!config.secretKey, [config.secretKey]);

  // Function to save or update sync settings
  const saveConfig = async (overrideConfig: ETRADEAutoSyncConfig) => {
    const accountId = accountState.selectedAccount?.id;
    if (!accountId) return;

    const payload = {
      accountId,
      broker: Brokers.ETrade,
      name: "ETrade Auto Sync Settings",
      settings: JSON.stringify(overrideConfig || config),
      syncSettingId: props.settings?.syncSettingId || syncSettingIdTemp,
    };

    try {
      const result = await addOrUpdateSyncSetting(
        payload as SyncSetting,
        accountId
      );
      if (result.success) {
        setSyncSettingIdTemp(result.data.syncSettingId);
      }
    } catch (error) {
      notifications.showErrorMessage("Auto Sync Settings", error as any);
    }
  };

  // Function to test configuration stages such as Authorize, Link Code, Test Connection, etc.
  const testConfig = async (
    stage:
      | "AUTHORIZE_URL"
      | "LINK_CODE"
      | "TEST_CONNECTION"
      | "RENEW_ACCESS_TOKEN"
      | "TEST_DATA_ACCESS"
      | "RUN_FULL_CONNECTION_TEST"
  ) => {
    setConnectionStatus("");
    const accountId = accountState.selectedAccount?.id;
    if (!accountId) return;

    const payload = {
      accountId,
      broker: Brokers.ETrade,
      name: "ETrade Auto Sync Settings",
      settings: JSON.stringify(config),
      syncSettingId: props.settings?.syncSettingId,
    };

    try {
      if (stage === "AUTHORIZE_URL") {
        const result = await testSyncSetting(
          payload as SyncSetting,
          accountId,
          stage
        );
        window.open(result?.authorizeUrl, "_blank");
      } else if (stage === "TEST_CONNECTION") {
        const result = await testSyncSetting(
          { ...payload },
          accountId,
          "GET_ACCESS_TOKEN"
        );
        setConnectionStatus(result.success ? "CONNECTED" : "FAILED");
      } else {
        await testSyncSetting({ ...payload }, accountId, stage);
      }
    } catch (error) {
      notifications.showErrorMessage("Test Config Error", error as any);
    }
  };

  // Function to set link code and update configuration
  const setLinkCode = async (code: string) => {
    const result = await getSyncSettings(accountState.selectedAccount?.id);
    const extractedSettings = JSON.parse(result[0].settings);
    const updatedConfig = { ...extractedSettings, code };

    setConfig(updatedConfig);
    saveConfig(updatedConfig);
  };

  // Handle invalid configuration by clearing the code and showing a notification
  const handleInvalidConfig = () => {
    const clearedConfig = { ...config, code: "" };
    setConfig(clearedConfig);
    saveConfig(clearedConfig);
    notifications.createNotification(
      "Auto Sync Settings - Failed",
      "Unable to verify settings"
    );
  };

  // Effect to load existing settings into the config state when settings prop changes
  useEffect(() => {
    if (props.settings?.settings) {
      setConfig(JSON.parse(props.settings.settings));
    }
  }, [props.settings]);

  return {
    readOnly: props.readOnly,
    config,
    setConfig,
    validAPIKey,
    validAPISecret,
    setLinkCode,
    saveConfig,
    testConfig,
    connectionStatus,
  };
};
