import React, { useEffect } from "react";
import {
  BuySell,
  FormFrameworkComponentType,
  InstrumentType,
} from "../../../../types/Enums";
import FormBase from "../../../../components/forms/Form";
import { Box, VStack } from "@chakra-ui/react";
import { useAtomValue } from "jotai";
import { AccountAtom, TransactionsAtom } from "../../../../store";
import { compilePositionsForMe, getCurrencies } from "../../../../api";
import { createTrade } from "../../../../api/Trades";
import { useTransactions } from "../../../../hooks/useTransactions";
import { usePositions } from "../../../../hooks";
import { Currency } from "../../../../types/Currencies";
import { delay } from "../../../../utils";

interface AddEquityFormController {
  isLoading: boolean;
  schema: any;
  submitPayload: (payload: any) => void;
}

export const useAddEquityFormController = (props: any) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const accountState = useAtomValue(AccountAtom);
  const transactionsState = useAtomValue(TransactionsAtom);
  const transactions = useTransactions();
  const positions = usePositions();
  const [schema, setSchema] = React.useState<any>({
    symbol: {
      value: "",
      label: "Symbol",
      error: "",
      type: FormFrameworkComponentType.InstrumentSearch,
      instrumentType: InstrumentType.Equity,
      required: true,
      enableCreateNew: true,
    },
    exchange: {
      value: "US",
      label: "Exchange",
      enum: ["US"],
    },
    tradeDate: {
      value: new Date().toISOString(),
      label: "Trade Date",
      error: "",
      type: FormFrameworkComponentType.Date,
      required: true,
    },
    tradeType: {
      value: "Equity",
    },
    accountId: {
      value: accountState?.selectedAccount?.id,
    },
    book: {
      value: "",
      label: "Book",
      error: "",
      type: FormFrameworkComponentType.Select,
      enum: transactionsState.books,
      required: false,
      enableCreateNew: true,
    },
    buySell: {
      value: "Buy",
      label: "Buy/Sell",
      error: "",
      type: FormFrameworkComponentType.Select,
      enum: Object.values(BuySell),
      required: true,
    },
    price: {
      value: 0,
      label: "Price",
      error: "",
      type: FormFrameworkComponentType.Number,
      required: true,
    },
    quantity: {
      value: 0,
      label: "Quantity",
      error: "",
      type: FormFrameworkComponentType.Number,
      required: true,
    },
    currency: {
      value: "USD",
      label: "Currency",
      error: "",
      type: FormFrameworkComponentType.Select,
      required: true,
      enum: Object.values(Currency),
      enableCreateNew: false,
    },
    fees: {
      value: 0,
      label: "Fees",
      error: "",
      type: FormFrameworkComponentType.Number,
      required: false,
    },
    commission: {
      value: 0,
      label: "Commission",
      error: "",
      type: FormFrameworkComponentType.Number,
      required: false,
    },
    notes: {
      value: "",
      label: "Notes",
      error: "",
      type: FormFrameworkComponentType.TextArea,
      required: false,
    },
  });

  const submitPayload = async (payload: any) => {
    setIsLoading(true);
    const parsePayloadToJustValues = Object.keys(payload).reduce((acc, key) => {
      if (key === "symbol") {
        acc["symbol"] = payload[key].value.value;
        acc["exchange"] = payload[key].value.exchange;
        return acc;
      }
      acc[key] = payload[key].value;
      return acc;
    }, {});

    const Trade = {
      ...parsePayloadToJustValues,
      tradeType: "Equity",
      grossProceeds: schema.price.value * schema.quantity.value,
      netProceeds:
        schema.price.value * schema.quantity.value -
        schema.fees.value -
        schema.commission.value,
      currency: schema.currency.value,
    };

    const createdTrade = await createTrade(
      Trade,
      accountState?.selectedAccount?.id
    );

    if (createdTrade) {
      if (createdTrade.success === true) {
        await compilePositionsForMe(accountState?.selectedAccount?.id);
        delay(1000);
        await transactions.getTradesAndCashflows(
          accountState?.selectedAccount?.id
        );
        await positions.fetchPositions(accountState?.selectedAccount?.id);

        props.onComplete();
      } else {
        const errors = createdTrade.errors;
        const newSchema = { ...schema };
        Object.keys(errors).forEach((key) => {
          newSchema[key].error = errors[key];
        });
        setSchema(newSchema);

        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    schema,
    submitPayload,
  } as AddEquityFormController;
};
