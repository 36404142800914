import { Box } from "@chakra-ui/react";
import { NavContent } from "./NavContent";
import React from "react";

export const NavBar = (props: any) => {
  return (
    <Box pb="60px">
      <Box
        as="header"
        position="relative"
        zIndex="10"
        pos="fixed"
        w="full"
        bg="var(--bg)"
      >
        <Box
          as="nav"
          aria-label="Main navigation"
          maxW="8xl"
          mx="auto"
          px={{ base: "6", md: "8" }}
        >
          <NavContent.Mobile display={{ base: "flex", lg: "none" }} />
          <NavContent.Desktop display={{ base: "none", lg: "flex" }} />
        </Box>
      </Box>
    </Box>
  );
};

export default NavBar;
