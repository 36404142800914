import { APIEndpoint } from ".";
import { DashboardConfiguration } from "../types/DashboardConfiguration";

export const createDashboardConfiguration = async (
  accountId: string,
  name: string
) => {
  const token = localStorage.getItem("token");

  return fetch(`${APIEndpoint}/dashboard/layouts`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token || ""}`,
      AccountId: accountId,
    },
    body: JSON.stringify({
      name,
    }),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error creating layout:", error));
};

export const getSpecificLayout = async (
  layoutId: number,
  accountId: number
) => {
  const token = localStorage.getItem("token");

  return fetch(`${APIEndpoint}/dashboard/layouts/${layoutId}`, {
    method: "GET",
    headers: {
      Authorization: `${token || ""}`,
      AccountId: accountId as any,
    },
  })
    .then((response) => response.json())
    .catch((error) =>
      console.error("Error getting layout for account:", error)
    );
};

export const getAllLayoutsForUser = async (): Promise<
  DashboardConfiguration[]
> => {
  const token = localStorage.getItem("token");
  const result = await fetch(`${APIEndpoint}/dashboard/layouts`, {
    method: "GET",
    headers: {
      Authorization: token || "",
    },
  });

  if (result.ok) {
    const data = await result.json();
    if (data.success && data.configurations) {
      return data.configurations.map((x: DashboardConfiguration) => {
        return x as DashboardConfiguration;
      });
    }
  }

  return [];
};

export const updateLayout = async (layoutId: number, layoutObj: any) => {
  const layout = layoutObj.layout;
  const strippedLayout = {
    ...layout,
    lg: layout.lg.map((x: any) => {
      return {
        ...x,
        data: undefined,
      };
    }),
    md: layout.md.map((x: any) => {
      return {
        ...x,
        data: undefined,
      };
    }),
    sm: layout.sm.map((x: any) => {
      return {
        ...x,
        data: undefined,
      };
    }),
    xs: layout.xs.map((x: any) => {
      return {
        ...x,
        data: undefined,
      };
    }),
    xxs: layout.xxs.map((x: any) => {
      return {
        ...x,
        data: undefined,
      };
    }),
  };

  const token = localStorage.getItem("token");
  return fetch(`${APIEndpoint}/dashboard/layouts/${layoutId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token || ""}`,
    },
    body: JSON.stringify({ layout: strippedLayout }),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error updating layouts:", error));
};

export const _updateLayoutName = async (layoutId: number, name: string) => {
  const token = localStorage.getItem("token");
  return fetch(`${APIEndpoint}/dashboard/layouts/${layoutId}/name`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token || ""}`,
    },
    body: JSON.stringify({ name }),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error updating layout name:", error));
};

export const _deleteLayout = async (layoutId: number) => {
  const token = localStorage.getItem("token");
  return fetch(`${APIEndpoint}/dashboard/layouts/${layoutId}`, {
    method: "DELETE",
    headers: {
      Authorization: `${token || ""}`,
    },
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error deleting layout:", error));
};
