import {
  Box,
  Heading,
  HStack,
  Icon,
  Image,
  Link,
  ListItem,
  OrderedList,
  Stack,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { Component } from "react";
import { Panel } from "../../../../../components/design_library";
import React from "react";
import { ExternalLinkIcon, InfoOutlineIcon } from "@chakra-ui/icons";

export default class GenericInstructions extends Component {
  render() {
    return (
      <Box p={6} maxW="600px" mx="auto">
        <VStack align="start" spacing={4}>
          <Text fontSize="xl" fontWeight="bold">
            Instructions: Custom Trade Upload Spreadsheet
          </Text>

          <Text>
            {" "}
            Start by downloading the template file and filling in the columns.
            There are 2 examples here:
          </Text>
          <HStack w="full">
            <Link
              href="https://cdn.wealthbee.io/main/app/uploads/template/example_generic_upload_1.csv"
              isExternal
              color="var(--accent)"
              mr="20px"
            >
              Template 1 <ExternalLinkIcon mx="2px" />
            </Link>
            <Link
              href="https://cdn.wealthbee.io/main/app/uploads/template/example_generic_upload_2.csv"
              isExternal
              color="var(--accent)"
            >
              Template 2 <ExternalLinkIcon mx="2px" />
            </Link>
          </HStack>
          <Box>
            <HStack align="start">
              <Text fontWeight="600" pb="3px">
                1. Type
              </Text>
            </HStack>
            <Text>
              Specify the type of trade. Use "Option" for options and "Equity"
              for stocks.
            </Text>
          </Box>

          <Box>
            <HStack align="start">
              <Text fontWeight="600" pb="3px">
                2. Trade Date
              </Text>
            </HStack>
            <Text>
              Enter the date the trade was executed (e.g., 01/31/2024).
            </Text>
          </Box>

          <Box>
            <HStack align="start">
              <Text fontWeight="600" pb="3px">
                3. Instrument
              </Text>
            </HStack>
            <Text>
              Enter the ticker symbol for the instrument, like SPY or AAPL.
            </Text>
          </Box>

          <Box>
            <HStack align="start">
              <Text fontWeight="600" pb="3px">
                4. Expiry (Options only)
              </Text>
            </HStack>
            <Text>
              If the trade is an option, specify the expiration date. Leave
              blank for equities.
            </Text>
          </Box>

          <Box>
            <HStack align="start">
              <Text fontWeight="600" pb="3px">
                5. Strike (Options only)
              </Text>
            </HStack>
            <Text>
              Enter the strike price for options. Skip this field for equities.
            </Text>
          </Box>

          <Box>
            <HStack align="start">
              <Text fontWeight="600" pb="3px">
                6. Put / Call (Options only)
              </Text>
            </HStack>
            <Text>Select "Put" or "Call" if the trade is an option.</Text>
          </Box>

          <Box>
            <HStack align="start">
              <Text fontWeight="600" pb="3px">
                7. Buy / Sell
              </Text>
            </HStack>
            <Text>Specify if the trade is a "Buy" or "Sell".</Text>
          </Box>

          <Box>
            <HStack align="start">
              <Text fontWeight="600" pb="3px">
                8. Quantity
              </Text>
            </HStack>
            <Text>Provide the quantity of shares or contracts.</Text>
          </Box>

          <Box>
            <HStack align="start">
              <Text fontWeight="600" pb="3px">
                9. Price
              </Text>
            </HStack>
            <Text>
              Specify the price per unit (e.g., per share or contract).
            </Text>
          </Box>

          <Box>
            <HStack align="start">
              <Text fontWeight="600" pb="3px">
                10. Fees
              </Text>
            </HStack>
            <Text>Enter any fees associated with this trade.</Text>
          </Box>

          <Box>
            <HStack align="start">
              <Text fontWeight="600" pb="3px">
                11. Multiplier
              </Text>
            </HStack>
            <Text>Use "100" for options, or "1" for equities.</Text>
          </Box>

          <Box>
            <HStack align="start">
              <Text fontWeight="600" pb="3px">
                12. Book
              </Text>
            </HStack>
            <Text>Enter a name for the book or group (optional).</Text>
          </Box>

          <Box>
            <HStack align="start">
              <Text fontWeight="600" pb="3px">
                13. Position Group
              </Text>
            </HStack>
            <Text>Enter a grouping category for position management.</Text>
          </Box>

          <Box>
            <HStack align="start">
              <Text fontWeight="600" pb="3px">
                14. Strategy
              </Text>
            </HStack>
            <Text>
              Specify the strategy, like "Puts", "Calls", or "Iron Condor".
            </Text>
          </Box>

          <Box>
            <HStack align="start">
              <Text fontWeight="600" pb="3px">
                15. Notes
              </Text>
            </HStack>
            <Text>Add any relevant notes about the trade (optional).</Text>
          </Box>

          <Box>
            <HStack align="start">
              <Text fontWeight="600" pb="3px">
                16. Currency
              </Text>
            </HStack>
            <Text>Enter the currency (e.g., "USD").</Text>
          </Box>

          <Box>
            <HStack align="start">
              <Text fontWeight="600" pb="3px">
                17. Exchange
              </Text>
            </HStack>
            <Text>Enter the exchange code (e.g., "US" for the US market).</Text>
          </Box>
        </VStack>
      </Box>
    );
  }
}
