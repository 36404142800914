import { Box, HStack } from "@chakra-ui/react";
import moment from "moment";
import Notes from "../../../features/notes/Notes";
import { calculateDaysUntilExpiry } from "../utils/calculateDaysUntilExpiry";
import { FastTag } from "../../design_library";
import React from "react";

export const TradeDetailsRender = (params: any, page: string) => {
  let trade = params?.node?.data;
  let instrument = trade?.instrument;
  if (!instrument) {
    return <></>;
  }
  let daysUntilExpiry = calculateDaysUntilExpiry(instrument);

  return (
    <HStack
      fontWeight={400}
      justifyContent={"space-between"}
      textAlign={"right"}
      color={params.data.quantity === 0 ? "var(--light-gray)" : "var(--white)"}
    >
      {page === "Positions" && (
        <FastTag
          h="24px"
          backgroundColor={
            params.node.data.quantity === 0
              ? "var(--trade-closed)"
              : "var(--trade-open)"
          }
        >
          {params.node.data.quantity === 0 ? "Closed" : "Open"}
        </FastTag>
      )}
      <FastTag h="24px">{params.node.data.quantity}</FastTag>
      {instrument.strike ? (
        <>
          <FastTag
            size="sm"
            h="24px"
            backgroundColor={
              daysUntilExpiry > 0 ? "var(--trade-open)" : "var(--trade-closed)"
            }
          >
            {moment(instrument.expiry).format("MMM Do")} (
            {daysUntilExpiry >= 0
              ? daysUntilExpiry.toFixed(0) + "d"
              : "Expired"}
            )
          </FastTag>

          <FastTag h="24px">{instrument.strike?.toFixed(2)}</FastTag>
          <FastTag
            h="24px"
            backgroundColor={`var(--trade-${instrument.putCall.toLowerCase()})`}
          >
            {instrument?.putCall}
          </FastTag>
        </>
      ) : (
        <FastTag h="24px" backgroundColor="var(--trade-stock)">
          {instrument?.instrumentType}
        </FastTag>
      )}
      <FastTag h="24px" p={0}>
        <Notes tags={trade.tags} notes={trade.notes} />
      </FastTag>
    </HStack>
  );
};
