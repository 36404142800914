import React from "react";
import { TradeEx, Position, FifoEntry } from "../../types";
import {
  Box,
  Flex,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import moment from "moment";
import Big from "big.js";
import convertSymbolToReadable from "../grid/utils/convertSymbolToReadable";
import currencyFormatter from "currency-formatter";
import { getPrecision } from "../../utils";

interface PositionAuditLogProps {
  trades?: TradeEx[];
  data: Position;
}

export const PositionAuditLog = (props: PositionAuditLogProps) => {
  const buildExplain = (
    entry: FifoEntry,
    trade: TradeEx,
    openingTrade?: TradeEx
  ) => {
    if (entry && entry.pnl && openingTrade) {
      return (
        <Flex direction="column">
          <Text mb={2}>
            Claimed {entry.quantity} from{" "}
            {`${moment(openingTrade.tradeDate).format("DD-MMM-yyyy")} (${
              openingTrade.brokerTradeId
            })`}
          </Text>
          <Text mb={2}>
            {`Price Diff = 
             ${new Big(entry.openingPrice)
               .minus(entry.closingPrice!)
               .toNumber()} (
            ${entry.openingPrice} - ${entry.closingPrice!})`}
          </Text>
          <Text>
            {`P&L  (${entry.quantity} * ${new Big(entry.openingPrice)
              .minus(entry.closingPrice!)
              .toNumber()} * ${openingTrade.instrument?.multiplier ?? 1}) = ${
              entry.pnl
            }`}
          </Text>
        </Flex>
      );
    }
    return <></>;
  };

  const buildRows = () => {
    const result: React.ReactNode[] = [];
    const fifoEntries = props.data?.audit?.fifo;
    const trades = props.trades;
    let runningPnL = 0;
    if (fifoEntries && trades) {
      let previousTrade: TradeEx | undefined = undefined;
      for (let i = 0; i < fifoEntries.length; i++) {
        const entry = fifoEntries[i];

        const price = entry.closingPrice ?? entry.openingPrice;

        // get trade and corresponding opening trade
        const currentTrade =
          trades.find((t) => t.id === entry.trade) ?? ({} as TradeEx);
        const openingTrade = trades.find((t) => t.id === entry.fromTrade);

        if (entry.pnl) {
          runningPnL = new Big(runningPnL).plus(entry.pnl).toNumber();
        }

        if (
          currentTrade &&
          currentTrade.brokerTradeId !== previousTrade?.brokerTradeId
        ) {
          result.push(
            <Tr>
              <Td>
                <Tooltip label={currentTrade.brokerTradeId}>
                  <Text>{currentTrade.brokerTradeId.substring(0, 10)}...</Text>
                </Tooltip>
              </Td>
              <Td>
                {moment.utc(currentTrade.tradeDate).format("DD-MMM-YYYY hh:mm")}
              </Td>
              <Td>
                {convertSymbolToReadable(currentTrade.instrument?.symbol)}
              </Td>
              <Td isNumeric>{currentTrade.quantity}</Td>
              <Td isNumeric>
                {currencyFormatter.format(price, {
                  code: "USD",
                  precision: getPrecision(price) ?? 2,
                })}
              </Td>
              <Td isNumeric>
                {currencyFormatter.format(currentTrade.netProceeds, {
                  code: "USD",
                  precision: 2,
                })}
              </Td>
              <Td isNumeric>{entry.runningQuantity}</Td>
              <Td>{buildExplain(entry, currentTrade, openingTrade)}</Td>
              <Td isNumeric>
                {entry.pnl ? (
                  currencyFormatter.format(entry.pnl, {
                    code: "USD",
                    precision: 2,
                  })
                ) : (
                  <></>
                )}
              </Td>
              <Td isNumeric>
                {currencyFormatter.format(runningPnL, {
                  code: "USD",
                  precision: 2,
                })}
              </Td>
            </Tr>
          );
        } else {
          result.push(
            <Tr>
              <Td colSpan={6}></Td>
              <Td isNumeric>{entry.runningQuantity}</Td>
              <Td>{buildExplain(entry, currentTrade!, openingTrade)}</Td>
              <Td isNumeric>
                {entry.pnl ? (
                  currencyFormatter.format(entry.pnl, {
                    code: "USD",
                    precision: 2,
                  })
                ) : (
                  <></>
                )}
              </Td>
              <Td isNumeric>
                {currencyFormatter.format(runningPnL, {
                  code: "USD",
                  precision: 2,
                })}
              </Td>
            </Tr>
          );
        }
        previousTrade = currentTrade;
      }
    }
    return result;
  };

  return (
    <Flex
      direction="column"
      p={2}
      height="full"
      bg="var(--bg)"
      overflow={"scroll"}
    >
      <Flex direction="column">
        <TableContainer my={1}>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Field</Th>
                <Th>Log</Th>
                <Th>Equation</Th>
              </Tr>
            </Thead>
            <Tbody>
              {props.data?.audit?.log?.map((log, index) => (
                <Tr key={index}>
                  <Td>{log.field}</Td>
                  <Td>{log.message}</Td>
                  <Td>{log.equation}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
      <Flex direction="column" mt="15px" mb="100px">
        <HStack>
          <Text pl="4">Position Computation</Text>
          <Text fontSize="xs" opacity="0.8">
            (P&L figures below use the adjusted price which is the trade price
            with the costs applied)
          </Text>
        </HStack>
        <TableContainer my={2}>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th width="220px">Id</Th>
                <Th>Timestamp</Th>
                <Th>Symbol</Th>
                <Th isNumeric>Quantity</Th>
                <Th isNumeric>Adjusted Price</Th>
                <Th isNumeric>Net Proceeds</Th>
                <Th isNumeric>Running Qty</Th>
                <Th>P&L Explain</Th>
                <Th isNumeric>Realized P&L</Th>
                <Th isNumeric>Running Realized P&L</Th>
              </Tr>
            </Thead>
            <Tbody>{buildRows()}</Tbody>
          </Table>
        </TableContainer>
      </Flex>
    </Flex>
  );
};
