import { ColDef } from "ag-grid-community";
import { ColumnTypeEnum } from "./columnTypes";
import { defaultColumn } from "./defaultColumn";
import {
  ComplexColumn,
  CurrencyColumn,
  CurrencyWithPercentage,
  CurrencyWithPercentageOfStock,
  DateColumn,
  IndicatorColumn,
  PercentageColumn,
  PillColumn,
  SymbolColumn,
  TagsColumn,
  TextColumn,
  TradeDetailsRender,
} from "../renderers";
import { dateComparator } from "../comparators";
import { StockLogoRender } from "../renderers/StockLogoRender";
import { PriceDetailRender } from "../renderers/PriceDetailRender";

export const defaultAutoGroupColumnDef: ColDef = {
  cellRenderer: "agGroupCellRenderer",
  pinned: "left",
};

// base column function
export const createColumn = (
  headerName: string,
  field: string,
  columnType: ColumnTypeEnum = ColumnTypeEnum.Text,
  colDef?: ColDef
): ColDef => {
  let col: ColDef = { ...defaultColumn, headerName, field };

  col.filter = true;
  col.resizable = true;
  col.sortable = true;
  col.minWidth = 120;

  switch (columnType) {
    case ColumnTypeEnum.Complex:
      col.type = "complex";
      col.cellRenderer = ComplexColumn;
      col.aggFunc = "distinct";
      break;
    case ColumnTypeEnum.Numeric:
      col.filter = "agNumberColumnFilter";
      col.aggFunc = "sum";
      break;
    case ColumnTypeEnum.Date:
      col.type = "date";
      col.cellRenderer = DateColumn;
      col.filter = false;
      col.filter = "agDateColumnFilter";
      col.aggFunc = "distinct";
      col.comparator = dateComparator;
      break;
    case ColumnTypeEnum.Boolean:
      col.type = "bool";
      col.aggFunc = "distinct";
      break;
    case ColumnTypeEnum.Currency:
      col.type = "currency";
      col.cellRenderer = CurrencyColumn;
      col.filter = "agNumberColumnFilter";
      col.aggFunc = "sum";
      break;
    case ColumnTypeEnum.CurrencyWithPercentage:
      col.type = "currency";
      col.cellRenderer = CurrencyWithPercentage;
      col.filter = "agNumberColumnFilter";
      col.aggFunc = "sum";
      break;
    case ColumnTypeEnum.CurrencyWithPercentageOfStock:
      col.type = "currency";
      col.cellRenderer = CurrencyWithPercentageOfStock;
      col.filter = "agNumberColumnFilter";
      col.aggFunc = "sum";
      break;
    case ColumnTypeEnum.Percentage:
      col.type = "percentage";
      col.cellRenderer = PercentageColumn;
      col.filter = "agNumberColumnFilter";
      col.aggFunc = "sum";
      break;
    case ColumnTypeEnum.Pill:
      col.type = "pill";
      col.cellRenderer = PillColumn;
      col.aggFunc = "distinct";
      break;
    case ColumnTypeEnum.Symbol:
      col.type = "symbol";
      col.cellRenderer = SymbolColumn;
      col.aggFunc = "distinct";
      break;
    case ColumnTypeEnum.Indicator:
      col.type = "indicator";
      col.cellRenderer = IndicatorColumn;
      col.aggFunc = "distinct";
      break;
    case ColumnTypeEnum.Tags:
      col.type = "tags";
      col.cellRenderer = TagsColumn;
      col.aggFunc = "distinct";
      break;
    case ColumnTypeEnum.Logo:
      col.type = "logo";
      col.cellRenderer = StockLogoRender;
      col.aggFunc = "distinct";
      break;
    case ColumnTypeEnum.TradeDetail:
      col.type = "logo";
      col.cellRenderer = TradeDetailsRender;
      col.aggFunc = "distinct";
      break;
    case ColumnTypeEnum.PriceDetail:
      col.type = "price";
      col.cellRenderer = PriceDetailRender;
      col.aggFunc = "distinct";
      break;
    default:
      col.type = "text";
      col.cellRenderer = TextColumn;
      col.aggFunc = "distinct";
      break;
  }

  return { ...col, ...colDef };
};

export const createGroupColumn = (
  headerName: string,
  field: string,
  columnType: ColumnTypeEnum = ColumnTypeEnum.Text,
  colDef?: ColDef,
  disableGrouping?: boolean
): ColDef => {
  let col = createColumn(headerName, field, columnType, colDef);
  col.suppressMovable = false;
  col.valueFormatter = (params) => {
    if (
      params.value === 0 ||
      params.value === undefined ||
      params.value === null ||
      params.value === ""
    ) {
      return "";
    }
    return params.value;
  };

  switch (columnType) {
    case ColumnTypeEnum.Complex:
      break;
    case ColumnTypeEnum.Numeric:
      col.aggFunc = "sum";
      break;
    case ColumnTypeEnum.Date:
      col.aggFunc = "distinct";
      break;
    case ColumnTypeEnum.Boolean:
      break;
    case ColumnTypeEnum.Currency:
      break;
    case ColumnTypeEnum.Percentage:
      break;
    case ColumnTypeEnum.Pill:
      break;
    case ColumnTypeEnum.Symbol:
      break;
    case ColumnTypeEnum.Indicator:
      break;
    case ColumnTypeEnum.Tags:
      break;
    case ColumnTypeEnum.Logo:
      break;
    case ColumnTypeEnum.PriceDetail:
      break;
    case ColumnTypeEnum.CurrencyWithPercentage:
      break;
    default:
      col.aggFunc = "distinct";
      break;
  }
  col.rowDrag = colDef?.rowDrag ? true : false;

  col.enableRowGroup = disableGrouping ? false : true;

  return { ...col, ...colDef };
};
