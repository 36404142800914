import React from "react";
import { BiBarChart, BiLineChart, BiTable } from "react-icons/bi";
import { FaHashtag } from "react-icons/fa";
import { MdCompare } from "react-icons/md";
import { RiCashLine } from "react-icons/ri";
import { SiElasticstack } from "react-icons/si";
import { TbChartInfographic } from "react-icons/tb";

export const InsightTypeIcon = ({ type }) => {
  return (
    <>
      {type === "Number" && <FaHashtag fontSize="20px" />}
      {type === "Barchart" && <BiBarChart fontSize="20px" />}
      {type === "Comparison" && <MdCompare fontSize="20px" />}
      {type === "Linechart" && <BiLineChart fontSize="20px" />}
      {type === "Treemap" && <SiElasticstack fontSize="20px" />}
      {type === "ProfitAndLossLineChart" && <RiCashLine fontSize="20px" />}
      {type === "Dendrogram" && <TbChartInfographic fontSize="20px" />}
      {type === "Table" && <BiTable fontSize="20px" />}
    </>
  );
};
