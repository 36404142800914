export const distinctValue = (props: any) => {
  if (props.colDef?.type === "text" || props.colDef?.type === "dateColumn") {
    if (props.values && props.values.length > 0) {
      let value = props.values[0] ?? "";

      const commonValues = Array.from(new Set(props.values));

      // idea here is that if all values are the same, return that value
      // else if there are multiple different values, return "*"
      if (commonValues.length === 1) {
        return commonValues[0];
      } else if (commonValues.length > 1) {
        return "*";
      }
    }
  }
  return "";
};
