import React from "react";
import { Stack, Text } from "@chakra-ui/react";
import ReactDatePicker from "react-datepicker";
import { Required } from "./Required";

import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { LeftArrowIcon, RightArrowIcon } from "../../design_library";
export const DatePicker = (props: {
  label: string;
  value: string;
  field: string;
  required: boolean;
  error: string;
  onChange: (field: string, value: string) => void;
}) => {
  return (
    <Stack spacing="4" mt="2" mb="1">
      <Text>
        {props.label}
        {props.required && <Required />}
      </Text>
      <ReactDatePicker
        preventOpenOnFocus={true}
        className={"react-datepicker"}
        renderCustomHeader={({
          date,
          monthDate,
          customHeaderCount,
          decreaseMonth,
          increaseMonth,
        }) => {
          return (
            <div>
              <button
                aria-label="Previous Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--previous"
                }
                onClick={decreaseMonth}
              >
                <LeftArrowIcon className={"react-datepicker-icon-left"} />
              </button>
              <span className="react-datepicker__current-month">
                {moment.utc(date).format("MMMM YYYY")}
              </span>
              <button
                aria-label="Next Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--next"
                }
                onClick={increaseMonth}
              >
                <RightArrowIcon className={"react-datepicker-icon-right"} />
              </button>
            </div>
          );
        }}
        selected={props.value}
        onChange={(date) => props.onChange(props.field, date)}
        dateFormat="MM/dd/yyyy"
      />

      <Text color="red.500">{props.error}</Text>
    </Stack>
  );
};
