import { atom } from "jotai";

export interface AppState {
  initialised: boolean;
  APIEndpoint: string;
  APIEndpointProcessor: string;
  stripeKey: string;
  myUrl: string;
  loading: boolean;
  username?: string;
  authed: boolean;
  errorMessage?: string;
  user?: any;
  subscriptionActive: boolean;
  gridTheme: string;
  role: string;
  stripeId?: string;
  featureFlags?: string[];
}

const buildInitialState = (): AppState => {
  const state: any = {
    initialised: false,
    loading: false,
    authed: false,
    subscriptionActive: false,
    role: "user",
    featureFlags: [],
  };

  if (
    process.env.NODE_ENV === "dev" ||
    process.env.NODE_ENV === "development"
  ) {
    console.log("Dev environment");
    state.stripeKey =
      "pk_test_51PNC6qD102TPMwRfR398rDymChPOYuWJ9YiYdmw3Q1KjbK5Ug2GgQg2ghzaLVeQMSy6oF8zDpGiOF217b42CPm8T00Q5NINlm6";
  } else {
    state.stripeKey =
      "pk_live_51PNC6qD102TPMwRfO3f5E9H6NcfFxP4W2inE2NlS1X5KuVvQZOtSDUkYkUTwfW0zExYQB95xmKpP0zTeQNYlgkYU00p0BGXTcs";
  }

  return state;
};

export const AppStateAtom = atom<AppState>(buildInitialState());
