import { useAtom } from "jotai";
import { FilterStateAtom } from "../../../store/FilterStateAtom";
import { useCallback, useEffect, useRef, useState } from "react";
import { updateUserFilter } from "../../../api";
import { PositionStatus, UserFilter } from "../../../types";

interface UseFilterController {
  setSelectedUnderlying: (underlying: string) => void;
  setUnderlyings: (underlyings: string[]) => void;
  underlyings: string[];
  setPositionStatus: (status: string) => void;
  positionStatus: PositionStatus;
  setSelectedTag: (underlying: string) => void;
  tags: string[];
  selectedTag: string;
  setTags: (tags: string[]) => void;
  selectedUnderlying: string;
  toDate: Date;
  fromDate: Date;
  setToDate: (date: Date) => void;
  setFromDate: (date: Date) => void;
  positionGroups: string[];
  setPositionGroups: (groups: string[]) => void;
  books: string[];
  setBooks: (books: string[]) => void;
  setSelectedPositionGroup: (group: string) => void;
  setSelectedBook: (book: string) => void;
  selectedPositionGroup: string;
  selectedBook: string;
  selectedStrategy: string;
  setSelectedStrategy: (strategy: string) => void;
  baseStrategies: string[];
  tradedStrategies: string[];
  setBaseStrategies: (strategies: string[]) => void;
  setTradedStrategies: (strategies: string[]) => void;
  modified: boolean;
  saveFilter: () => void;
  resetFilter: () => void;
}

export const useFilterController = (
  close: () => void,
  filter?: UserFilter
): UseFilterController => {
  const [filters, setFilters] = useAtom(FilterStateAtom);

  const initialised = useRef<boolean>(false);
  const originalFilter = useRef<string>();

  const [currentFilter, setCurrentFilter] = useState<UserFilter | undefined>();

  const [modified, setModified] = useState<boolean>(false);

  const resetFilter = useCallback(async () => {
    if (currentFilter) {
      const updatedFilter = {
        ...currentFilter,
        filterConfig: {
          underlyings: [],
          tags: [],
          positionGroups: [],
          strategies: [],
          books: [],
        },
      } as UserFilter;

      loadFilterSettings(updatedFilter);

      await updateUserFilter(updatedFilter);
    }
  }, [currentFilter]);

  const saveFilter = useCallback(async () => {
    if (currentFilter && modified) {
      await updateUserFilter(currentFilter);

      // ensure that the filter is updated in the filter state
      setFilters((prevState) => {
        return {
          ...prevState,
          selectedUnderlying: currentFilter.filterConfig?.underlyings?.length
            ? currentFilter.filterConfig?.underlyings[0]
            : undefined,
          selectedTag: currentFilter.filterConfig?.tags?.length
            ? currentFilter.filterConfig?.tags[0]
            : undefined,
          // toDate: moment().add(1, "day").toDate(),
          // fromDate: moment().subtract(5, "year").toDate(),
          selectedPositionGroup: currentFilter.filterConfig?.positionGroups
            ?.length
            ? currentFilter.filterConfig?.positionGroups[0]
            : undefined,
          selectedBook: currentFilter.filterConfig?.books?.length
            ? currentFilter.filterConfig?.books[0]
            : undefined,
          selectedStrategy: currentFilter.filterConfig?.strategies?.length
            ? currentFilter.filterConfig?.strategies[0]
            : undefined,
        };
      });
    }
    close();
  }, [currentFilter, modified]);

  const setSelectedUnderlying = async (value: string) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        selectedUnderlying:
          Array.isArray(value) && value.length > 0 ? value[0] : value,
      };
    });
  };

  const setFromDate = async (date: Date) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        fromDate: date,
      };
    });
  };

  const setBooks = async (books: string[]) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        books: ["All", ...books],
      };
    });
  };

  const setToDate = async (date: Date) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        toDate: date,
      };
    });
  };

  const setUnderlyings = async (underlyings: string[]) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        underlyings: ["All", ...underlyings],
      };
    });
  };

  const setPositionStatus = async (status: string) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        status: status,
      } as any;
    });
  };

  const setPositionGroups = async (groups: string[]) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        positionGroups: ["All", ...groups],
      };
    });
  };

  const setSelectedPositionGroup = async (value: string) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        selectedPositionGroup:
          Array.isArray(value) && value.length > 0 ? value[0] : value,
      };
    });
  };

  const setSelectedBook = async (value: string) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        selectedBook:
          Array.isArray(value) && value.length > 0 ? value[0] : value,
      };
    });
  };

  const setSelectedTag = async (value: string) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        selectedTag:
          Array.isArray(value) && value.length > 0 ? value[0] : value,
      };
    });
  };

  const setTags = async (tags: string[]) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        tags: [...tags],
      };
    });
  };

  const setSelectedStrategy = async (value: string) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        selectedStrategy:
          Array.isArray(value) && value.length > 0 ? value[0] : value,
      };
    });
  };

  const setBaseStrategies = async (strategies: string[]) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        baseStrategies: ["All", ...strategies],
      };
    });
  };

  const setTradedStrategies = async (strategies: string[]) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        tradedStrategies: ["All", ...strategies],
      };
    });
  };

  const loadFilterSettings = useCallback((filterToApply: UserFilter) => {
    originalFilter.current = JSON.stringify(filterToApply);
    setCurrentFilter(filterToApply);
    setSelectedUnderlying(
      (filterToApply.filterConfig?.underlyings?.length ?? 0) > 0
        ? filterToApply.filterConfig?.underlyings[0] ?? ""
        : ""
    );
    setSelectedTag(
      (filterToApply.filterConfig?.tags?.length ?? 0) > 0
        ? filterToApply.filterConfig?.tags[0] ?? ""
        : ""
    );
    setSelectedPositionGroup(
      (filterToApply.filterConfig?.positionGroups?.length ?? 0) > 0
        ? filterToApply.filterConfig?.positionGroups[0] ?? ""
        : ""
    );
    setSelectedBook(
      (filterToApply.filterConfig?.books?.length ?? 0) > 0
        ? filterToApply.filterConfig?.books[0] ?? ""
        : ""
    );
    setSelectedStrategy(
      (filterToApply.filterConfig?.strategies?.length ?? 0) > 0
        ? filterToApply.filterConfig?.strategies[0] ?? ""
        : ""
    );
  }, []);

  useEffect(() => {
    if (initialised.current) {
      setCurrentFilter((prevState) => {
        return {
          ...prevState,
          filterConfig: {
            underlyings: filters.selectedUnderlying
              ? [filters.selectedUnderlying]
              : [],
            tags: filters.selectedTag ? [filters.selectedTag] : [],
            positionGroups: filters.selectedPositionGroup
              ? [filters.selectedPositionGroup]
              : [],
            strategies: filters.selectedStrategy
              ? [filters.selectedStrategy]
              : [],
            books: filters.selectedBook ? [filters.selectedBook] : [],
          },
        } as UserFilter;
      });
    }
  }, [filters]);

  useEffect(() => {
    if (filter) {
      loadFilterSettings(filter);

      setTimeout(() => {
        initialised.current = true;
      }, 100);
    }
  }, [filter]);

  useEffect(() => {
    if (initialised.current) {
      if (
        originalFilter.current &&
        originalFilter.current !== JSON.stringify(currentFilter)
      ) {
        setModified(true);
      } else {
        setModified(false);
      }
    }
  }, [currentFilter]);

  return {
    selectedPositionGroup: filters.selectedPositionGroup,
    setSelectedPositionGroup,
    selectedBook: filters.selectedBook,
    setSelectedBook,
    setBooks,
    books: filters.books,
    setPositionGroups,
    positionGroups: filters.positionGroups,
    setSelectedTag,
    setTags,
    tags: filters.tags,
    setSelectedUnderlying,
    setUnderlyings,
    setPositionStatus,
    selectedTag: filters.selectedTag,
    positionStatus: filters.status,
    underlyings: filters.underlyings,
    selectedUnderlying: filters.selectedUnderlying,
    toDate: filters.toDate,
    fromDate: filters.fromDate,
    setToDate,
    setFromDate,
    selectedStrategy: filters.selectedStrategy,
    setSelectedStrategy,
    baseStrategies: (filters as any).baseStrategies,
    tradedStrategies: (filters as any).tradedStrategies,
    setBaseStrategies,
    setTradedStrategies,
    modified,
    saveFilter,
    resetFilter,
  };
};
