import {
  Flex,
  VStack,
  Text,
  HStack,
  Spacer,
  Tag,
  Divider,
  Skeleton,
  FormControl,
  FormLabel,
  Textarea,
  Box,
} from "@chakra-ui/react";
import { CreatableSelect } from "chakra-react-select";
import React, { useMemo } from "react";
import { Show, Spinner } from "../../components";
import { ExplainTradeProps, useExplainTrades } from "./useExplainTrades";
import { SecondaryButton } from "../../components/design_library";
import StarterKit from "@tiptap/starter-kit";
import { EditorProvider } from "@tiptap/react";
import { MenuBar } from "../../components/texteditor/MenuBar";
import { TagEntry } from "../../features/notes/TagEntry";
import { IoExpand, IoHelp } from "react-icons/io5";

export const ExplainTrade = (props: ExplainTradeProps) => {
  const controller = useExplainTrades(props);

  const bookOptions = useMemo(() => {
    return controller.books.map((item) => {
      return { value: item, label: item };
    });
  }, [controller.books]);

  const positionNames = useMemo(() => {
    return controller.positionGroups.map((item) => {
      return { value: item, label: item };
    });
  }, [controller.positionGroups]);

  const strategyOptions = useMemo(() => {
    return controller.strategies.map((item) => {
      return { value: item, label: item };
    });
  }, [controller.strategies]);

  const tagOptions = useMemo(() => {
    return controller.tags.map((item) => {
      return { value: item, label: item };
    });
  }, [controller.tags]);

  const extensions = [
    StarterKit.configure({
      heading: {
        levels: [1, 2, 3, 4, 5, 6],
      },
    }),
  ];

  return (
    <Flex w="full" p={2} background={"var(--bg)"}>
      <VStack alignItems="start" w="full">
        <HStack w="full" mt={1}>
          <Text
            fontFamily="Work Sans"
            fontSize="14px"
            fontWeight={500}
            align="left"
          >
            EXPLAIN TRADES
          </Text>

          <Show if={props.trades?.length > 0}>
            <Tag size="sm" colorScheme="orange" ml={4}>
              ({controller.nbrTrades}) Trades Selected
            </Tag>
          </Show>
        </HStack>

        {controller.nbrTrades == 0 && (
          <VStack mt="20px">
            <IoExpand size={50} />
            <Show if={props.trades?.length === 0}>
              <Tag size="sm" colorScheme="red" ml={4}>
                SELECT ONE OR MORE TRADES
              </Tag>
            </Show>
            <Text textAlign={"center"} px="10px">
              Use the checkboxes to the left to select trades, you can then
              'explain them' by assigning strategies, books, position names,
              tags, and notes.
            </Text>
          </VStack>
        )}
        <Skeleton
          overflow={"scroll"}
          isLoaded={controller.nbrTrades > 0}
          w="full"
          h="full"
          fadeDuration={0}
          speed={0}
          startColor="var(--bg)"
          overflowX={"hidden"}
          p="10px"
        >
          <Divider mt={1} />

          <FormControl mt="30px">
            <HStack my={1}>
              <FormLabel>Book</FormLabel>
              <Spacer />
              <SecondaryButton size="sm" onClick={() => controller.clearBook()}>
                Clear
              </SecondaryButton>
            </HStack>

            <CreatableSelect
              size="sm"
              tagVariant="solid"
              value={bookOptions.find(
                (x) => x.value === controller.selectedBook
              )}
              options={bookOptions}
              onChange={(e) => {
                if (e?.value) {
                  controller.setSelectedBook(e.value);
                } else {
                  controller.setSelectedBook(undefined);
                }
              }}
              isClearable={true}
            />
          </FormControl>

          <FormControl mt="30px">
            <HStack my={1}>
              <FormLabel>Position Name</FormLabel>
              <Spacer />
              <SecondaryButton
                size="sm"
                onClick={() => controller.clearPositionName()}
              >
                Clear
              </SecondaryButton>
            </HStack>
            <CreatableSelect
              size="sm"
              tagVariant="solid"
              value={positionNames.find(
                (x) => x.value === controller.selectedPositionGroup
              )}
              options={positionNames}
              onChange={(e) => {
                if (e?.value) {
                  controller.setSelectedPositionGroup(e.value);
                } else {
                  controller.setSelectedPositionGroup(undefined);
                }
              }}
              isClearable={true}
            />
          </FormControl>

          <FormControl mt="30px">
            <HStack my={1}>
              <FormLabel>Strategy</FormLabel>
              <Spacer />
              <SecondaryButton
                size="sm"
                onClick={() => controller.clearStrategy()}
              >
                Clear
              </SecondaryButton>
            </HStack>

            <CreatableSelect
              size="sm"
              tagVariant="solid"
              value={strategyOptions.find(
                (x) => x.value === controller.selectedStrategy
              )}
              options={strategyOptions}
              onChange={(e) => {
                if (e?.value) {
                  controller.setSelectedStrategy(e.value);
                } else {
                  controller.setSelectedStrategy(undefined);
                }
              }}
              isClearable={true}
            />
          </FormControl>

          <FormControl mt="30px">
            <HStack my={1}>
              <FormLabel>Tags</FormLabel>
              <Spacer />
              <SecondaryButton size="sm" onClick={() => controller.clearTags()}>
                Clear
              </SecondaryButton>
            </HStack>

            <CreatableSelect
              size="sm"
              tagVariant="solid"
              value={controller.selectedTags.map((item) => {
                return { value: item, label: item };
              })}
              placeholder="Specify Tags"
              isMulti={true}
              options={tagOptions}
              onChange={(e) => {
                const newtags = e.map((item: any) => item.value);
                controller.setSelectedTags(newtags);
              }}
              isClearable={true}
            />
          </FormControl>

          {/* NOTES */}
          <FormControl mt="30px">
            <HStack my={1}>
              <FormLabel>Notes</FormLabel>
              <Spacer />
              <SecondaryButton
                size="sm"
                onClick={() => controller.clearNotes()}
              >
                Clear
              </SecondaryButton>
            </HStack>

            {!controller.loading && (
              <EditorProvider
                slotBefore={
                  <Box mb="20px">
                    <MenuBar />
                  </Box>
                }
                extensions={extensions}
                content={`` + controller.notes}
                onUpdate={(e) => {
                  controller.setNotes(e.editor.getHTML());
                }}
                editorProps={{
                  attributes: {
                    class: "prose",
                  },
                }}
              ></EditorProvider>
            )}
            {controller.loading && (
              <VStack>
                <Spinner />
                <Text>Retrieving notes...</Text>
              </VStack>
            )}
          </FormControl>

          <HStack mt="30px">
            <SecondaryButton
              size="sm"
              colorScheme="green"
              w="full"
              isDisabled={
                !controller.selectedStrategy &&
                !controller.selectedBook &&
                !controller.selectedPositionGroup &&
                !controller.selectedTags.length &&
                !controller.notes
              }
              onClick={() => controller.saveChanges()}
            >
              Save Changes
            </SecondaryButton>
          </HStack>
        </Skeleton>
      </VStack>
    </Flex>
  );
};
