import React, { useState, useRef, useEffect } from "react";
import { VStack, Box } from "@chakra-ui/react";
import ChartStructure from "./ChartStructure";
import { ChartProperties, DataItem } from "../../../../../../types/Insight";
import NoDataAvailable from "../../../../../../components/insights/NoDataAvailable";

export interface ChartProps {
  data: DataItem[];
  chartProperties: ChartProperties;
  initialWidth?: number;
  initialHeight?: number;
}

const Chart = (props: ChartProps) => {
  const {
    data,
    chartProperties,
    initialWidth = 800,
    initialHeight = 400,
  } = props;

  const [containerWidth, setContainerWidth] = useState<number>(initialWidth);
  const [containerHeight, setContainerHeight] = useState<number>(initialHeight);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleLegendClick = (key: string) => {
    // setSelectedKeys((currentKeys) =>
    //   currentKeys.includes(key)
    //     ? currentKeys.filter((k) => k !== key)
    //     : [...currentKeys, key]
    // );
  };

  useEffect(() => {
    const handleResize = (entries: ResizeObserverEntry[]) => {
      if (!entries.length) return;
      const { width, height } = entries[0].contentRect;
      setContainerWidth(width);
      setContainerHeight(height);
    };

    const observer = new ResizeObserver((entries) => handleResize(entries));
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [data]);

  if (!chartProperties || !data) return <NoDataAvailable title="this chart" />;

  return (
    <VStack maxH="95%" spacing={4} align="start" ref={containerRef}>
      <Box p={4} overflow={"visible"}>
        <ChartStructure
          width={containerWidth}
          height={containerHeight}
          data={data}
          chartProperties={chartProperties}
          onLegendClick={handleLegendClick}
        />
      </Box>
    </VStack>
  );
};

export default Chart;
