import { Box, Center, Heading, List, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../homepage/footer/App";
import NavBar from "../homepage/menu/App";

const links = [
  "https://www.linkedin.com/company/wealthbee",
  "https://medium.com/@matthew4/top-investment-analytics-journaling-tools-in-2024-2025-69742b641877",
  "https://www.pinterest.co.uk/pin/844354630171383271/",
  "https://x.com/WealthBeeIo",
  "https://www.quora.com/How-do-you-track-stock-futures",
  "https://lockyer.notion.site/WealthBee-d3545d5886bc4134a556bcc553e9d6ec?pvs=74",
  "https://www.behance.net/gallery/206714611/WealthBee-UI?",
  "https://dribbble.com/shots/24771911-WealthBee-Trading-Journal?added_first_shot=true",
  "https://www.tumblr.com/wealthbee",
  "https://www.tumblr.com/wealthbee/760704414176722944/top-investment-analytics-journaling-tools-in?source=share",
  "https://ninjatools.info/ninjatrader/trading-journal.html",
];

export default function OnTheInternet() {
  return (
    <Box background={"var(--bg)"}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Links</title>
        <link rel="canonical" href="https://wealthbee.io/on-the-internet" />
      </Helmet>
      <NavBar />
      <Center
        maxW="lg"
        mx="auto"
        minH={"89vh"}
        alignItems={"flex-start"}
        mt="20px"
      >
        <Stack spacing="5" flex="1" px="20px">
          <Stack spacing="1">
            <Heading as="h1" size="lg" color="fg.emphasized">
              On the internet
            </Heading>
            <Text color="fg.muted" textStyle="sm">
              Here are some of the links to WealthBee on the internet
            </Text>
          </Stack>
          <List listStyleType="none">
            <Stack spacing="3" width="full">
              {links.map((link) => (
                <Box
                  w="full"
                  border="1px solid var(--light-gray)"
                  padding="10px"
                  borderRadius={"4px"}
                  as="a"
                  href={link}
                >
                  <Text key={link}>
                    {
                      link
                        .replace("https://", "")
                        .replace("www.", "")
                        .split("/")[0]
                        .split(".")[0]
                    }
                  </Text>
                  <Text key={link} mt="20px" fontSize="14px">
                    <a href={link} target="_blank" rel="noreferrer">
                      Visit article &rarr;
                    </a>
                  </Text>
                </Box>
              ))}
            </Stack>
          </List>
        </Stack>
      </Center>
      <Footer />
    </Box>
  );
}
