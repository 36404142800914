import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";
import { Box, HStack, Text, useColorMode } from "@chakra-ui/react";
import { CheckCircleIcon, CheckIcon, WarningTwoIcon } from "@chakra-ui/icons";
import { MdCancel } from "react-icons/md";
import { HiOutlinePresentationChartBar } from "react-icons/hi";
import { FaClosedCaptioning } from "react-icons/fa";
import { BiCart } from "react-icons/bi";
import { BsBarChartFill } from "react-icons/bs";
import { IoDocumentText } from "react-icons/io5";

export const EventCell = (props) => {
  const { colorMode } = useColorMode();
  return <Child color={colorMode} {...props} />;
};

class Child extends React.Component {
  render() {
    let {
      style,
      className,
      event,
      selected,
      isAllDay,
      onSelect,
      onDoubleClick,
      onKeyPress,
      localizer,
      continuesPrior,
      continuesAfter,
      accessors,
      getters,
      children,
      components: { event: Event, eventWrapper: EventWrapper },
      slotStart,
      slotEnd,
      ...props
    } = this.props;
    delete props.resizable;

    let title = accessors.title(event);
    let tooltip = accessors.tooltip(event);
    let end = accessors.end(event);
    let start = accessors.start(event);
    let allDay = accessors.allDay(event);

    let showAsAllDay =
      isAllDay ||
      allDay ||
      localizer.diff(start, localizer.ceil(end, "day"), "day") > 1;

    let userProps = getters.eventProp(event, start, end, selected);

    const content = (
      <div className="rbc-event-content" title={tooltip || undefined}>
        {Event ? (
          <Event
            event={event}
            continuesPrior={continuesPrior}
            continuesAfter={continuesAfter}
            title={title}
            isAllDay={allDay}
            localizer={localizer}
            slotStart={slotStart}
            slotEnd={slotEnd}
          />
        ) : (
          title
        )}
      </div>
    );

    if (this.props.event.type === "NOTE") {
      return (
        <EventWrapper {...this.props} type="date">
          <div
            {...props}
            tabIndex={0}
            style={{ ...userProps.style, ...style }}
            className={clsx(
              "rbc-event",
              className,
              userProps.className,
              {
                "rbc-selected": selected,
                "rbc-event-allday": showAsAllDay,
                "rbc-event-continues-prior": continuesPrior,
                "rbc-event-continues-after": continuesAfter,
              },
              "rbc-event-note",
              this.props.color === "dark" ? "rbc-event-dark" : "rbc-event-light"
            )}
            onClick={(e) => onSelect && onSelect(event, e)}
            onDoubleClick={(e) => onDoubleClick && onDoubleClick(event, e)}
            onKeyPress={(e) => onKeyPress && onKeyPress(event, e)}
          >
            <HStack justifyContent={"space-between"}>
              {typeof children === "function" ? children(content) : content}
              <HStack justifyContent={"space-between"}>
                {this.props.event.data.note.length > 1 && (
                  <IoDocumentText color="white" />
                )}
                {this.props.event.data.markAsReviewed === true ? (
                  <CheckCircleIcon color="lightgreen" />
                ) : (
                  <WarningTwoIcon color="yellow" />
                )}
              </HStack>
            </HStack>
          </div>
        </EventWrapper>
      );
    } else if (this.props.event.type === "TRADE") {
      return (
        <EventWrapper {...this.props} type="date">
          <div
            {...props}
            tabIndex={0}
            style={{ ...userProps.style, ...style }}
            className={clsx("rbc-event", className, userProps.className, {
              "rbc-selected": selected,
              "rbc-event-allday": showAsAllDay,
              "rbc-event-continues-prior": continuesPrior,
              "rbc-event-continues-after": continuesAfter,
            })}
            onClick={(e) => onSelect && onSelect(event, e)}
            onDoubleClick={(e) => onDoubleClick && onDoubleClick(event, e)}
            onKeyPress={(e) => onKeyPress && onKeyPress(event, e)}
          >
            <HStack justifyContent={"space-between"}>
              {typeof children === "function" ? children(content) : content}
              <HStack justifyContent={"space-between"}>
                {this.props.event.type === "TRADE" &&
                  this.props.event.tradeData.notes && (
                    <IoDocumentText color="white" />
                  )}
                {this.props.event.type === "TRADE" &&
                  this.props.event.tradeData.positionStatus === "OPEN" && (
                    <BsBarChartFill color="lightgreen" />
                  )}
              </HStack>
            </HStack>
          </div>
        </EventWrapper>
      );
    } else {
      return (
        <EventWrapper {...this.props} type="date">
          <div
            {...props}
            tabIndex={0}
            style={{ ...userProps.style, ...style }}
            className={clsx("rbc-event", className, userProps.className, {
              "rbc-selected": selected,
              "rbc-event-allday": showAsAllDay,
              "rbc-event-continues-prior": continuesPrior,
              "rbc-event-continues-after": continuesAfter,
            })}
            onClick={(e) => onSelect && onSelect(event, e)}
            onDoubleClick={(e) => onDoubleClick && onDoubleClick(event, e)}
            onKeyPress={(e) => onKeyPress && onKeyPress(event, e)}
          >
            {typeof children === "function" ? children(content) : content}
          </div>
        </EventWrapper>
      );
    }
  }
}

EventCell.propTypes = {
  event: PropTypes.object.isRequired,
  slotStart: PropTypes.instanceOf(Date),
  slotEnd: PropTypes.instanceOf(Date),

  resizable: PropTypes.bool,
  selected: PropTypes.bool,
  isAllDay: PropTypes.bool,
  continuesPrior: PropTypes.bool,
  continuesAfter: PropTypes.bool,

  accessors: PropTypes.object.isRequired,
  components: PropTypes.object.isRequired,
  getters: PropTypes.object.isRequired,
  localizer: PropTypes.object,

  onSelect: PropTypes.func,
  onDoubleClick: PropTypes.func,
  onKeyPress: PropTypes.func,
};

export default EventCell;
