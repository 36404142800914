import React from "react";
import NavWrapper from "./NavWrapper";
import { Box, HStack, Heading, Text } from "@chakra-ui/react";
import { BiBook, BiBookOpen } from "react-icons/bi";
import { FaBookOpen, FaObjectGroup } from "react-icons/fa";
import { StockLogo } from "../../design_library";
import DiscordInvite from "../../misc/DiscordInvite";

export default function LastPage() {
  return (
    <NavWrapper previousEnabled={false} isFinalStep={true} nextDisabled={false}>
      <Box textAlign={"left"}>
        <Heading
          as="h2"
          fontSize="18px"
          mb={4}
          fontWeight={600}
          textAlign={"left"}
        >
          Time to get started!
        </Heading>

        <Text mt="10px">
          You're all set! There are still loads of features we didn't show you
          but we hope you have a good understanding of the basics.
        </Text>
        <Text mt="10px">
          You can always come back to this tutorial by clicking on the 'Help'
          button in the settings page.
        </Text>
        <Text mt="10px">
          If you have any questions or need help, please reach out to us via
          Discord. You can find the link in the navbar or click the button
          below.
        </Text>
        <Text mt="10px" mb="20px">
          <span className="rainbow">
            Head to the dashboard at the top of the screen to see your insights.
          </span>
        </Text>
        <DiscordInvite />
      </Box>
    </NavWrapper>
  );
}
