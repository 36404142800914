import React, { useMemo } from "react";
import { Instrument, InstrumentType } from "../../../../types";
import { Box, Button, Flex } from "@chakra-ui/react";
import { useInstrumentFormController } from "./useInstrumentFormController";
import FormBase from "../../../../components/forms/Form";
import { getPricesForUnderlying } from "../../../../api";

interface InstrumentFormProps {
  instrument?: Instrument;
  volatility?: number;
  updateInstrument: (instrument: Instrument) => void;
}

export const InstrumentForm = (props: InstrumentFormProps) => {
  const controller = useInstrumentFormController(props);

  const [priceRunLoading, setPriceRunLoading] = React.useState(false);

  const canCalcTheoreticalPrice = useMemo(() => {
    if (!props.instrument) return false;
    if (props.instrument.instrumentType !== InstrumentType.Option) return false;
    if (props.volatility === undefined) return false;
    return true;
  }, [props.instrument, props.volatility]);

  const form = useMemo(() => {
    return (
      <Flex
        display="block"
        overflowY="auto"
        h="100%"
        w="100%"
        direction="column"
        px={2}
        py={1}
      >
        {canCalcTheoreticalPrice && (
          <Flex float="right" mr="4px" mt="20px">
            <Button
              colorScheme="green"
              size="sm"
              onClick={() => {
                controller.calculateTheoreticalPrice();
              }}
            >
              Calculate Theoretical Price
            </Button>
          </Flex>
        )}

        {props.instrument?.underlyingInstrumentId ||
          (props.instrument?.id && (
            <Flex float="right" mr="4px" mt="20px">
              <Button
                bg="var(--chart-blue)"
                size="sm"
                isLoading={priceRunLoading}
                onClick={() => {
                  setPriceRunLoading(true);
                  const underlyingId =
                    props.instrument?.underlyingInstrumentId ||
                    props.instrument?.id;

                  if (underlyingId !== undefined) {
                    try {
                      getPricesForUnderlying(underlyingId).then((response) => {
                        setPriceRunLoading(false);
                      });
                    } catch (error) {
                      console.error("Error fetching prices", error);
                    } finally {
                    }
                  } else {
                    setPriceRunLoading(false); // Set to false if no ID is available
                  }
                }}
              >
                Run Price Update
              </Button>
            </Flex>
          ))}

        <FormBase
          isLoading={controller.isLoading}
          title="Instrument Details"
          subtitle=""
          onSubmit={controller.submitPayload}
          submitText="Apply Changes"
          schema={controller.schema}
        />
      </Flex>
    );
  }, [controller.schema, priceRunLoading]);

  return <Box>{form}</Box>;
};
