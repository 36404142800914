import React from "react";
import {
  Box,
  Button,
  Heading,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
} from "@chakra-ui/react";
import { TbPlayerPlayFilled } from "react-icons/tb";
import { useAutoSyncController } from "./useAutoSyncController";
import { AddOrEditAutoSyncConfiguration } from "./AddOrEditAutoSyncConfiguration";

export const AutoSync = () => {
  const controller = useAutoSyncController();
  const getPanelContent = () => {
    if (controller.settings.length === 0) {
      return (
        <Box pt={2}>
          <Text fontSize="sm">No settings defined for this account</Text>
        </Box>
      );
    } else {
      return (
        <Flex my={4}>
          {controller.settings.map((setting) => {
            return (
              <Flex>
                <Button
                  leftIcon={<TbPlayerPlayFilled />}
                  size="sm"
                  mr={2}
                  colorScheme="green"
                  onClick={() => {
                    controller.run(setting);
                  }}
                >
                  Run
                </Button>
                <Button
                  size="sm"
                  mr={2}
                  onClick={() => {
                    controller.setSelectedSettings(setting);
                    controller.setShowConfigure(true);
                  }}
                >
                  Edit
                </Button>
                <Text size="sm" ml={2} pt="5px">
                  {setting.broker}
                </Text>
              </Flex>
            );
          })}
        </Flex>
      );
    }
  };

  return (
    <Flex direction="column">
      <Heading as="h3" size="lg">
        Auto Sync
      </Heading>
      {getPanelContent()}
      <Flex mt="10px">
        <Button
          w="full"
          onClick={() => {
            controller.setSelectedSettings(undefined);
            controller.setShowConfigure(true);
          }}
        >
          Add Configuration
        </Button>
      </Flex>
      <Modal
        closeOnOverlayClick={false}
        isOpen={controller.showConfigure}
        onClose={() => controller.setShowConfigure(false)}
        scrollBehavior="inside"
        isCentered
        size="6xl"
      >
        <ModalOverlay />
        <ModalContent background={"var(--bg)"}>
          <ModalHeader>Configure AutoSync</ModalHeader>

          <ModalCloseButton size="sm" />
          <ModalBody>
            <AddOrEditAutoSyncConfiguration
              settings={controller.selectedSettings}
              close={() => controller.setShowConfigure(false)}
            />
          </ModalBody>
          <Box h="10px" />
        </ModalContent>
      </Modal>
    </Flex>
  );
};
