import React, { useEffect, useCallback } from "react";
import {
  Box,
  Heading,
  HStack,
  Tag,
  Text,
  VStack,
  Spinner,
  Switch,
  Center,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { useInsightsController } from "../useInsightsController";
import {
  SecondaryButton,
  TextInput,
} from "../../../../components/design_library";
import { useAtomValue } from "jotai";
import { AppStateAtom } from "../../../../store";
import { DashboardLayoutStateStore } from "../../../../store/DashboardLayout";
import { InsightTemplate } from "../../../../types/Insight";
import { FaSearchMinus } from "react-icons/fa";
import { InsightTypeIcon } from "../../../../components/insights/InsightTypeIcon";
import { useLayout } from "../../helpers/Layout";

interface InsightSearchListProps {
  setPage: (page: string) => void;
  setInsightData: (insight: InsightTemplate) => void;
}

const InsightSearchList: React.FC<InsightSearchListProps> = ({
  setPage,
  setInsightData,
}) => {
  const controller = useInsightsController();
  const { addInsightToLayouts, removeInsightFromLayouts } = useLayout();
  const dashboardLayoutState = useAtomValue(DashboardLayoutStateStore);
  const appState = useAtomValue(AppStateAtom);

  useEffect(() => {
    controller.fetchInsights(controller.displaysPage, controller.searchQuery);
  }, [controller.searchQuery]);

  const isInsightEnabled = useCallback(
    (insightId: number) =>
      dashboardLayoutState.enabledInsights?.some(
        (id) => parseInt(id) === insightId
      ),
    [dashboardLayoutState.enabledInsights]
  );

  const handleToggleInsight = useCallback(
    (insight: InsightTemplate) => {
      if (isInsightEnabled(insight.insightId)) {
        removeInsightFromLayouts(insight);
      } else {
        addInsightToLayouts(insight);
      }
    },
    [isInsightEnabled, removeInsightFromLayouts, addInsightToLayouts]
  );

  return (
    <Box flex="1" fontSize="14px" minH="300px">
      {dashboardLayoutState.gridLoading && (
        <Box
          pos="absolute"
          right="0"
          top="0"
          h="100%"
          w="100%"
          bg="rgba(0,0,0,0.5)"
          zIndex={99999}
        >
          <Center h="100vh">
            <VStack>
              <Spinner size="xl" />
              <Text color="white" pl="10px">
                Loading...
              </Text>
            </VStack>
          </Center>
        </Box>
      )}

      <Box px="3" pb="2">
        <TextInput
          placeholder="Search insights"
          bg="var(--bg)"
          onChange={(e) => controller.setSearchQuery(e.target.value)}
          value={controller.searchQuery}
        />
      </Box>

      <HStack px="3" alignItems="flex-start">
        <VStack flex="4" maxH="1200px" overflow="auto">
          {controller.loading && controller.insights.length === 0 && (
            <VStack
              h="100%"
              minH="300px"
              justifyContent="center"
              alignItems="center"
            >
              <Spinner />
            </VStack>
          )}
          {!controller.loading && controller.insights.length === 0 && (
            <VStack h="160px" justifyContent="center" mt="40px">
              <FaSearchMinus fontSize="40px" />
              <Text fontSize="lg" maxW="200px" textAlign="center">
                No insights found, try a different search term...
              </Text>
            </VStack>
          )}
          {controller.insights.map((insight) => {
            const enabled = isInsightEnabled(insight.insightId as number);
            const isOwner =
              parseInt(appState.user?.id) === parseInt(insight.ownerUserId);

            return (
              <Box
                key={insight.insightId}
                px={2}
                py={1}
                borderRadius={8}
                border="solid 1px var(--gray)"
                w="full"
              >
                <HStack justifyContent="space-between">
                  <HStack alignItems="center" justifyContent="center">
                    <Box
                      w="40px"
                      h="40px"
                      mr="10px"
                      borderRadius="6px"
                      bg={enabled ? "var(--green)" : "var(--gray)"}
                      color="var(--white)"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <InsightTypeIcon type={insight.type} />
                    </Box>

                    <VStack alignItems="flex-start">
                      <HStack>
                        <Heading
                          size="xs"
                          maxW="300px"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                        >
                          {insight.title}
                        </Heading>
                        {insight.official && (
                          <Tag colorScheme="green">Official</Tag>
                        )}
                        {insight.isDefaultInsight && (
                          <Tag colorScheme="blue">Recommended</Tag>
                        )}
                      </HStack>
                      <Text
                        size="xs"
                        maxW="500px"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        {insight.description}
                      </Text>
                    </VStack>
                  </HStack>

                  <HStack justifyContent="space-between" alignItems="center">
                    {isOwner && (
                      <>
                        <SecondaryButton
                          bg="transparent"
                          onClick={() => {
                            setInsightData(insight);
                            setPage("creator");
                          }}
                          px="2px"
                        >
                          <EditIcon fontSize="20px" />
                        </SecondaryButton>
                        <SecondaryButton
                          bg="transparent"
                          onClick={() =>
                            controller.handleDelete(insight.insightId as number)
                          }
                          px="2px"
                        >
                          <DeleteIcon fontSize="20px" />
                        </SecondaryButton>
                      </>
                    )}
                    <Switch
                      colorScheme={enabled ? "green" : "red"}
                      size="lg"
                      isChecked={enabled}
                      onChange={() => handleToggleInsight(insight)}
                    />
                  </HStack>
                </HStack>
              </Box>
            );
          })}
        </VStack>
      </HStack>
    </Box>
  );
};

export default InsightSearchList;
