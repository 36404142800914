import React, { useEffect } from "react";

export default function TokenAssignment() {
  useEffect(() => {
    localStorage.removeItem("selectedAccount");
    localStorage.setItem("chakra-ui-color-mode", "dark");
    const urlParams = new URLSearchParams(window.location.search);
    localStorage.setItem("token", "bearer " + urlParams.get("token"));
    window.location.href = "../app/dashboard";
  }, []);

  return <div></div>;
}
