import { Box } from "@chakra-ui/react";
import styles from "../homepage/Homepage.module.css";
import { useEffect, useRef } from "react";
import { checkToken } from "../../api";
import { useNavigate } from "react-router";
import React from "react";
import { Cookies } from "react-cookie-consent";
import { Meta } from "../../components";
import Footer from "../homepage/footer/App";
import NavBar from "../homepage/menu/App";
import EnterpriseHero from "./components/EnterpriseHero";
import { EnterpriseFeatures } from "./components/EnterpriseFeatures";
import EnterpriseUseCases from "./components/EnterpriseUseCases";
import { EnterpriseCTA } from "./components/EnterpriseCTA";
import EnterpriseAPIDocumentation from "./components/EnterpriseAPIDocumentation";

const EnterpriseMarketingPage = () => {
  const initialised = useRef<boolean>(false);
  const navigate = useNavigate();
  const performTokenCheck = async () => {
    const res = await checkToken();
    if (res.success === true) {
      navigate("/app/dashboard");
    }
  };

  useEffect(() => {
    if (initialised.current === false) {
      initialised.current = true;
      performTokenCheck();
    }
  }, []);

  return (
    <Box className={styles.mainAppContainer}>
      <Meta
        title={"WealthBee - An enterprise P&L analytics engine"}
        description={
          "For enterprise users WealthBee is an emedable P&L engine with APIs to help to you track your portfolios or your clients accounts."
        }
        canonical={"https://wealthbee.io/enterprise"}
      />
      <NavBar />

      <EnterpriseHero />

      <Box bg="var(--dark-gray)" maxW="full" py="10px">
        <Section>
          <EnterpriseFeatures />
        </Section>
      </Box>

      <Section>
        <EnterpriseUseCases />
      </Section>

      <Section>
        <EnterpriseCTA />
      </Section>

      <Footer />

      <Cookies />
    </Box>
  );
};

const Section = (props: any) => {
  return (
    <Box as="section" id={props.id} mt="100px">
      {props.children}
    </Box>
  );
};
export default EnterpriseMarketingPage;
