import moment from "moment";
import { ColDef } from "ag-grid-community";
import { ColumnTypeEnum, createGroupColumn } from "../../../../components";
import { useInstruments } from "../../../../hooks";
import { Link } from "@chakra-ui/react";
import React from "react";

export const columnDefinitions: ColDef[] = [
  createGroupColumn("Price Date", "priceDate", ColumnTypeEnum.Date, {
    valueFormatter: (params) => {
      return params.value ? moment(params.value).format("DD-MMM-YYYY") : "";
    },
  }),
  createGroupColumn("Timestamp", "timestamp", ColumnTypeEnum.Text, {
    aggFunc: "distinct",
    sort: "desc",
  }),
  createGroupColumn("Price Type", "priceType", ColumnTypeEnum.Text),
  createGroupColumn("Price", "price", ColumnTypeEnum.Numeric),
  createGroupColumn("Source", "source", ColumnTypeEnum.Text),

  createGroupColumn("Delta", "greeks.delta", ColumnTypeEnum.Numeric),
  createGroupColumn("Gamma", "greeks.gamma", ColumnTypeEnum.Numeric),
  createGroupColumn("Theta", "greeks.theta", ColumnTypeEnum.Numeric),
  createGroupColumn("Vega", "greeks.vega", ColumnTypeEnum.Numeric),
  createGroupColumn("Rho", "greeks.rho", ColumnTypeEnum.Numeric),
  createGroupColumn("Phi", "greeks.phi", ColumnTypeEnum.Numeric),
  createGroupColumn("IV", "greeks.iv", ColumnTypeEnum.Numeric),
];
