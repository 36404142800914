import { Box, Text } from "@chakra-ui/react";
import { StepType } from "@reactour/tour";
import Test from "./components/WelcomeToWealthBee";
import WelcomeToWealthBee from "./components/WelcomeToWealthBee";
import AccountManagerTutorial from "./components/AccountManagerTutorial";
import AddAssetsTutorial from "./components/AddAssetsTutorial";
import PositionsGridExplainer from "./components/PositionsGridExplainer";
import BookExplainerTutorial from "./components/BookExplainerTutorial";
import TransactionsPaneTutorial from "./components/TransactionsPaneTutorial";
import UnderlyingExplainerTutorial from "./components/UnderlyingExplainerTutorial";
import PositionsGroupTutorial from "./components/PositionsGroupTutorial";
import PositionSingleExplainerTutorial from "./components/PositionSingleExplainerTutorial";
import LastPage from "./components/LastPage";
import UploadDnDTutorial from "./components/UploadDnDTutorial";
import TutorialVideo from "./components/TutorialVideo";

export const tourData: StepType[] = [
  {
    selector: ".xxxxxx",
    content: TutorialVideo,
    position: "center",
  },
  {
    selector: ".xxxxxx",
    content: WelcomeToWealthBee,
    position: "center",
  },
  {
    selector: ".tutorial-account-manager",
    content: AccountManagerTutorial,
    position: "top",
  },
  {
    selector: ".tutorial-add-assets",
    content: AddAssetsTutorial,
    position: "center",
  },
  {
    selector: ".tutorial-assets-list",
    content: (
      <Box>
        <Text>Start by uploading a file from your broker.</Text>
        <Text className="rainbow">Click on upload trades</Text>
      </Box>
    ),
    position: "bottom",
  },
  {
    selector: ".modal-body-tutorial",
    content: (
      <Text>
        You can either search or select your broker, if you don't see your
        broker, please contact us via our support chat icon in the bottom right.{" "}
        <span className="rainbow">Click on your broker to proceed.</span>
      </Text>
    ),
    position: "bottom",
  },
  {
    selector: "html",
    content: UploadDnDTutorial,
    position: [50, 25],
  },
  {
    selector: ".ag-root-wrapper",
    content: PositionsGridExplainer,
    position: "center",
  },
  {
    selector: ".ag-row-level-0",
    content: BookExplainerTutorial,
    position: [400, 20],
  },
  {
    selector: ".ag-row-level-1",
    content: UnderlyingExplainerTutorial,
    position: [400, 20],
  },
  {
    selector: ".ag-row-level-2",
    content: PositionsGroupTutorial,
    position: [400, 20],
  },
  {
    selector: ".ag-row-level-3",
    content: PositionSingleExplainerTutorial,
    position: [400, 20],
  },
  {
    selector: ".allotment-tutorial",
    content: TransactionsPaneTutorial,
    position: [200, 20],
  },
  {
    selector: ".xxxxxxx",
    content: LastPage,
    position: "center",
  },
];
