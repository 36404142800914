import React, { useEffect, useRef } from "react";
import { Box, HStack, Stack, Text } from "@chakra-ui/react";
import { Required } from "./Required";

import { CreatableSelect } from "chakra-react-select";
import { searchForInstruments } from "../../../api";
import { useDebounce } from "../../../features/hooks";
import { StockLogo } from "../../design_library";
import { InstrumentType } from "../../../types/Enums";
export const InstrumentSearch = (props: {
  label: string;
  instrumentType: InstrumentType;
  value: string;
  field: string;
  error: string;
  enableCreateNew?: boolean;
  onChange: (field: string, value: string) => void;
}) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [options, setOptions] = React.useState([]);
  const initialised = useRef<boolean>(false);

  useEffect(() => {
    if (initialised.current === false) {
      initialised.current = true;
      setIsLoading(false);
    }
  });

  const getSearchResults = async (query: string) => {
    if (query.length === 0) {
      setOptions([]);
      return;
    }
    setIsLoading(true);
    const results = await searchForInstruments(
      query.toUpperCase(),
      props.instrumentType
    );

    if (results?.forSearch) {
      setOptions(results.forSearch);
      setIsLoading(false);
    }
  };

  const primeSearch = useDebounce(getSearchResults, 400);

  return (
    <Stack spacing="4" mt="2" mb="1">
      <Text>
        {props.label}
        <Required />
      </Text>
      {props.enableCreateNew === true && (
        <CreatableSelect
          components={{ Option }}
          isLoading={isLoading}
          tagVariant="solid"
          options={
            options &&
            options.map((item) => {
              return { value: item.value, label: item.label, data: item };
            })
          }
          noOptionsMessage={() => "No results found"}
          formatCreateLabel={(inputValue) =>
            `Press return to add: ${inputValue} (New)`
          }
          allowCreateWhileLoading={false}
          onInputChange={(e) => {
            primeSearch(e);
          }}
          onChange={(e) => {
            props.onChange(props.field, e);
          }}
        />
      )}

      <Text color="red.500">{props.error}</Text>
    </Stack>
  );
};

const Option = (props: any) => {
  const { data } = props;

  if (data.__isNew__) return <Text pl="4"> {data.label}</Text>;
  return (
    <Box
      p="2"
      {...props.innerProps}
      sx={{
        "&:hover": {
          bg: "var(--bg)",
        },
      }}
    >
      <HStack>
        <StockLogo stock={data.value} size="30" />
        <Text>({data.value})</Text>
        <Text>{data.label}</Text>
      </HStack>
    </Box>
  );
};
