import { GetContextMenuItemsParams } from "ag-grid-community";
import { RiRestaurantLine } from "react-icons/ri";

export const getAllChildrenOfGroup = (
  params: GetContextMenuItemsParams,
  transactions: any[]
) => {
  const children = [];

  const selectedRows = params.api.getSelectedRows();
  if (selectedRows.length > 0) {
    selectedRows.forEach((row) => {
      if (row.tradeIds) {
        row.tradeIds.forEach((tradeId) => {
          const trade = transactions.trades.find((transaction) => {
            return transaction.id === tradeId;
          });
          if (trade) {
            children.push(trade);
          }
        });
      } else {
        children.push(row);
      }
    });
  }

  if (params.node.data !== undefined) {
    if (params?.node?.data?.tradeIds) {
      params.node.data.tradeIds.forEach((tradeId) => {
        const trade = transactions.trades.find((transaction) => {
          return transaction.id === tradeId;
        });
        if (trade) {
          children.push(trade);
        }
      });
    } else {
      children.push(params.node.data);
    }
  } else if (!params.node?.allLeafChildren) {
    params.api.getSelectedNodes().map((node) => {
      if (node.data !== undefined) {
        children.push(node.data);
      }
    });

    if (params?.api?.getSelectedNodes().length === 0)
      children.push(params?.node?.data);
  } else if (params?.node?.allLeafChildren) {
    params?.node?.allLeafChildren?.forEach((child) => {
      if (!child.data.tradeIds) {
        children.push(child.data);
      } else {
        child.data.tradeIds.forEach((tradeId) => {
          const trade = transactions.trades.find((transaction) => {
            return transaction.id === tradeId;
          });

          if (trade) {
            children.push(trade);
          }
        });
      }
    });
  }

  return children;
};
