import {
  Box,
  Center,
  Divider,
  HStack,
  Heading,
  Icon,
  Image,
  List,
  ListIcon,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import * as React from "react";
import { CheckIcon, PrimaryButton } from "../../../components/design_library";
import styles from "./Pricing.module.css";
import { BiRightArrowAlt } from "react-icons/bi";

const Pricing = (props) => {
  return (
    <Box as="section" id="pricing" pt="30px" pb="120px">
      <Center>
        <Box
          width="8xl"
          rounded="xl"
          mt="2"
          display="flex"
          flexDir={"column"}
          justifyContent="center"
          alignItems={"center"}
        >
          {/* <Image
            textAlign={"center"}
            display="flex"
            justifyContent={"center"}
            src={"https://cdn.wealthbee.io/main/svg/logo.svg"}
            alt="Pricing card logo"
            style={{ maxWidth: "371px" }}
            marginBottom="16px"
          /> */}

          <Heading
            mb="4"
            textAlign={{
              base: "center",
              md: "center",
              lg: "center",
              xl: "center",
            }}
          >
            Pick the plan that suits you best
          </Heading>

          <Text
            fontSize={"16px"}
            textAlign="center"
            fontWeight="400"
            opacity={0.8}
            maxW="572px"
          >
            Get full access to all features with a subscription and take your
            stock, future & option trading performance to the next level.
          </Text>

          <Text
            fontSize={"18px"}
            textAlign="center"
            fontWeight="400"
            opacity={0.8}
            maxW="572px"
            mt="14px"
          >
            <span className="rainbow">Save 20% </span>
            when you choose an annual subscription.
          </Text>

          <HStack
            gap={"32px"}
            marginTop="44px"
            minH={"228px"}
            justifyContent="center"
            alignItems={"flex-start"}
            flexWrap="wrap"
          >
            <PricingBox
              planName={"Premium Monthly"}
              priceText={"$35"}
              frequencyText={"/month"}
              accountsListText={[
                "5 portfolios",
                "Unlimited uploads",
                "1000 trades per month",
              ]}
              benefitsListText={[
                "Automatic Strategy Categorization",
                "Net worth tracking",
                "Trade journaling & analytics",
                "Pricing for stocks, options, and futures",
                "Personalized financial insights",
                "Automated reporting & PnL tracking",
                "Secure and reliable platform",
                "Integration with major brokers",
                "Position Calculation Audit",
                "Future updates included",
              ]}
            />

            <PricingBox
              planName={"Premium Annual"}
              priceText={"$28"}
              frequencyText={"/month"}
              accountsListText={[
                "10 portfolios",
                "Unlimited uploads",
                "5000 trades per month",
              ]}
              benefitsListText={[
                "Automatic Strategy Categorization",
                "Trade journaling & analytics",
                "Pricing for stocks, options, and futures",
                "Personalized financial insights",
                "Automated reporting & PnL tracking",
                "Secure and reliable platform",
                "Integration with major brokers",
                "Position Calculation Audit",
                "Future updates included",
                "Save 20% annually",
              ]}
              billedAnnuallyText={"$336 billed annually"}
            />

            <PricingBox
              planName={"Invested Monthly"}
              priceText={"$540"}
              frequencyText={"/month"}
              accountsListText={[
                "Unlimited portfolios",
                "Unlimited uploads",
                "100,000 trades per month",
              ]}
              benefitsListText={[
                "Everything in Standard plan",
                "Priority customer support",
                "Early access to new features",
                "Access to private trading chat group",
                "1:1 education & mentoring sessions",
              ]}
              billedAnnuallyText={""}
            />
          </HStack>
        </Box>
      </Center>
    </Box>
  );
};

const PricingBox = ({
  planName,
  priceText,
  frequencyText,
  accountsListText,

  benefitsListText,
  billedAnnuallyText,
}: {
  planName?: string;
  priceText?: string;
  frequencyText?: string;
  accountsListText?: string[];
  benefitsListText?: string[];
  billedAnnuallyText?: string;
}) => {
  return (
    <Box
      className={styles.pricingCardBox}
      border="1px solid var(--accent)"
      py="20px"
      px="20px"
    >
      <Text textAlign={"left"} w="full" fontSize="20px" color="var(--accent)">
        {planName}
      </Text>
      <HStack
        py="4px"
        w="full"
        justifyContent={"flex-start"}
        alignItems={"flex-end"}
      >
        <Text textAlign={"left"} fontSize="34px" color="var(--white)">
          {priceText}
        </Text>
        <Text mb="6px">{frequencyText}</Text>
      </HStack>
      <Text py="4px" textAlign={"left"} w="full" fontSize="14px" minH="30px">
        {billedAnnuallyText ? billedAnnuallyText : " "}
      </Text>
      <Divider mt="20px" mb="10px" />

      <Box w="full" minH="140px">
        <Text
          textAlign={"left"}
          w="full"
          mt="10px"
          fontSize="16px"
          color="var(--white)"
        >
          Accounts
        </Text>
        <UnorderedList mt="12px" w="full" px="12px">
          {accountsListText?.map((item, index) => (
            <ListItem key={index}>
              <Text textAlign={"left"} fontSize="14px" color="var(--white)">
                {item}
              </Text>
            </ListItem>
          ))}
        </UnorderedList>
      </Box>

      <Box w="full" minH="140px">
        <Text
          textAlign={"left"}
          w="full"
          mt="10px"
          fontSize="16px"
          color="var(--white)"
        >
          Benefits
        </Text>

        <UnorderedList mt="12px" w="full" px="12px">
          {benefitsListText?.map((item, index) => (
            <ListItem key={index}>
              <Text textAlign={"left"} fontSize="14px" color="var(--white)">
                {item}
              </Text>
            </ListItem>
          ))}
        </UnorderedList>
      </Box>
      <PrimaryButton
        id="cta_1"
        w="full"
        as="a"
        href="../app/register"
        onClick={() => {
          localStorage.setItem("ftk", "true");
        }}
        border="none !important"
        bg="var(--accent-dark) !important"
        fontSize="16px !important"
        px="20px"
        mt="40px"
      >
        Start 30 day free trial now
        <Box pl="6px">
          <BiRightArrowAlt />
        </Box>
      </PrimaryButton>
    </Box>
  );
};

export default Pricing;
