import { useEffect, useState } from "react";
import { getKnowledgeBaseArticles } from "../../api";
interface UseKnowledgeBaseController {
  articles: any[];
  categories: any[];
}

export const useKnowledgeBaseController = (): UseKnowledgeBaseController => {
  const [articles, setArticles] = useState([] as any[]);
  const [categories, setCategories] = useState([] as any[]);

  const getArticles = async () => {
    const data = await getKnowledgeBaseArticles(true);
    const articles = data.articles;
    //sort by name
    articles.sort((a, b) => a.title.localeCompare(b.title));
    setArticles(articles);

    const categories = data.metadata.categories.sort();
    const index = categories.indexOf("Guides");
    if (index > -1) {
      categories.splice(index, 1);
      categories.unshift("Guides");
    }

    //sort by cateogiry alpghabetically

    setCategories(categories);
  };

  useEffect(() => {
    getArticles();
  }, []);

  return {
    articles,
    categories,
  };
};
