import { useState } from "react";
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Input,
  Box,
  Text,
  VStack,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  HStack,
  FormLabel,
  Heading,
} from "@chakra-ui/react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  EditIcon,
  ViewIcon,
} from "@chakra-ui/icons";
import React from "react";
import { useAtomValue } from "jotai";
import { AccountAtom } from "../../store";
import useAccountController from "./useAccountController";
import { BiTrash } from "react-icons/bi";
import { SecondaryButton, Dialog } from "../design_library";
import ExportAccount from "./ExportAccount";

export default function AccountSelector({}: any) {
  const controller = useAccountController();

  const accountState = useAtomValue(AccountAtom);
  const [open, setOpen] = useState(false);

  const handleAccountSelection = (account) => {
    // Set selected account and delay closing the Popover

    controller.setSelectedAccount(account);
    setTimeout(() => {
      setOpen(false);
    }, 150);
  };

  return (
    <>
      <Popover
        isOpen={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <PopoverTrigger>
          <Button
            className="tutorial-account-manager"
            border={"1px solid var(--gray)"}
            bg={"var(--bg)"}
            aria-haspopup="listbox"
            aria-expanded={open}
            rightIcon={
              <VStack>
                <ChevronUpIcon />
                <ChevronDownIcon />
              </VStack>
            }
            width="240px"
            justifyContent="space-between"
            mt="2px"
            height="60px"
            px="8px"
          >
            <HStack>
              <Box
                bg="var(--gray)"
                borderRadius={"6px"}
                minWidth="46px"
                height="46px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Text fontSize="2xl">
                  {accountState?.selectedAccount?.name[0].toUpperCase()}
                </Text>
              </Box>
              <VStack w="full" alignItems={"flex-start"} pl="4px">
                <Text fontSize="xs" fontWeight={400}>
                  Selected Portfolio
                </Text>
                <Text
                  maxW="140px"
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                >
                  {accountState?.selectedAccount?.name}
                </Text>
              </VStack>
            </HStack>
          </Button>
        </PopoverTrigger>
        <PopoverContent width="250px" bg="var(--bg)">
          <VStack align="stretch" p={4} spacing={4}>
            <Box>
              {accountState.accounts.map((account, key) => (
                <Button
                  key={key}
                  variant="ghost"
                  justifyContent="space-between"
                  onClick={() => handleAccountSelection(account)}
                  width="full"
                >
                  <Text
                    maxW="200px"
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                  >
                    {account.name}
                  </Text>
                </Button>
              ))}
            </Box>

            <Divider />
            <MangageAccounts />
          </VStack>
        </PopoverContent>
      </Popover>
    </>
  );
}

const MangageAccounts = () => {
  const controller = useAccountController();
  const accountState = useAtomValue(AccountAtom);

  return (
    <>
      <Button
        variant="ghost"
        justifyContent="space-between"
        onClick={() => {
          controller.setModalOpen(true);
        }}
        width="full"
        leftIcon={<EditIcon />}
      >
        Manage portfolios
      </Button>

      <Modal
        size={"3xl"}
        isOpen={controller.modalOpen}
        onClose={() => controller.setModalOpen(false)}
      >
        <ModalOverlay backdropFilter="blur(3px)" />

        <ModalContent bg="var(--bg)">
          <ModalHeader>
            <HStack justifyContent={"space-between"} mt="10px">
              <Heading fontSize={"xl"}>Portfolio Management</Heading>
              <ExportAccount />
            </HStack>
            <Text fontSize={"md"} fontWeight={500}>
              Manage your portfolios and transactions.
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody bg="var(--bg)">
            <Box mb="20px">
              <form onSubmit={controller.handleSubmit}>
                <FormLabel>Portfolio Name</FormLabel>
                <HStack>
                  <Input
                    type="text"
                    name="name"
                    value={controller.accountForm.name}
                    onChange={controller.handleFormChange}
                    placeholder="Enter new portfolio name"
                    required
                  />
                  <SecondaryButton type="submit" w="full" maxW="160px">
                    {controller.editingId ? "Update" : "Create account"}
                  </SecondaryButton>
                </HStack>
              </form>
            </Box>

            <Heading fontSize={"md"} mb="12px">
              Your Accounts
            </Heading>
            {accountState.accounts.map((account) => (
              <HStack
                key={account.id}
                w="100%"
                justifyContent={"space-between"}
              >
                <HStack
                  w="full"
                  p="8px"
                  borderRadius={"6px"}
                  justifyContent={"space-between"}
                >
                  <Input
                    defaultValue={account.name}
                    onChange={(e) => {
                      controller.updateAccountName(
                        account.id,
                        e.target.value,
                        account
                      );
                    }}
                    placeholder="Enter account name"
                  />

                  <HStack>
                    <SecondaryButton
                      size="md"
                      onClick={() => {
                        controller.setSelectedAccount(account);
                        setTimeout(() => {
                          controller.setModalOpen(false);
                        }, 20);
                      }}
                    >
                      <ViewIcon />
                      <Text pl="4px"> View</Text>
                    </SecondaryButton>

                    <Dialog
                      buttonSize="md"
                      title={"Delete all transactions"}
                      buttonText={
                        <HStack>
                          <BiTrash />
                          <Text fontWeight={400} fontSize="sm">
                            {" "}
                            Trades
                          </Text>
                        </HStack>
                      }
                      description={`Are you sure you want to delete all transactions for account ${account.name}?`}
                      confirmText={"Confirm"}
                      onConfirm={() =>
                        controller.deleteAllTheTrades(account.id)
                      }
                    />
                    <Dialog
                      buttonSize="md"
                      title={"Remove Account"}
                      disabled={accountState.accounts.length === 1}
                      buttonText={
                        <HStack>
                          <BiTrash />
                          <Text fontWeight={400} fontSize="sm">
                            {" "}
                            Portfolio
                          </Text>
                        </HStack>
                      }
                      description={`Are you sure you want to delete ${account.name} and all associated transactions?`}
                      confirmText={"Confirm"}
                      onConfirm={() => controller.handleDelete(account.id)}
                    />
                  </HStack>
                </HStack>
              </HStack>
            ))}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
