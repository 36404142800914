import { atom } from "jotai";
import { PositionGroupingOptions, PositionStatus } from "../types";
import moment from "moment";

export interface FilterState {
  filterApplied: boolean;
  selectedFilterId?: number;
  underlyings: string[];
  selectedUnderlying: string;
  status: PositionStatus;

  positionGrouping: PositionGroupingOptions;
  tags: string[];
  selectedTag: string;
  toDate: Date;
  fromDate: Date;
  positionGroups: string[];
  selectedPositionGroup: string;
  books: string[];
  selectedBook: string;
  selectedStrategy: string;
  strategies: string[];
}

const buildInitialState = (): FilterState => {
  const state: any = {
    filterApplied: false,
    underlyings: [],
    selectedUnderlying: undefined,
    status: "", //Formally positionView
    positionGrouping: PositionGroupingOptions.DEFAULT,
    tags: [],
    selectedTag: "",
    toDate: moment().add(1, "day").toDate(),
    fromDate: moment().subtract(5, "year").toDate(),
    positionGroups: [],
    selectedPositionGroup: "",
    books: [],
    selectedBook: "",
    selectedStrategy: "",
    baseStrategies: [],
    tradedStrategies: [],
  };

  return state;
};

export const FilterStateAtom = atom<FilterState>(buildInitialState());
