export const delay = async (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const distinctValues = (arr: string[]): string[] => {
  if (arr.length > 0) {
    const uniqueValues = arr.filter(
      (value, index, self) => self.indexOf(value) === index
    );
    return uniqueValues;
  }
  return [];
};

export const sum = (arr: number[]): number => {
  return arr.reduce((a, b) => a + b, 0);
};
