import {
  Heading,
  HStack,
  Image,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import FakeCalendarDate from "../../homepage/FeatureList/component/demos/FakeCalendarDate";
import { FloatingIcons } from "../../homepage/FeatureList/component/demos/FloatingIcons";
import { RandomValuesDemo } from "../../homepage/FeatureList/component/demos/RandomValues";
import UploadingExplosion from "../../homepage/FeatureList/component/demos/UploadingExplosion";
import FeatureContainer from "../../homepage/FeatureList/component/FeatureContainer";

const CustomTab = (props: any) => {
  return (
    <Tab
      _selected={{
        color: "var(--white)",
        background: "var(--accent)",
        borderRadius: "12px",
      }}
      _hover={{
        background: "var(--accent)",
        borderRadius: "12px",
      }}
      borderRadius="12px"
      color="var(--white)"
      background="var(--dark-gray)"
      px="20px"
      py="14px"
      mx="10px"
      w="full"
    >
      {props.children}
    </Tab>
  );
};

export default function EnterpriseUseCases() {
  return (
    <VStack>
      <Heading
        as="h2"
        size="xl"
        color="var(--white)"
        textAlign="center"
        mb="40px"
      >
        Enterprise Use Cases
      </Heading>
      <Stack>
        <Tabs isLazy variant="custom" borderRadius={"12px"} p="6px">
          <TabList
            justifyContent={"space-between"}
            background={"var(--dark-gray)"}
            borderRadius={"12px"}
          >
            <CustomTab>Advisories Automation</CustomTab>
            <CustomTab>Analytical Dashboards</CustomTab>
            <CustomTab>White Labeling</CustomTab>
            <CustomTab>Affiliates</CustomTab>
          </TabList>
          <TabIndicator />
          <TabPanels display={"flex"} justifyContent={"center"}>
            <FeatureContainer
              component={
                <Image
                  src="https://cdn.wealthbee.io/main/app/homepage/PNL.png"
                  alt="feature1"
                  borderRadius={"8px"}
                  border="solid 1px var(--accent)"
                />
              }
              color="yellow"
              direction="left"
              title={`Advisories Automation`}
              description={[
                `Use WealthBee's P&L engine to track portfolios and trades`,
                `Automatically generate P&L reports & analytics`,
                `Embed P&L analytics dashboard into your own website`,
                `Distribute newsletters with P&L reports and commentary to your clients`,
              ]}
            />

            <FeatureContainer
              component={
                <Frame>
                  <RandomValuesDemo
                    defaultNumber={56}
                    defaultText={"Breakeven"}
                    arrayOfTextValues={["Breakeven"]}
                    prefix={"$"}
                    suffix={""}
                  />
                </Frame>
              }
              color="yellow"
              direction="right"
              title={"Analytical Dashboards"}
              description={[
                `Create custom analytics dashboards for your clients`,
                `Embed WealthBee's analytics engine into your own website`,
                `Track performance metrics for your clients`,
                `Manage portfolios via API`,
              ]}
            />

            <FeatureContainer
              component={
                <Frame>
                  <FloatingIcons
                    textList={[
                      { value: "Stangle", color: "var(--accent)", offset: 5 },
                      {
                        value: "Wheeling",
                        color: "var(--chart-blue)",
                        offset: 10,
                      },
                      {
                        value: "Iron Condor",
                        color: "var(--green)",
                        offset: 15,
                      },
                      {
                        value: "Covered Call",
                        color: "var(--red)",
                        offset: 8,
                      },
                      {
                        value: "Straddle",
                        color: "var(--chart-orange)",
                        offset: 15,
                      },
                      {
                        value: "Strangle",
                        color: "var(--chart-dark-blue)",
                        offset: 10,
                      },
                      {
                        value: "Butterfly",
                        color: "var(--chart-baby-blue)",
                        offset: 5,
                      },
                      {
                        value: "Custom tag...",
                        color: "var(--gray)",
                        offset: 5,
                      },
                    ]}
                  />
                </Frame>
              }
              cPosition="blurBottomRight"
              color="yellow"
              direction="left"
              title={`White Labeling`}
              description={[
                `White-label WealthBee's P&L engine and analytics dashboard`,
                `Monetize your own following or user-base`,
                `Customize the look and feel with your logo, and theme.`,
              ]}
            />

            <FeatureContainer
              component={
                <Frame>
                  <UploadingExplosion />
                </Frame>
              }
              color="yellow"
              direction="right"
              title={"Affiliates"}
              description={[
                `Earn commissions by referring users back to WealthBee`,
                `Track your referrals and commissions in real-time`,
                `Customize your referral link and landing page`,
              ]}
            />
          </TabPanels>
        </Tabs>
      </Stack>
    </VStack>
  );
}

const Frame = (props: any) => {
  return (
    <HStack
      width={"450px"}
      maxH="90%"
      maxW="90%"
      borderRadius={"12px"}
      paddingLeft="20px"
      paddingRight={"20px"}
      justifyContent={"center"}
    >
      {props.children}
    </HStack>
  );
};
