import {
  Box,
  Heading,
  Image,
  ListItem,
  OrderedList,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Component } from "react";
import { Panel } from "../../../../../components/design_library";
import React from "react";

export default class RobinhoodInstructions extends Component {
  render() {
    return (
      <Panel>
        <Stack spacing="5">
          <OrderedList>
            <ListItem mb="14px">
              Open the Robinhood app and select 'Account'. If you're on the web,
              skip to the next step.
            </ListItem>
            <ListItem mt="10px" mb="14px">
              Go to 'Reports and Statements' and select 'Reports'.
            </ListItem>
            <ListItem mt="10px" mb="14px">
              Click 'Generate New Report'.
            </ListItem>

            <ListItem mt="10px" mb="14px">
              In 'Customize your report', select the account type, the start
              date, and the end date for the report, then click 'Generate
              Report'.
            </ListItem>

            <ListItem mt="10px" mb="14px">
              Reports may take 2 hours to generate but can take up to 24 hours.
              Robinhood will notify you when it's ready to download.
            </ListItem>
            <ListItem mt="10px" mb="14px">
              Once notified, go to 'Reports' and download the CSV file.
            </ListItem>
          </OrderedList>
        </Stack>
      </Panel>
    );
  }
}
