import {
  Button,
  Center,
  Flex,
  Heading,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { GridReadyEvent } from "ag-grid-enterprise";
import {
  GridContainer,
  columnTypes,
  distinctValue,
  isMobile,
} from "../../components";
import { AgGridReact } from "ag-grid-react";
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import "./TransactionsGrid.css";
import { columnDefinitions } from "./grid/columnDefinitions";
import { useTransactionController } from "./useTransactionController";
import { useGridContextMenuController } from "../../components/grid/utils/useGridContextMenuController";
import { TransactionsInnerRenderer } from "./grid/TransactionsInnerRenderer";
import React, { useCallback, useRef, useState } from "react";
import { useGridState } from "../../components/grid/useGridState";
import { GridApi, SelectionChangedEvent } from "ag-grid-community";
import { ExplainTrade } from "../explain-trade/ExplainTrade";
import { Toolbar } from "../../components/toolbar/Toolbar";
import { KnowledgeBaseHelperTopics, ToolbarFeatures, Trade } from "../../types";
import { FaRegLightbulb } from "react-icons/fa";
import Uploads from "../uploads/Uploads";
import EditTradeModal from "../../components/grid/utils/contextMenu/EditTradeModal";

const Transactions = () => {
  const controller = useTransactionController();
  const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
  const [showSideBar, setShowSideBar] = React.useState<boolean>(true);
  const scrollHoldRef = useRef(null);

  const gridRef = useRef<GridApi | null>(null);

  const gridState = useGridState({ gridName: "Transactions" });

  const onGridReady = useCallback((e: GridReadyEvent) => {
    gridRef.current = e.api;
    gridState.onGridReady(e);
  }, []);

  // clear selected rows when explain trade is clicked
  const onExplainTrade = () => {
    if (gridRef.current) {
      const nodes = gridRef.current.getSelectedNodes();
      for (const node of nodes) {
        node.setSelected(false);
      }

      setTimeout(() => {
        setSelectedRows([]);
      }, 10);
    }
  };

  const onSelectionChanged = (e: SelectionChangedEvent) => {
    const selectedRows = e.api.getSelectedRows();

    setSelectedRows(selectedRows);
  };

  const {
    isOpen: isEditModalOpen,
    onOpen: onOpenEditModal,
    onClose: onCloseEditModal,
  } = useDisclosure();

  const { getContextMenuItems, selectedParams } = useGridContextMenuController(
    () => {},
    () => {},
    () => {},
    () => {},
    onOpenEditModal,
    onCloseEditModal
  );

  return (
    <Flex h="full" direction="column">
      <EditTradeModal
        isOpen={isEditModalOpen}
        onClose={onCloseEditModal}
        onConfirm={onCloseEditModal}
        selectedParams={selectedParams}
      />

      {/* To avoid AGGrid bouncing to bottom of page after a modal is closed... */}
      <Button ref={scrollHoldRef} h="0"></Button>

      <Toolbar
        enabledOptions={[
          ToolbarFeatures.FILTER_SELECTOR,
          ToolbarFeatures.UNDERLYING,
          ToolbarFeatures.HELP,
          ToolbarFeatures.PRICING_QUEUE,
          ToolbarFeatures.TAGS,
          ToolbarFeatures.CLEAR,
          // ToolbarFeatures.DATE_RANGE,
          ToolbarFeatures.STRATEGY,

          ToolbarFeatures.TOGGLE_UNBOOKED_AND_NO_POS_GROUP,
        ]}
        helpKey={KnowledgeBaseHelperTopics.TRANSACTIONS}
        transactionsController={controller}
      />

      {controller.trades.length === 0 ? (
        <Center h="70%">
          <VStack>
            <FaRegLightbulb size="50" mb="10px" />
            <Heading size="md">No Transactions Found</Heading>
            <Text maxW="300px" textAlign={"center"} mb="20px">
              Please add your trades to see transactions.
            </Text>
            <Uploads />
          </VStack>
        </Center>
      ) : (
        <Allotment separator={true}>
          <GridContainer>
            <AgGridReact
              context={{ instruments: controller.instruments }}
              columnDefs={columnDefinitions} // Column Defs for Columns
              groupSelectsChildren={true}
              rowData={controller.trades} // Row Data for Rows
              columnTypes={columnTypes} // Optional - custom column types
              rowGroupPanelShow="always"
              rowSelection="multiple" // Options - allows click selection of rows
              rowHeight={31}
              suppressAggFilteredOnly={false}
              suppressAggFuncInHeader={true}
              suppressChangeDetection={false}
              rowDragManaged={false}
              rowDragEntireRow={isMobile}
              animateRows={true}
              rowDragMultiRow={true}
              getContextMenuItems={getContextMenuItems}
              aggFuncs={{
                distinct: distinctValue,
              }}
              autoGroupColumnDef={{
                cellRendererParams: {
                  suppressCount: true,
                  innerRenderer: TransactionsInnerRenderer,
                },
                checkboxSelection: true,
                sortable: false,
                resizable: true,
                initialWidth: 600,
                headerName: "Book",
              }}
              suppressRowClickSelection={true}
              sideBar={{
                toolPanels: [
                  {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                      suppressPivotMode: true,
                      suppressValues: true,
                      suppressRowGroups: true,
                    },
                  },
                ],
                position: "left",
                defaultToolPanel: "filters",
              }}
              onRowDragMove={controller.onRowDragMove}
              onRowDragEnd={controller.onRowDragEnd}
              getRowId={controller.getRowId}
              loadingOverlayComponent={controller.loadingOverlayComponent}
              loadingOverlayComponentParams={
                controller.loadingOverlayComponentParams
              }
              noRowsOverlayComponent={controller.noRowsOverlayComponent}
              noRowsOverlayComponentParams={
                controller.noRowsOverlayComponentParams
              }
              onRowGroupOpened={(params) => {
                gridState.performGroupChanged(params);
              }}
              onSelectionChanged={onSelectionChanged}
              onGridReady={onGridReady}
              onFirstDataRendered={gridState.onFirstDataRendered}
              onColumnEverythingChanged={gridState.onColumnChanged}
              onColumnResized={gridState.onColumnResized}
              onSortChanged={gridState.onColumnChanged}
              onColumnMoved={gridState.onColumnMoved}
            />
          </GridContainer>

          <Allotment.Pane visible={showSideBar} maxSize={550}>
            <Flex w="full" h="full" p="2px" ml="1px">
              <ExplainTrade
                trades={selectedRows}
                onExplainTrade={onExplainTrade}
              />
            </Flex>
          </Allotment.Pane>
        </Allotment>
      )}
    </Flex>
  );
};

export default Transactions;
