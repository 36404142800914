import {
  Box,
  Button,
  Heading,
  HStack,
  IconButton,
  Spinner,
  Text,
  VStack,
  Select,
  Input,
  useToast,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
} from "@chakra-ui/react";
import React, { useEffect, useState, useCallback } from "react";
import { createAPIKey, deleteAPIKey, getAPIKeys } from "../../api/APIKeys";
import { useAtomValue } from "jotai";
import { AccountAtom, AppStateAtom } from "../../store";
import { BiTrash, BiCopy } from "react-icons/bi";
import { DashboardLayoutStateStore } from "../../store/DashboardLayout";
import { getAllLayoutsForUser } from "../../api";
import { useLayout } from "../../features/dashboard/helpers/Layout";
import { DashboardConfiguration } from "../../types/DashboardConfiguration";
import { IoEye } from "react-icons/io5";

export default function ManageAPIKeys() {
  const [apiKeysList, setApiKeysList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedAccountId, setSelectedAccountId] = useState("");
  const [selectedDashboardId, setSelectedDashboardId] = useState([]);
  const [layouts, setLayouts] = useState<DashboardConfiguration[]>([]);
  const [selectedAPIKey, setSelectedAPIKey] = useState("");
  const [embedLink, setEmbedLink] = useState("");
  const appState = useAtomValue(AppStateAtom);
  const accountState = useAtomValue(AccountAtom);
  const layoutState = useAtomValue(DashboardLayoutStateStore);
  const toast = useToast();
  const footerScript = ` <script> const iframe = document.getElementById("contentFrame"); iframe.onload = () => { iframe.contentWindow.postMessage("getHeight", "*"); }; window.addEventListener("message", (event) => { if (event.origin === "${window.location.origin}") { const newHeight = event.data.height; iframe.style.height = newHeight + "px"; } }); </script>`;
  // Fetch keys and handle loading state
  const fetchKeys = useCallback(async () => {
    setLoading(true);
    const keys = await getAPIKeys();
    getDashboards();
    setApiKeysList(keys || []);
    setLoading(false);
  }, []);

  const getDashboards = useCallback(async () => {
    const layouts = await getAllLayoutsForUser(appState.user.id);
    setLayouts(layouts);
  }, []);

  // Handle key deletion with confirmation
  const handleDeleteKey = async (id) => {
    const confirmation = window.prompt(
      "Are you sure you want to delete this key? Type 'DELETE' to confirm."
    );
    if (confirmation === "DELETE") {
      await deleteAPIKey(appState.user.id, id);
      fetchKeys();
    }
  };

  // Handle API key creation
  const handleCreateKey = async () => {
    await createAPIKey(appState.user.id);
    fetchKeys();
  };

  // Generate embed link when account, dashboard, or API key changes
  useEffect(() => {
    if (selectedAccountId && selectedDashboardId && apiKeysList.length > 0) {
      const link = `${window.location.origin}/embed?pid=${selectedAccountId}&did=${selectedDashboardId}&apikey=${selectedAPIKey}`;
      setEmbedLink(link);
    }
  }, [selectedAccountId, selectedDashboardId, apiKeysList, selectedAPIKey]);

  useEffect(() => {
    fetchKeys();
  }, [fetchKeys]);

  // Copy embed link to clipboard
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast({
      title: "Copied to clipboard",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  if (!appState?.featureFlags?.includes("api_keys")) {
    return (
      <Box
        width="xl"
        maxW="100vw"
        bg="var(--dark-gray)"
        shadow="base"
        p={5}
        rounded="lg"
      >
        <Heading as="h3" size="lg" color="var(--white)" mb={5}>
          API Keys
        </Heading>
        <Text>
          API Keys are a premium feature that allows you to embed your dashboard
          into 3rd party websites. Please contact support to arrange access.
        </Text>
      </Box>
    );
  }

  return (
    <Box>
      <Box
        width="xl"
        maxW="100vw"
        bg="var(--dark-gray)"
        shadow="base"
        p={5}
        rounded="lg"
      >
        <Heading as="h3" size="lg" color="var(--white)" mb={5}>
          API Keys
        </Heading>

        <VStack spacing={4}>
          {loading ? (
            <Spinner />
          ) : (
            apiKeysList.map((key) => (
              <HStack
                key={key.id}
                width="full"
                bg="var(--dark-gray)"
                shadow="sm"
                justifyContent="space-between"
                rounded="lg"
                border="1px solid var(--gray)"
                p="2"
              >
                <VStack align="start">
                  <Text color="var(--white)" isTruncated>
                    Key: {key.key}
                  </Text>

                  <HStack>
                    <Text color="var(--gray-light)" fontSize="sm">
                      Usage: {key.count || 0}
                    </Text>
                    <Tag colorScheme="blue">{key.type}</Tag>
                  </HStack>
                </VStack>

                <IconButton
                  aria-label="Delete API Key"
                  icon={<BiTrash fill="var(--red)" />}
                  onClick={() => handleDeleteKey(key.id)}
                />
              </HStack>
            ))
          )}

          <Button w="full" onClick={handleCreateKey}>
            Generate new key
          </Button>
        </VStack>
      </Box>
      <Box
        mt="20px"
        width="xl"
        maxW="100vw"
        bg="var(--dark-gray)"
        shadow="base"
        p={5}
        rounded="lg"
      >
        <VStack alignItems={"flex-start"} w="full">
          <Heading as="h3" size="lg" color="var(--white)">
            Embed Dashboard
          </Heading>

          <HStack width="full" spacing={4}>
            <VStack align="start" width="50%">
              <Heading as="h3" size="sm" color="var(--white)">
                Select Account
              </Heading>
              <Select
                placeholder="Select account"
                value={selectedAccountId}
                onChange={(e) => setSelectedAccountId(e.target.value)}
              >
                {accountState.accounts.map((account) => (
                  <option key={account.id} value={account.id}>
                    {account.name}
                  </option>
                ))}
              </Select>
            </VStack>

            <VStack align="start" width="50%">
              <Heading as="h3" size="sm" color="var(--white)">
                Select Dashboard
              </Heading>
              <Select
                placeholder="Select dashboard"
                value={selectedDashboardId}
                onChange={(e) => setSelectedDashboardId(e.target.value)}
              >
                {layouts.map((configuration) => (
                  <option
                    key={configuration.id}
                    value={configuration.configurationId}
                  >
                    {configuration.name}
                  </option>
                ))}
              </Select>
            </VStack>
            <VStack align="start" width="50%">
              <Heading as="h3" size="sm" color="var(--white)">
                Select API Key
              </Heading>
              <Select
                placeholder="Select API key"
                value={selectedAPIKey}
                onChange={(e) => setSelectedAPIKey(e.target.value)}
              >
                {apiKeysList.map((key) => (
                  <option key={key.id} value={key.key}>
                    {key.key}
                  </option>
                ))}
              </Select>
            </VStack>
          </HStack>
        </VStack>

        <VStack width="full" spacing={4} alignItems={"flex-start"}>
          <Heading as="h3" size="sm" color="var(--white)" mt="40px">
            Paste this code within your web page
          </Heading>

          <Input
            isDisabled={embedLink === ""}
            value={`<iframe id="contentFrame"  src="${embedLink}" width="100%" allowfullscreen frameborder="0"></iframe>`}
            isReadOnly
            size="md"
          />

          <Button
            isDisabled={embedLink === ""}
            w="full"
            leftIcon={<BiCopy />}
            onClick={() =>
              copyToClipboard(
                `<iframe id="contentFrame" src="${embedLink}" width="100%" allowfullscreen frameborder="0"></iframe>`
              )
            }
          >
            Copy Embed Code
          </Button>

          <Heading as="h3" size="sm" color="var(--white)" mt="20px">
            Paste this code within your page underneath the iframe
          </Heading>

          <Input
            isDisabled={embedLink === ""}
            value={footerScript}
            isReadOnly
            size="md"
          />

          <Button
            isDisabled={embedLink === ""}
            w="full"
            leftIcon={<BiCopy />}
            onClick={() => copyToClipboard(footerScript)}
          >
            Copy Footer Script
          </Button>

          <Button
            isDisabled={
              embedLink === "" ||
              !selectedAccountId ||
              !selectedDashboardId ||
              !selectedAPIKey
            }
            onClick={() => {
              window.open(embedLink, "_blank");
            }}
            w="full"
          >
            <IoEye />
            <Text pl="10px">View preview</Text>
          </Button>
        </VStack>
      </Box>
    </Box>
  );
}
