import {
  Box,
  Center,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  LeftArrowIcon,
  PrimaryButton,
  RightArrowIcon,
} from "../../../components/design_library";
import React from "react";
import CTAHeaderText from "../../../components/homepage/CTAHeaderText";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./styles.css";
import {
  BiAbacus,
  BiBell,
  BiCloudLightRain,
  BiRightArrowAlt,
  BiShoppingBag,
} from "react-icons/bi";
const Hero = (props: any) => {
  return (
    <>
      <Box display={"flex"} justifyContent={"center"}>
        <VStack display={"flex"} w="8xl" flexWrap={"wrap"}>
          <CTAHeaderText />

          <Box
            flex="8"
            mt="40px"
            minH={{ md: "1200px", lg: "600px" }}
            minW={{ base: "300px", md: "400px", lg: "400px" }}
            mb="0px"
          >
            <Box zIndex={999}>
              <Box color="white" pt="10px" pb="50px">
                <Box
                  width={{
                    base: "100%",
                    md: "100%",
                    lg: "100%",
                    xl: "100%",
                  }}
                  maxWidth="100vw"
                  justifyContent="center"
                  alignItems="center"
                  margin="auto"
                  marginTop="25px"
                  px="5vw"
                >
                  <Carousel
                    infiniteLoop={true}
                    autoPlay={true}
                    interval={15000}
                    showStatus={false}
                    thumbWidth={160}
                    useKeyboardArrows={true}
                    stopOnHover={true}
                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                      hasPrev && (
                        <button
                          type="button"
                          onClick={onClickHandler}
                          className="control-arrow control-prev"
                        >
                          <LeftArrowIcon />
                        </button>
                      )
                    }
                    renderArrowNext={(onClickHandler, hasNext, label) =>
                      hasNext && (
                        <button
                          type="button"
                          onClick={onClickHandler}
                          className="control-arrow control-next"
                        >
                          <RightArrowIcon />
                        </button>
                      )
                    }
                  >
                    <div>
                      <picture>
                        <img
                          className="caroselImage"
                          alt="Demo image of options tracker 1"
                          src="https://cdn.wealthbee.io/main/app/homepage/dashboard.png"
                        />
                        <p className="customLegend">
                          The dashboard where you can view portfolio, position,
                          book, tag and strategy level analytics
                        </p>
                      </picture>
                    </div>

                    <div>
                      <picture>
                        <img
                          className="caroselImage"
                          alt="Demo image of options tracker 1"
                          src="https://cdn.wealthbee.io/main/app/homepage/positions.png"
                        />
                        <p className="customLegend">
                          The positions page where you can group and view all
                          your positions as well as drilling into them.
                        </p>
                      </picture>
                    </div>

                    <div>
                      <picture>
                        <img
                          className="caroselImage"
                          alt="Demo image of options tracker 3"
                          src="https://cdn.wealthbee.io/main/app/homepage/charts.png"
                        />
                        <p className="customLegend">
                          View all your trades mapped onto a trading view chart,
                          journal and draw trend lines and annotations.
                        </p>
                      </picture>
                    </div>

                    <div>
                      <picture>
                        <img
                          className="caroselImage"
                          alt="Demo image of options tracker 4"
                          src="https://cdn.wealthbee.io/main/app/homepage/brokers.png"
                        />
                        <p className="customLegend">
                          Connect your broker and import your trades and
                          positions automatically with our broker integrations.
                        </p>
                      </picture>
                    </div>

                    <div>
                      <picture>
                        <img
                          className="caroselImage"
                          alt="Demo image of options tracker 5"
                          src="https://cdn.wealthbee.io/main/app/homepage/journal.png"
                        />
                        <p className="customLegend">
                          The journal where you can add notes and tags to your
                          trades and positions.
                        </p>
                      </picture>
                    </div>

                    <div>
                      <picture>
                        <img
                          className="caroselImage"
                          alt="Demo image of options tracker 6"
                          src="https://cdn.wealthbee.io/main/app/homepage/calendar.png"
                        />
                        <p className="customLegend">
                          View your P&L on a calendar view and add notes and
                          tags to your trades and positions.
                        </p>
                      </picture>
                    </div>
                  </Carousel>

                  <HStack justify={"center"} alignItems="center" mt="22px">
                    <PrimaryButton
                      id="cta_1"
                      as="a"
                      href="../features"
                      onClick={() => {
                        localStorage.setItem("ftk", "true");
                      }}
                      color={"var(--white)"}
                      border="none !important"
                      bg="var(--accent-dark) !important"
                      mt="20px"
                      fontSize="18px !important"
                      minW="300px"
                      h="60px"
                    >
                      View all of our features
                      <Box pl="6px">
                        <BiBell />
                      </Box>
                    </PrimaryButton>
                  </HStack>
                </Box>
              </Box>
            </Box>
          </Box>
        </VStack>
      </Box>
    </>
  );
};

export default Hero;
