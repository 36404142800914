import { useCallback, useState } from "react";
import Big from "big.js";
import { useAtomValue } from "jotai";
import { Instrument } from "../../../../types";
import { AccountAtom } from "../../../../store";
import { calculatePrice } from "../../../../api";

interface PricingResult {
  theoreticalPrice?: number;
  impliedVolatility?: number;
  greeks?: {
    delta: number;
    gamma: number;
    theta: number;
    vega: number;
    rho: number;
    phi: number;
    iv: number;
  };
}
interface UsePricingToolController {
  pricingResult?: PricingResult;
  calc: (
    underlyingPrice: number,
    marketPrice: number,
    strike: number,
    expiry: Date,
    vol: number,
    optionType: string
  ) => void;
}

export const usePricingToolController = (
  instrument: Instrument | undefined
): UsePricingToolController => {
  const account = useAtomValue(AccountAtom);

  const [result, setResult] = useState<PricingResult>();

  const calc = useCallback(
    async (
      underlyingPrice: number,
      marketPrice: number,
      strike: number,
      expiry: Date,
      vol: number,
      optionType: string
    ) => {
      try {
        const result = await calculatePrice(
          account.selectedAccount.id,
          marketPrice,
          underlyingPrice,
          vol,
          strike,
          expiry,
          optionType
        );

        if (result) {
          setResult({
            theoreticalPrice: result.price,
            impliedVolatility: result.impliedVolatility,
            greeks: result?.greeks,
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    [instrument, account]
  );

  return {
    pricingResult: result,
    calc,
  };
};
