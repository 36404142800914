import {
  Box,
  Heading,
  Image,
  ListItem,
  OrderedList,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Component } from "react";
import { Panel } from "../../../../../components/design_library";
import React from "react";

export default class WealthBeeInstructions extends Component {
  render() {
    return (
      <Panel>
        <Stack spacing="5">
          <Text>
            Follow the steps below to export your trades from Ally for
            WealthBee:
          </Text>
          <OrderedList spacing={3}>
            <ListItem>Log in to your Ally Invest online account.</ListItem>
            <ListItem>Select your account.</ListItem>
            <ListItem>
              Navigate to <strong>My Accounts</strong>, then{" "}
              <strong>Account Summary</strong>, and then{" "}
              <strong>Activity</strong>.
            </ListItem>
            <ListItem>
              Choose the date range you need (maximum of 366 days per download).
            </ListItem>
            <ListItem>
              Click the <strong>Find</strong> button to display the data, then
              select <strong>Export to Excel</strong> to begin the download.
            </ListItem>
            <ListItem>
              Save the file to a designated WealthBee import folder on your
              device.
            </ListItem>
            <ListItem>
              Repeat the above steps to download additional CSV files if needed
              to cover more than one year.
            </ListItem>
            <ListItem>
              Open WealthBee and go to the Add assets section.
            </ListItem>
            <ListItem>
              Use the <strong>From File</strong> option under the Account tab,
              select the files you downloaded, and click <strong>Open</strong>.
            </ListItem>
            <ListItem>
              WealthBee will prompt you to save the imported data. Click{" "}
              <strong>Yes</strong>.
            </ListItem>
          </OrderedList>
        </Stack>
      </Panel>
    );
  }
}
