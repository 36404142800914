import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";

interface ExpirePositionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  closingFees: string;
  setClosingFees: (value: string) => void;
  closingCommission: string;
  setClosingCommission: (value: string) => void;
  closingNotes: string;
  setClosingNotes: (value: string) => void;
}

const ExpirePositionModal: React.FC<ExpirePositionModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  closingFees,
  setClosingFees,
  closingCommission,
  setClosingCommission,
  closingNotes,
  setClosingNotes,
}) => {
  const handleNumericInput = (
    value: string,
    setter: (value: string) => void
  ) => {
    // Allow only numbers and a single decimal point
    if (/^\d*\.?\d*$/.test(value)) {
      setter(value);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="var(--bg)">
        <ModalHeader>Expire Position</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Closing Fees</FormLabel>
            <Input
              placeholder="0.00"
              value={closingFees}
              onChange={(e) =>
                handleNumericInput(e.target.value, setClosingFees)
              }
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Closing Commission</FormLabel>
            <Input
              placeholder="0.00"
              value={closingCommission}
              onChange={(e) =>
                handleNumericInput(e.target.value, setClosingCommission)
              }
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Closing Notes</FormLabel>
            <Input
              placeholder="Enter notes for trade..."
              value={closingNotes}
              onChange={(e) => setClosingNotes(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button bg="var(--accent)" mr={3} onClick={onConfirm}>
            Confirm Expire
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ExpirePositionModal;
