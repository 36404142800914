import React from "react";
import { Box, Flex } from "@chakra-ui/react";

export const TagsColumn = (props: any) => {
  if (props.value === undefined || (props.value?.length ?? 0) === 0) {
    return <></>;
  }

  const values = Array.isArray(props.value)
    ? props.value
    : Array.from(new Set(props.value.split(",") ?? [])) ?? [];

  return (
    <Flex
      justifyContent="flex-start"
      alignItems="center"
      height="36px"
      lineHeight="100%"
      pt="2px"
    >
      {values.map((x: string, idx: number) => {
        const bgColour = idx % 2 === 0 ? "gray.600" : "blue.700";
        return (
          <Box
            key={x}
            height="20px"
            lineHeight="100%"
            fontSize="14px"
            fontWeight={400}
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="var(--white)"
            padding="4px 12px 4px 12px"
            borderRadius="24px"
            bg={bgColour}
            mr="2px"
          >
            {x}
          </Box>
        );
      })}
    </Flex>
  );
};
