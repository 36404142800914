import { useAtomValue } from "jotai";
import { useEffect, useMemo, useRef, useState } from "react";
import { AccountAtom, TransactionsAtom } from "../../store";
import { useTransactions } from "../../hooks/useTransactions";
import { CashFlow, CashFlowEx } from "../../types/Cashflow";
import { useInstruments } from "../../hooks";
import { useFilters } from "../../hooks/useFilters";

interface UseCashFlowController {
  cashflows: CashFlowEx[];
  gridApiRef: any;
  underlyings: string[];
  selectedUnderlying?: string;
  setSelectedUnderlying: (underlying: string | undefined) => void;
}

export const useCashflowController = (): UseCashFlowController => {
  const useTransactionsHook = useTransactions();
  const instruments = useInstruments();
  const filtersHook = useFilters();
  const gridApiRef = useRef<any>(null);
  const initialised = useRef<boolean>(false);
  const [selectedUnderlying, setSelectedUnderlying] = useState<
    string | undefined
  >(undefined);

  const accountState = useAtomValue(AccountAtom);
  const transactions = useAtomValue(TransactionsAtom);
  const [cashflows, setCashflows] = useState<CashFlowEx[]>([]);

  useEffect(() => {
    if (!initialised.current) {
      initialised.current = true;
      if (transactions.cashflows.length === 0) {
        useTransactionsHook.getTradesAndCashflows(
          accountState?.selectedAccount?.id
        );
      }
    }
  }, [
    useTransactionsHook,
    accountState?.selectedAccount?.id,
    transactions.cashflows.length,
  ]);

  const underlyings: string[] = useMemo(() => {
    const ids = Array.from(
      new Set(transactions.cashflows.map((x) => x?.instrumentId))
    );

    const result = ids.map(
      (id) => instruments.instruments.find((x) => x.id === id)?.name ?? ""
    );
    return result.filter((name) => name).sort();
  }, [transactions.cashflows, instruments.instruments]);

  useEffect(() => {
    const data = transactions.cashflows
      .map((cashflow) => {
        const ins = instruments.instruments.find(
          (x) => x.id === cashflow.instrumentId
        );

        return {
          ...cashflow,
          instrument: ins,
        } as CashFlowEx;

        // TBD: This code is commented out because it needs to be thought through

        // if (
        //   filtersHook.selectedUnderlying === "All" ||
        //   filtersHook.selectedUnderlying === undefined ||
        //   filtersHook.selectedUnderlying === ""
        // ) {
        //   return {
        //     ...cashflow,
        //     instrument: ins,
        //   } as CashFlowEx;
        // } else {
        //   // console.log(
        //   //   "Ins:",
        //   //   ins?.symbol,
        //   //   "Fil:",
        //   //   filtersHook.selectedUnderlying
        //   // );
        //   if (ins?.symbol === filtersHook.selectedUnderlying) {
        //     return {
        //       ...cashflow,
        //       instrument: ins,
        //     } as CashFlowEx;
        //   } else {
        //     return null;
        //   }
        // }
      })
      .filter((cashflow) => cashflow !== null) as CashFlowEx[];

    setCashflows(data);
  }, [
    transactions.cashflows,
    instruments.instruments,
    filtersHook.selectedUnderlying,
  ]);

  return {
    cashflows,
    gridApiRef,
    underlyings,
    selectedUnderlying,
    setSelectedUnderlying,
  };
};
