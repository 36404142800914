import {
  Box,
  Heading,
  Image,
  ListItem,
  OrderedList,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Component } from "react";
import { Panel } from "../../../../../components/design_library";
import React from "react";

export default class FirstradeInstructions extends Component {
  render() {
    return (
      <Panel>
        <Stack spacing="5">
          <Text>Follow the steps below to export your trades:</Text>
          <OrderedList spacing={3}>
            <ListItem>Log in to your Firstrade account.</ListItem>
            <ListItem>
              Go to <strong>Accounts</strong>, then select{" "}
              <strong>History</strong>.
            </ListItem>
            <ListItem>
              Select the <strong>Download Account History</strong> tab.
            </ListItem>
            <ListItem>
              Choose the account, date range, and download format as{" "}
              <strong>Excel CSV Files</strong>.
            </ListItem>
            <ListItem>
              Click <strong>Download</strong> to export your trades.
            </ListItem>
          </OrderedList>
        </Stack>
      </Panel>
    );
  }
}
