import React, { useEffect } from "react";
import {
  Flex,
  Text,
  useDisclosure,
  HStack,
  Button,
  Box,
  Heading,
  Center,
  Spinner,
  VStack,
} from "@chakra-ui/react";
import { MdLightbulb } from "react-icons/md";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "@chakra-ui/react";
// import InsightsCreator from "./components/InsightsCreator";
import InsightSearchList from "./components/InsightSearchList";
import { useInsightsController } from "./useInsightsController";
import { SecondaryButton } from "../../../components/design_library";
import { InsightTemplate, InsightType } from "../../../types/Insight";
import InsightsCreator from "./components/InsightsCreator";
import { useAtomValue } from "jotai";
import { AppStateAtom, TransactionsAtom } from "../../../store";
import { AddIcon } from "@chakra-ui/icons";
import { FaFlask } from "react-icons/fa";
import { IoBulb } from "react-icons/io5";
import { DashboardLayoutStateStore } from "../../../store/DashboardLayout";

const InsightsManager: React.FC = () => {
  const controller = useInsightsController();

  const appState = useAtomValue(AppStateAtom);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handlePageChange = (page: "insights" | "creator") => {
    controller.setPage(page);
  };

  const isUserAdmin = appState.user?.role === "admin";

  return (
    <Flex>
      <SecondaryButton onClick={onOpen} bg="var(--accent)" h="45px">
        <Text
          display={{
            base: "none",
            lg: "block",
          }}
          className="addInsightsButton"
        >
          <HStack>
            <FaFlask />
            <Text>Insights</Text>
          </HStack>
        </Text>
        <Text
          ml="2"
          mr="2"
          display={{
            lg: "none",
            base: "block",
          }}
        >
          <FaFlask />
        </Text>
      </SecondaryButton>

      <Modal onClose={onClose} size="4xl" isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent bg="var(--dark-gray)">
          <HStack pr="12px">
            <HStack justifyContent="space-between" w="full">
              <ModalHeader flex="3" pl="4">
                <Heading fontSize="xxl">Insights Marketplace</Heading>
                <Text fontSize="sm">
                  Enable the insights you want to track for your account.
                </Text>
              </ModalHeader>

              <Box h="20px" />
              {isUserAdmin && (
                <Box>
                  {controller.page === "insights" ? (
                    <SecondaryButton
                      size="sm"
                      onClick={() => {
                        controller.setInsightData({
                          title: "",
                          type: InsightType.Number,
                          query: "",
                          description: "",
                          tags: [],
                          isPublic: false,
                          version: 1,
                        });
                        handlePageChange("creator");
                      }}
                      color="var(--red)"
                    >
                      Create your own insight (Admin Only)
                    </SecondaryButton>
                  ) : (
                    <SecondaryButton
                      onClick={() => handlePageChange("insights")}
                    >
                      Insight Marketplace
                    </SecondaryButton>
                  )}
                </Box>
              )}
            </HStack>
          </HStack>
          <ModalBody p="0" mt="10px">
            {controller.page === "insights" ? (
              <InsightSearchList
                setPage={handlePageChange}
                setInsightData={controller.setInsightData}
              />
            ) : (
              <InsightsCreator
                setPage={controller.setPage}
                insightsData={controller.insightData as InsightTemplate}
                setInsightData={controller.setInsightData}
              />
            )}
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default InsightsManager;
