import { ColDef } from "ag-grid-community";
import { ColumnTypeEnum, createGroupColumn } from "../../components";

export const columnDefinitions: ColDef[] = [
  createGroupColumn("Underlying", "underlyingSymbol", ColumnTypeEnum.Text, {
    rowGroup: true,
    hide: true,
    sort: "asc",
    rowDrag: true,
    menuTabs: ["generalMenuTab"],
  }),

  createGroupColumn("Symbol", "symbol", ColumnTypeEnum.Text, {
    valueGetter: (params) => {
      try {
        return params.data.symbol?.replaceAll("_", " ");
      } catch (e) {
        return "N/A";
      }
    },
    sort: "asc",
    menuTabs: ["generalMenuTab"],
  }),

  createGroupColumn("Type", "type", ColumnTypeEnum.Pill, {
    cellRendererParams: {
      exactMatch: true,
      colourMap: {
        Option: "var(--accent)",
        Equity: "var(--trade-stock)",
      },
      defaultColour: "var(--accent)",
    },
    menuTabs: ["generalMenuTab"],
  }),

  createGroupColumn("Put/Call", "putCall", ColumnTypeEnum.Pill, {
    cellRendererParams: {
      exactMatch: true,
      colourMap: {
        Put: "var(--trade-call)",
        Call: "var(--trade-put)",
      },
      defaultColour: "var(--accent)",
    },
    menuTabs: ["generalMenuTab"],
  }),

  createGroupColumn("Strike", "strike", ColumnTypeEnum.Numeric, {
    menuTabs: ["generalMenuTab"],
  }),
  createGroupColumn("Expiry Date", "expiry", ColumnTypeEnum.Date, {
    menuTabs: ["generalMenuTab"],
  }),

  createGroupColumn("Current Price", "currentPrice", ColumnTypeEnum.Currency, {
    cellRendererParams: {
      noFormatting: true,
    },
    menuTabs: ["generalMenuTab"],
    valueFormatter: (params) => {
      if (!params.data || !params.data.currentPrice) return "";
      return params.data.currentPrice?.toFixed(4);
    },
    cellRenderer: "numericCellRenderer",
  }),

  createGroupColumn("Price Type", "priceType", ColumnTypeEnum.Text, {
    menuTabs: ["generalMenuTab"],
  }),

  createGroupColumn("User Price", "userPrice", ColumnTypeEnum.Currency, {
    cellRendererParams: {
      noFormatting: true,
    },
    menuTabs: ["generalMenuTab"],
    cellRenderer: "numericCellRenderer",
  }),

  createGroupColumn("User Expiry", "userExpiry", ColumnTypeEnum.Date, {
    sort: "asc",
    menuTabs: ["generalMenuTab"],
  }),
];
