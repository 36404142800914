import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  HStack,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ToggleButton } from "./ToggleButton";
import { useNavigate } from "react-router";
import Uploads from "../uploads/Uploads";
import React from "react";
import AccountSelector from "../../components/account/AccountSelector";
import {
  RiCalendar2Fill,
  RiCashFill,
  RiDashboardFill,
  RiExchangeBoxFill,
  RiPriceTag2Fill,
  RiRoadMapFill,
} from "react-icons/ri";

export const MobileDrawer = () => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const navigate = useNavigate();
  return (
    <>
      <Drawer placement="top" isOpen={isOpen} onClose={onClose}>
        <DrawerContent>
          <DrawerBody p="4" backgroundColor={"var(--bg)"}>
            <Stack spacing="1">
              <Button
                w="full"
                size="lg"
                variant="tertiary"
                onClick={() => {
                  navigate("/app/dashboard");
                  onToggle();
                }}
                color={
                  window.location.pathname === "/app/dashboard"
                    ? "var(--accent)"
                    : ""
                }
              >
                <RiDashboardFill />
                <Text pl="8px">Dashboard</Text>
              </Button>
              <Button
                size="lg"
                variant="tertiary"
                onClick={() => {
                  navigate("/app/positions");
                  onToggle();
                }}
                color={
                  window.location.pathname === "/app/positions"
                    ? "var(--accent)"
                    : ""
                }
              >
                <RiRoadMapFill />
                <Text pl="8px">Positions</Text>
              </Button>
              <Button
                size="lg"
                variant="tertiary"
                onClick={() => {
                  navigate("/app/transactions");
                  onToggle();
                }}
                color={
                  window.location.pathname === "/app/transactions"
                    ? "var(--accent)"
                    : ""
                }
              >
                <RiExchangeBoxFill />
                <Text pl="8px">Transactions</Text>
              </Button>
              <Button
                size="lg"
                variant="tertiary"
                onClick={() => {
                  navigate("/app/cashflows");
                  onToggle();
                }}
                color={
                  window.location.pathname === "/app/cashflows"
                    ? "var(--accent)"
                    : ""
                }
              >
                <RiCashFill />
                <Text pl="8px">Cashflows</Text>
              </Button>
              <Button
                size="lg"
                variant="tertiary"
                onClick={() => {
                  navigate("/app/calendar");
                  onToggle();
                }}
                color={
                  window.location.pathname === "/app/calendar"
                    ? "var(--accent)"
                    : ""
                }
              >
                <RiCalendar2Fill />
                <Text pl="8px">Calendar</Text>
              </Button>
              <Button
                size="lg"
                variant="tertiary"
                onClick={() => {
                  navigate("/app/prices");
                  onToggle();
                }}
                color={
                  window.location.pathname === "/app/prices"
                    ? "var(--accent)"
                    : ""
                }
              >
                <RiPriceTag2Fill />
                <Text pl="8px">Prices</Text>
              </Button>
              <Button
                size="lg"
                variant="tertiary"
                onClick={() => {
                  navigate("/app/settings");
                  onToggle();
                }}
                color={
                  window.location.pathname === "/app/settings"
                    ? "var(--accent)"
                    : ""
                }
              >
                Settings
              </Button>
              <VStack
                mb="10px"
                w="full"
                display="flex"
                justifyContent={"center"}
              >
                <Uploads />

                <AccountSelector />
              </VStack>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <ToggleButton
        isOpen={isOpen}
        onClick={onToggle}
        aria-label="Open menu"
        display={{ base: "inline-flex", xl: "none" }}
      />
    </>
  );
};
