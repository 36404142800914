// export const createAccount = async (account: Account) => {
//     const token = localStorage.getItem("token");

import APIEndpoint from "./APIEndpoint";

//     return fetch(`${APIEndpoint}/accounts`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `${token || ""}`,
//       },
//       body: JSON.stringify(account),
//     })
//       .then((response) => response.json())
//       .catch((error) => console.error("Error creating account:", error));
//   };

export const createAPIKey = async (userId: string) => {
  const token = localStorage.getItem("token");

  return fetch(`${APIEndpoint}/apikeys`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token || ""}`,
    },
    body: JSON.stringify({ userId }),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error creating API key:", error));
};

export const deleteAPIKey = async (userId: string, apiKeyId: string) => {
  const token = localStorage.getItem("token");

  return fetch(`${APIEndpoint}/apikeys`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token || ""}`,
    },
    body: JSON.stringify({ userId, apiKeyId }),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error deleting API key:", error));
};

export const getAPIKeys = async () => {
  const token = localStorage.getItem("token");

  return fetch(`${APIEndpoint}/apikeys`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token || ""}`,
    },
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error getting API keys:", error));
};

export const checkApiKeyIsValid = async (apiKey: string) => {
  const token = localStorage.getItem("token");

  return fetch(`${APIEndpoint}/apikeys/validity`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token || ""}`,
      apikey: apiKey,
    },
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error checking API key:", error));
};
