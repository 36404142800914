import React from "react";
import { InsightHeader } from "./InsightHeader";
import { InsightTemplate } from "../../types/Insight";
import { InsightRenderer } from "../../features/dashboard/canvas/insight/InsightRenderer";
import InsightWrapper from "./InsightWrapper";
import { Box } from "@chakra-ui/react";

export const InsightContainer = ({
  insight,
  demo,
  embedded,
}: {
  insight: InsightTemplate | null;
  demo?: boolean;
  embedded?: boolean;
}) => {
  if (!insight) return null;
  return (
    <InsightWrapper>
      <InsightHeader insight={insight} embedded={embedded} />
      <InsightRenderer insight={insight} demo={demo} />
    </InsightWrapper>
  );
};
