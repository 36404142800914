import { useAtom } from "jotai";
import { AccountAtom } from "../store";
import { Account } from "../types/Account";
import { getAccounts } from "../api";

export interface UseAccount {
  setSelectedAccount: (account: Account) => void;
  retrieveAccounts: () => void;
  setDefaultDashboardConfiguration: (id: number) => void;
  setAccountName: (name: string) => void;
}

export const useAccount = (): UseAccount => {
  const [accountState, setAccountState] = useAtom(AccountAtom);

  const setSelectedAccount = (account: Account) => {
    localStorage.setItem("selectedAccount", JSON.stringify(account));
    setAccountState((prevState) => {
      return {
        ...prevState,
        selectedAccount: account,
      };
    });
  };

  const setDefaultDashboardConfiguration = (id: number) => {
    setAccountState((prevState) => {
      return {
        ...prevState,
        selectedAccount: {
          ...prevState.selectedAccount,
          defaultDashboardConfigurationId: id,
        },
      };
    });
  };

  const setAccountName = (name: string) => {
    setAccountState((prevState) => {
      return {
        ...prevState,
        selectedAccount: {
          ...prevState.selectedAccount,
          name: name,
        },
      };
    });
  };

  const retrieveAccounts = async () => {
    const result = await getAccounts();
    if (result && result.success) {
      const selectedAccount = localStorage.getItem("selectedAccount");
      setAccountState((prevState) => {
        return {
          ...prevState,
          accounts: result.accounts,
        };
      });
      setSelectedAccount(
        selectedAccount ? JSON.parse(selectedAccount) : result.accounts[0]
      );
    } else {
      setTimeout(() => {
        console.log("Retrying accounts...");
        retrieveAccounts();
      }, 1500);
    }
  };

  return {
    setSelectedAccount,
    retrieveAccounts,
    setAccountName,
    setDefaultDashboardConfiguration,
  };
};
