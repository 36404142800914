import { atom } from "jotai";
import { DashboardConfiguration } from "../types/DashboardConfiguration";

export interface DashboardLayoutState {
  layouts: {};
  breakpoints: string[];
  currentBreakpoint: string;
  gridLoading: boolean;
  enabledInsights: any[];
  configurations: DashboardConfiguration[];
  selectedConfiguration: number;
}

const buildInitialState = (): DashboardLayoutState => {
  const state: any = {
    configurations: [],
    selectedConfiguration: undefined,
    layouts: {
      lg: [],
      md: [],
      sm: [],
      xs: [],
      xxs: [],
      defaultState: true,
    },
    breakpoints: ["lg", "md", "sm", "xs", "xxs"],
    currentBreakpoint: "lg",
    gridLoading: true,
    enabledInsights: [],
  };

  return state;
};

export const DashboardLayoutStateStore = atom<DashboardLayoutState>(
  buildInitialState()
);

export const SelectedDashboardIdAtom = atom<number | undefined>(undefined);
