import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Text,
  Divider,
  Heading,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Spinner,
  HStack,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useMemo, useState } from "react";
import CalendarNoteEditor from "./CalendarNoteEditor";
import convertSymbolToReadable from "../grid/utils/convertSymbolToReadable";
import { useInstruments } from "../../hooks";
import currencyFormatter from "currency-formatter";
import { columnTypes, GridContainer } from "../grid";
import { AgGridReact } from "ag-grid-react";
import { tradeColumnDefinitions } from "../../features/positions/tradesview/tradeColumnDefinitions";
import { useGridState } from "../grid/useGridState";
import TradesAgGrid from "../../features/positions/tradesview/components/TradesAgGrid";
import { useTransactions } from "../../hooks/useTransactions";
import { TransactionsAtom } from "../../store";
import { useAtomValue } from "jotai";
import { Trade, TradeEx } from "../../types";
import PositionsAgGrid from "../../features/positions/tradesview/components/PositionsAgGrid";
import { Allotment } from "allotment";

export function CalendarNotesModal({
  isOpen,
  onClose,
  date,
  note,
  expiries,
  trades,
}: {
  isOpen: boolean;
  onClose: () => void;
  date: Date;
  note: {
    content: string;
    date: Date;
  };
  expiries: any;
  trades: any;
}) {
  const instruments = useInstruments();

  const updatedTrades: TradeEx[] = useMemo(() => {
    const filteredTrades = trades;

    let price = 0;
    return filteredTrades.map((trade: Trade) => {
      return {
        ...trade,
        instrument: trade.instrumentId
          ? instruments.instruments.find((x) => x.id === trade.instrumentId)
          : undefined,
        underlyingInstrument: trade.underlyingInstrumentId
          ? instruments.instruments.find(
              (x) => x.id === trade.underlyingInstrumentId
            )
          : instruments.instruments.find((x) => x.id === trade.instrumentId),
      } as TradeEx;
    });
  }, [trades]);

  const updatedExpiries: TradeEx[] = useMemo(() => {
    return expiries.map((trade: Trade) => {
      return {
        ...trade,
        instrument: trade.instrumentId
          ? instruments.instruments.find((x) => x.id === trade.instrumentId)
          : undefined,
        underlyingInstrument: trade.underlyingInstrumentId
          ? instruments.instruments.find(
              (x) => x.id === trade.underlyingInstrumentId
            )
          : instruments.instruments.find((x) => x.id === trade.instrumentId),
      } as TradeEx;
    });
  }, [expiries]);

  return (
    <Modal isOpen={isOpen} size="full" onClose={() => onClose()}>
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent bg={"var(--dark-gray)"}>
        <ModalHeader>{moment(date).format("Do MMMM YYYY")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="40px">
          <CalendarNoteEditor note={note} menuBarEnabled={true} />

          <Box h="100%">
            <Heading mt="40px" size="sm">
              Upcoming expiries on open positions
            </Heading>

            <PositionsAgGrid trades={updatedExpiries} />
          </Box>

          <Box h="100%">
            <Heading mt="40px" size="sm">
              Trades for this day
            </Heading>

            <TradesAgGrid trades={updatedTrades} />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
