import {
  Box,
  Heading,
  HStack,
  Image,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { Logo } from "../../../features/homepage/menu/Logo";
import { updateAccount } from "../../../api";
import { useAtom, useAtomValue } from "jotai";
import { AccountAtom } from "../../../store";
import { CustomInputGroup, ItemHeading, TextInput } from "../../design_library";
import NavWrapper from "./NavWrapper";
import { useAccount } from "../../../hooks/useAccount";

export default function WelcomeToWealthBee(props: any) {
  const [name, setName] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [accountState, setAccountState] = useAtom(AccountAtom);
  const accounts = useAccount();

  const saveData = (name) => {
    setLoading(true);

    if (name.length === 0) {
      return;
    }

    updateAccount(accountState?.selectedAccount?.id, {
      ...accountState.selectedAccount,
      name: name,
    }).then((response) => {
      if (response.success === true) {
        accounts.setAccountName(name);

        setLoading(false);
      } else {
        alert(response.message);
        setLoading(false);
      }
    });
  };

  const validateIsTextOnly = (input) => {
    if (typeof input !== "undefined") {
      if (!input.match(/^[ A-Za-z0-9_@./#&'+-]*$/) || input.length > 50) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <NavWrapper
      previousEnabled={false}
      isFinalStep={false}
      nextDisabled={false}
    >
      <Box pb="10px" pt="8px" w="full">
        <VStack
          fontSize={"14px"}
          textAlign={"center"}
          justifyContent={"space-between"}
          w="full"
        >
          <VStack w="full" justifyContent={"center"} alignItems={"center"}>
            <Heading fontWeight={600} fontSize={"28px"}>
              Welcome to WealthBee!
            </Heading>

            <Text mb="10px">Start by naming your first portfolio:</Text>
            <TextInput
              border="solid 1px var(--gray)"
              padding="10px"
              dark={true}
              placeholder="Account name"
              value={name}
              textAlign="center"
              borderRadius="6px"
              width="100%"
              onChange={(e) => {
                if (validateIsTextOnly(e.target.value) === true) {
                  setName(e.target.value);

                  if (e.target.value.length > 0) {
                    saveData(e.target.value);
                  }
                }
              }}
            />
          </VStack>
        </VStack>
      </Box>
    </NavWrapper>
  );
}
