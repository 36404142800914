import {
  Instrument,
  InstrumentIssue,
  InstrumentPrice,
  PricingError,
} from "../types";
import { APIEndpoint } from ".";
import { InstrumentType } from "../types/Enums";

const token = localStorage.getItem("token");

export const getInstruments = async (
  accountId: number
): Promise<Instrument[]> => {
  const result = await fetch(`${APIEndpoint}/instruments`, {
    method: "GET",
    headers: {
      Authorization: token || "",
      AccountId: accountId as any,
    },
  });

  if (result.ok) {
    const data = await result.json();
    if (data.success && data.instruments) {
      return data.instruments as Instrument[];
    }
  }

  return [];
};

export const updateInstrument = async (instrument: Instrument) => {
  return fetch(`${APIEndpoint}/instruments/${instrument.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
    },
    body: JSON.stringify(instrument),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error updating instrument:", error));
};

export const getInstrumentPrices = async (
  accountId: number,
  instrumentId: number
): Promise<InstrumentPrice[]> => {
  const result = await fetch(
    `${APIEndpoint}/instruments/prices/${instrumentId}`,
    {
      method: "GET",
      headers: {
        Authorization: token || "",
        AccountId: accountId as any,
      },
    }
  );

  if (result.ok) {
    const data = await result.json();
    if (data.success && data.data) {
      return data.data as InstrumentPrice[];
    }
  }

  return [];
};

export const getInstrumentHistoricalPrices = async (
  accountId: number,
  instrumentId: number
): Promise<InstrumentPrice[]> => {
  const result = await fetch(
    `${APIEndpoint}/instruments/prices/historical/${instrumentId}`,
    {
      method: "GET",
      headers: {
        Authorization: token || "",
        AccountId: accountId as any,
      },
    }
  );

  if (result.ok) {
    const data = await result.json();
    if (data.success && data.data) {
      return data.data as InstrumentPrice[];
    }
  }

  return [];
};

export const getCurrentInstrumentPrice = async (instrumentId: number) => {
  return fetch(`${APIEndpoint}/instruments/prices/${instrumentId}/current`, {
    method: "GET",
    headers: {
      Authorization: token || "",
    },
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error refreshing current price:", error));
};

export const getPricesForUnderlying = async (
  underlyingInstrumentId: number
) => {
  return fetch(
    `${APIEndpoint}/instruments/prices/${underlyingInstrumentId}/linked`,
    {
      method: "GET",
      headers: {
        Authorization: token || "",
      },
    }
  )
    .then((response) => response.json())
    .catch((error) =>
      console.error(
        "Error refreshing current price of all instruments with this underlying:",
        error
      )
    );
};

export const getHistoricalInstrumentPrice = async (instrumentId: number) => {
  return fetch(`${APIEndpoint}/instruments/prices/${instrumentId}/historical`, {
    method: "GET",
    headers: {
      Authorization: token || "",
    },
  })
    .then((response) => response.json())
    .catch((error) =>
      console.error("Error refreshing historical price:", error)
    );
};

export const searchForInstruments = async (
  searchQuery: string,
  instrumentType: InstrumentType
) => {
  return fetch(
    `${APIEndpoint}/instruments/search/${searchQuery}?instrumentType=${instrumentType}`,
    {
      method: "GET",
      headers: {
        Authorization: token || "",
      },
    }
  )
    .then((response) => response.json())
    .catch((error) => console.error("Error searching for instruments:", error));
};

export const getInstrumentIssues = async (): Promise<InstrumentIssue[]> => {
  const result = await fetch(`${APIEndpoint}/instruments/issues`, {
    method: "GET",
    headers: {
      Authorization: token || "",
    },
  });

  if (result.ok) {
    const data = await result.json();
    if (data.success && data.data) {
      return data.data as InstrumentIssue[];
    }
  }

  return [];
};

export const getPricingErrors = async (instrumentId: number) => {
  const result = await fetch(
    `${APIEndpoint}/instruments/errors/${instrumentId}`,
    {
      method: "GET",
      headers: {
        Authorization: token || "",
      },
    }
  );

  if (result.ok) {
    const data = await result.json();
    if (data.success && data.data) {
      return data.data as PricingError[];
    }
  }

  return [];
};

export const calculateTheoreticalPriceForInstrument = async (
  instrument: Instrument
) => {
  return fetch(`${APIEndpoint}/instruments/price/calculate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
    },
    body: JSON.stringify(instrument),
  })
    .then((response) => response.json())
    .catch((error) =>
      console.error(
        "Error calculating theoretical price for instrument:",
        error
      )
    );
};
