import { Box } from "@chakra-ui/react";
import React from "react";

export default function InsightWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Box
      backgroundColor="var(--bg)"
      borderRadius="md"
      boxShadow="md"
      p={1}
      overflow="hidden"
      w="full"
      minH="130px"
      h="full"
    >
      {children}
    </Box>
  );
}
