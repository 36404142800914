import * as React from "react";
import "./index.css";
import { TVChartContainer } from "./TVChartContainer";
import { Box } from "@chakra-ui/react";
import { Trade } from "../../types";

const TradingView = ({ trades }) => {
  return (
    <Box h="100%" w="100%" display="flex" flexDirection="column">
      <TVChartContainer trades={trades} />
    </Box>
  );
};

export default TradingView;
