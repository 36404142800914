export interface InsightTemplate {
  newTag?: string;
  title: string; // The title of the insight such as 'Breakeven'
  type: InsightType; // The type such as text, chart, graph, map, heatmap etc.
  insightId: number; // The unique ID that identifies this insight
  query?: string; // The query to generate this insight
  userActivations: number; // The number of users that have this insight activated on their accounts
  createdDate: Date; // The date that this was created
  updatedDate: Date; // The last date that this was updated
  ownerUserId: number; // The user Id of the author
  description: string; // A description, describing the insight
  tags?: Record<string, any>; // Tags such as 'math', 'options' etc
  isPublic: boolean; // Indicates if users other than admins and the author can see and use this insight
  isDeleted: boolean; // Indicates if the insight has been deleted
  version: number; // The version of the insight
  official: boolean;
  data?:
    | InsightNumberProps
    | InsightComparisonProps
    | InsightBarchartProps
    | InsightTableProps; // The value of the insight
  width?: number;
  height?: number;
  isDefaultInsight?: boolean;
  defaultX?: number;
  defaultY?: number;
}

export enum InsightType {
  Number = "Number",
  Calendar = "Calendar",
  Barchart = "Barchart",
  Treemap = "Treemap",
  Comparison = "Comparison",
  Linechart = "Linechart",
  Dendrogram = "Dendrogram",
  ProfitAndLossLineChart = "ProfitAndLossLineChart",
  Table = "Table",
}

export interface InsightNumberProps {
  prefix?: string;
  value: number;
  suffix?: string;
  preview?: boolean;
  change?: number;
  changeSuffix?: string;
  decimals?: number;
}

export interface InsightTableProps {
  rows: any[];
}

export interface InsightComparisonProps {
  prefix?: string;
  value1: number;
  label1?: string;
  value2: number;
  label2?: string;
  suffix?: string;
  decimals?: number;
}

export type DataItem = {
  domain: string;
  [key: string]: number | string;
};

export interface ChartProperties {
  name: string;
  chartType: string;
  keys: string[];
  colors: string[];
  legend: string[];
  domainType: string;
  showEveryDomainLabel?: boolean;
  flipAxis?: boolean;
}

export interface InsightBarchartProps {
  data: DataItem[];
  chartProperties: ChartProperties;
}

export interface InsightItem {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  data?: any;
}
