import React, { useEffect } from "react";
import {
  BuySell,
  FormFrameworkComponentType,
  InstrumentType,
} from "../../../../types/Enums";
import FormBase from "../../../../components/forms/Form";
import { Box, VStack } from "@chakra-ui/react";
import { useAtomValue } from "jotai";
import { AccountAtom, TransactionsAtom } from "../../../../store";
import {
  compilePositionsForAllusers,
  compilePositionsForMe,
  getCurrencies,
} from "../../../../api";
import { createTrade, updateTrade } from "../../../../api/Trades";
import { useTransactions } from "../../../../hooks/useTransactions";
import { usePositions } from "../../../../hooks";
import { Currency } from "../../../../types/Currencies";
import { delay } from "../../../../utils";
import { TbPlaceholder } from "react-icons/tb";

interface AddoptionFormController {
  isLoading: boolean;
  schema: any;
  submitPayload: (payload: any) => void;
}

export const useAddOptionFormController = (props: any) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const accountState = useAtomValue(AccountAtom);
  const transactionsState = useAtomValue(TransactionsAtom);
  const transactions = useTransactions();
  const positions = usePositions();
  const [schema, setSchema] = React.useState<any>({
    symbol: {
      value: "",
      label: "Underlying Symbol",
      error: "",
      type: FormFrameworkComponentType.InstrumentSearch,
      instrumentType: InstrumentType.Equity,
      required: true,
      enableCreateNew: true,
    },
    strikePrice: {
      value: "",
      label: "Strike",
      error: "",
      type: FormFrameworkComponentType.Number,
      required: true,
      placeholder: "Format: 100.00 or 225.50",
    },

    expiryDate: {
      value: "",
      label: "Expiry",
      error: "",
      type: FormFrameworkComponentType.Date,
      required: true,
    },
    putCall: {
      value: "",
      label: "Put/Call",
      error: "",
      type: FormFrameworkComponentType.Select,
      enum: ["Put", "Call"],

      required: true,
    },

    multiplier: {
      value: "",
      label: "Multiplier",
      error: "",
      type: FormFrameworkComponentType.Number,
      required: true,
      placeholder: "Usually 100",
    },
    exchange: {
      value: "US",
      label: "Exchange",
      enum: ["US"],
    },
    tradeDate: {
      value: new Date().toISOString(),
      label: "Trade Date",
      error: "",
      type: FormFrameworkComponentType.Date,
      required: true,
    },
    tradeType: {
      value: "Option",
    },
    accountId: {
      value: accountState?.selectedAccount?.id,
    },
    book: {
      value: "",
      label: "Book",
      error: "",
      type: FormFrameworkComponentType.Select,
      enum: transactionsState.books,
      required: false,
      enableCreateNew: true,
    },
    buySell: {
      value: "Buy",
      label: "Buy/Sell",
      error: "",
      type: FormFrameworkComponentType.Select,
      enum: Object.values(BuySell),
      required: true,
    },
    price: {
      value: 0,
      label: "Price per contract",
      error: "",
      type: FormFrameworkComponentType.Number,
      required: true,
    },
    quantity: {
      value: 0,
      label: "Contracts",
      error: "",
      type: FormFrameworkComponentType.Number,
      required: true,
    },
    currency: {
      value: "USD",
      label: "Currency",
      error: "",
      type: FormFrameworkComponentType.Select,
      required: true,
      enum: Object.values(Currency),
      enableCreateNew: false,
    },
    fees: {
      value: 0,
      label: "Fees",
      error: "",
      type: FormFrameworkComponentType.Number,
      required: false,
    },
    commission: {
      value: 0,
      label: "Commission",
      error: "",
      type: FormFrameworkComponentType.Number,
      required: false,
    },
    notes: {
      value: "",
      label: "Notes",
      error: "",
      type: FormFrameworkComponentType.TextArea,
      required: false,
    },
  });

  const submitPayload = async (payload: any) => {
    setIsLoading(true);

    // Extract payload and ensure deep cloning of symbol to avoid losing it on error
    const parsePayloadToJustValues = Object.keys(payload).reduce((acc, key) => {
      if (key === "symbol") {
        console.log("symbol", payload[key]);
        acc["symbol"] = {
          value: payload[key].value.value,
          exchange: payload[key].value.exchange,
        };
        return acc;
      }
      acc[key] = payload[key].value;
      return acc;
    }, {});

    const Trade = {
      ...parsePayloadToJustValues,
      tradeType: InstrumentType.Option,
      instrumentType: InstrumentType.Option,
      grossProceeds: payload.price.value * payload.quantity.value,
      netProceeds:
        payload.price.value * payload.quantity.value -
        payload.fees.value -
        payload.commission.value,
      currency: payload.currency.value,

      strikePrice: payload.strikePrice?.value,
      expiryDate: payload.expiryDate?.value,
      putCall: payload.putCall?.value,
      multiplier: payload.multiplier.value,
      underlyingSymbol: payload.symbol?.value?.value,
      symbol: payload.symbol?.value?.value,
    };

    const createdTrade = await createTrade(
      Trade,
      accountState?.selectedAccount?.id
    );

    if (createdTrade) {
      if (createdTrade.success === true) {
        //rebuild positions
        await compilePositionsForMe(accountState?.selectedAccount?.id);
        delay(1000);
        await transactions.getTradesAndCashflows(
          accountState?.selectedAccount?.id
        );
        await positions.fetchPositions(accountState?.selectedAccount?.id);

        props.onComplete();
        setIsLoading(false);
      } else {
        const errors = createdTrade.errors;
        const newSchema = schema;

        // // Update schema with errors without resetting the symbol field
        Object.keys(errors).forEach((key) => {
          if (key !== "symbol") {
            if (newSchema[key]) {
              newSchema[key].error = errors[key];
            }
          }
        });

        setSchema(newSchema);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    schema,
    submitPayload,
  } as AddoptionFormController;
};
