import React from "react";
import NavWrapper from "./NavWrapper";
import { Box, Heading, Image, OrderedList, Text } from "@chakra-ui/react";

export default function AddAssetsTutorial() {
  return (
    <NavWrapper previousEnabled={false} isFinalStep={false} nextDisabled={true}>
      <Box fontSize="14px" maxW="300px">
        <Heading
          as="h2"
          fontSize="18px"
          mb={4}
          fontWeight={600}
          textAlign={"left"}
        >
          Adding assets
        </Heading>
        <Text mt="10px">
          You can add your investments by clicking this button.{" "}
        </Text>
        <Text mt="10px">Supported Asset Classes:</Text>
        <OrderedList mt="10px">
          <li>Stocks</li>
          <li>ETFs</li>
          <li>Options</li>
          <li>Futures</li>
        </OrderedList>
        <Text mt="10px" fontSize={"16px"}>
          Click on <span className="rainbow">Add assets</span> at the top right
          to move to the next step
        </Text>
      </Box>
    </NavWrapper>
  );
}
