import { useState } from "react";
import { ChangePassword } from "../../api";
import { useAtom } from "jotai";
import { AppStateAtom } from "../../store";
import { useToast } from "@chakra-ui/react";
import { getStripePortal } from "../../api";
import mixpanel from "mixpanel-browser";
import { useNotifications } from "../../hooks/useNotifications";

interface SettingsController {
  password: string;
  changePassword: () => void;
  requestStripePortal: () => void;
  setPassword: (password: string) => void;
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
  loading: boolean;
  errorMessage: string;
}

export const useSettingsController = () => {
  const [password, setPassword] = useState<string>("");
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const toast = useToast();
  const notifications = useNotifications();

  const performPasswordChange = async () => {
    mixpanel.track("Change Password Attempt");

    setLoading(true);

    ChangePassword(password).then((res) => {
      setTimeout(() => {
        if (res.success === true) {
          console.log("Password changed successfully");

          notifications.createNotification(
            "Password changed successfully",
            "success"
          );

          setTimeout(() => {
            notifications.clearNotifications();
          }, 2000);

          setErrorMessage("");
          setPassword("");
          setDrawerOpen(false);
        } else {
          console.log("Error changing password");
          toast({
            title: "Error changing password",
            description: res.msg,
            status: "error",
            duration: 9000,
            isClosable: true,
          });

          notifications.createNotification("Error changing password", "error");

          setTimeout(() => {
            notifications.clearNotifications();
          }, 5000);
          setErrorMessage(res.msg);
        }
        setLoading(false);
      }, 200);
    });
  };

  const reqStripePortal = () => {
    mixpanel.track("Request Stripe Portal");
    setLoading(true);
    getStripePortal().then((res) => {
      if (res.success === true) {
        window.location.href = res.url;
      }
      setLoading(false);
    });
  };

  return {
    password: "",
    changePassword: performPasswordChange,
    setPassword,
    requestStripePortal: reqStripePortal,
    drawerOpen,
    setDrawerOpen,
    loading,
    errorMessage,
  } as SettingsController;
};
