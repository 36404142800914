import { Box, VStack } from "@chakra-ui/react";
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import EnterpriseCTAText from "./EnterpriseCTAText";
const EnterpriseHero = (props: any) => {
  return (
    <>
      <Box display={"flex"} justifyContent={"center"}>
        <EnterpriseCTAText />
      </Box>
    </>
  );
};

export default EnterpriseHero;
